import { AltaAccessSvg } from 'components/svgs/AltaAccessSvg';
import { AltaDmpSvg } from 'components/svgs/AltaDmpSvg';
import { AltaVideoSvg } from 'components/svgs/AltaVideoSvg';
import { AppSwitcherButton } from 'new-components/AppSwitcher/AppSwitcherButton';

import '../AppSwitcher/AppSwitcherMenu';
import { AltaIntrusionSvg } from 'components/svgs/AltaIntrusionSvg';
import { AltaMailroomSvg } from 'components/svgs/AltaMailroomSvg';
import { AltaPartnersSvg } from 'components/svgs/AltaPartnersSvg';

interface NativeAppSwitcherMenuProps {
  access: {
    onClick(): void;
  };
  video: {
    onClick(): void;
  };
  intrusion: {
    onClick(): void;
  };
  mailroom: {
    onClick(): void;
  };
  partnerCenter: {
    onClick(): void;
    isVisible: boolean;
  };
  dmp: {
    onClick(): void;
    isVisible: boolean;
  };
}

export const NativeAppSwitcherMenu = ({
  access: { onClick: onAccessClick },
  video: { onClick: onVideoClick },
  intrusion: { onClick: onIntrusionClick },
  mailroom: { onClick: onMailroomClick },
  dmp: { isVisible: isDmpVisible, onClick: onDmpClick },
  partnerCenter: {
    isVisible: isPartnerCenterVisible,
    onClick: onPartnerCenterClick,
  },
}: NativeAppSwitcherMenuProps) => {
  return (
    <div className="native-app-switcher-menu app-switcher-menu">
      <AppSwitcherButton
        label="Access"
        onClick={onAccessClick}
        icon={
          <AltaAccessSvg className="native-app-switcher-button__icon app-switcher-button__icon" />
        }
      />

      <AppSwitcherButton
        label="Intrusion"
        onClick={onIntrusionClick}
        icon={
          <AltaIntrusionSvg className="native-app-switcher-button__icon app-switcher-button__icon" />
        }
      />

      {false && (
        <AppSwitcherButton
          label="Mailroom"
          onClick={onMailroomClick}
          icon={
            <AltaMailroomSvg className="native-app-switcher-button__icon app-switcher-button__icon" />
          }
        />
      )}

      {isPartnerCenterVisible && (
        <AppSwitcherButton
          label="Partners"
          onClick={onPartnerCenterClick}
          icon={
            <AltaPartnersSvg className="native-app-switcher-button__icon app-switcher-button__icon" />
          }
        />
      )}

      {isDmpVisible && (
        <AppSwitcherButton
          label="DMP"
          onClick={onDmpClick}
          icon={
            <AltaDmpSvg className="native-app-switcher-button__icon app-switcher-button__icon" />
          }
        />
      )}

      <AppSwitcherButton
        label="Video"
        onClick={onVideoClick}
        icon={
          <AltaVideoSvg className="native-app-switcher-button__icon app-switcher-button__icon" />
        }
      />
    </div>
  );
};
