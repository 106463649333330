import { ComponentProps } from 'react';
import clsx from 'clsx';
import {
  OpSelect,
  OpSelectOptionType,
} from 'new-components/DLS/OpSelect/OpSelect';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { v4 as uuidv4 } from 'uuid';
import { DefaultOptionType } from 'antd/es/select';
import { OpForm } from '../../OpForm';
import { useOpFormContext } from '../../OpFormContext';

type OpFormSelectProps<
  T extends string | number,
  U extends DefaultOptionType,
> = Pick<
  ComponentProps<typeof OpForm.Item>,
  | 'name'
  | 'label'
  | 'rules'
  | 'tooltip'
  | 'validateTrigger'
  | 'validateStatus' // Temp: For SUIR wrapped version
  | 'help' // Temp: For SUIR wrapped version
  | 'required' // Temp: For SUIR wrapped version
> &
  ComponentProps<typeof OpSelect<T, U>> & {
    formItemClassName?: string;
    isLoading?: boolean;
  };

export const OpFormSelect = <
  T extends string | number,
  U extends DefaultOptionType = OpSelectOptionType<T>,
>({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,
  rules,
  validateTrigger,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,

  testId,

  ...elementProps
}: OpFormSelectProps<T, U>) => {
  /**
   * The Form is wrapped in a provider that passes the loading context
   * to the component
   */
  const { isDataLoading, isReadOnly } = useOpFormContext();

  const opFormItemTestId = testId
    ? `${testId}-op-form-item`
    : typeof label === 'string'
      ? `${label}-op-form-item`
      : undefined;
  const uuid = uuidv4();
  const opSelectId = `op-select-${uuid}`;

  return (
    <OpForm.Item
      className={clsx('op-form-select', formItemClassName)}
      name={name}
      label={label}
      rules={rules}
      tooltip={tooltip}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
      testId={opFormItemTestId}
      {...(!name && { htmlFor: opSelectId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpSelect
          disabled={isReadOnly || disabled}
          {...(!name && { id: opSelectId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
          {...elementProps}
          testId={testId}
        />
      )}
    </OpForm.Item>
  );
};
