import { fromJS, Map } from 'immutable';
import { scopesByOperationId } from '@openpathsec/helium-client/scopes';

export function checkScope(currentOrgId, currentScope, allowedScopesRaw) {
  // console.error('checkScope in utils/redirect is DEPRECATED')
  // console.debug(`>> checkScope() ${currentOrgId} - currentScope (${currentScope && currentScope.toJS ? currentScope.toJS() : currentScope}) SCOPES: ${allowedScopesRaw}`)
  if (currentScope && allowedScopesRaw) {
    // replace {orgId} with the actual orgId
    const allowedScopes = allowedScopesRaw.map((x) =>
      x.replace('{orgId}', `${currentOrgId}`),
    );
    const some = currentScope.some((scope) =>
      allowedScopes.find((x) => scope === x),
    );
    return some;
  }
  return false;
}

export function substituteOrgIdInScope(currentOrgId, scopes) {
  return scopes.map((x) => x.replace('{orgId}', `${currentOrgId}`));
}

export function verifyScope(activeScopes, rawAllowedScopes, orgId) {
  // If no rawAllowedScopes are provided, return true
  if (!rawAllowedScopes?.length) {
    return true;
  }

  let allowedScopes = [...rawAllowedScopes];

  // Replace orgId placeholder with actual orgId if passed
  if (orgId) {
    allowedScopes = substituteOrgIdInScope(orgId, rawAllowedScopes);
  }

  // Check if any of the activeScopes are in the allowedScopes
  return activeScopes.some((scope) => allowedScopes.includes(scope));
}

export function verifyApiScope(activeScopes, operationId, orgId) {
  const allowedScopes = scopesByOperationId[operationId];

  // If no allowedScopes are provided return false
  if (!allowedScopes || !allowedScopes.length) {
    return false;
  }

  return verifyScope(activeScopes, allowedScopes, orgId);
}

// Returns a flat list of scopes from all applicable sources:
// identity-level scopes tied to no org, plus any org-level scopes
// tied to the current org, if any. NOTE: in future there may be other
// sources (e.g. partner-level scopes), so we want to keep all logic
// for calculating/extracting scopes flowing in this one function, so
// that the rest of the code can remain agnostic about where the
// individual scopes come from. NOTE ABOUT THE NOTE: currently the
// login() saga in AppContainer/sagas.js is in conflict with the above
// NOTE... i.e. the login() saga is also doing a chunk of
// scope-related processing that might be better to move here (or
// consolidate in some way).
export function getActiveScopes(currentOrg, scopes, parentOrgScope) {
  if (!scopes) return fromJS([]);
  if (parentOrgScope && !Map.isMap(parentOrgScope)) {
    throw new Error(
      'getActiveScopes should be passed parentOrgScope of type Immutable.Map!',
    );
  }
  // if you have no currentOrg, you're probably in master mode. Which means we need to check your s-o* permissions
  const partnerScopes =
    parentOrgScope && parentOrgScope.get('hasSubOrgFeature')
      ? parentOrgScope.get('partnerScopes')
      : [];
  const orgItem = currentOrg
    ? scopes.find((s) => s.getIn(['org', 'id']) === Number(currentOrg))
    : null;
  const orgScopes = orgItem ? orgItem.get('scope') : fromJS([]);

  const identityItem = scopes.find((s) => s.getIn(['org', 'id']) === null);
  const identityScopes = identityItem ? identityItem.get('scope') : null;

  let ret = fromJS([]);

  // Filter s-o scopes out of orgScopes (so that s-o scopes can only
  // enter the result via partnerScopes), because s-o scopes only
  // have meaning if the associated org has the subOrgs
  // feature. It's important to get the right list here since
  // various places (e.g. BillingSubscriptionWidget) are doing
  // client-side visibility gating based on the presence of s-o:r or
  // s-o:w, but that produces unintended behavior if the org doesn't
  // also have the subOrgs feature. So by forcing s-o scopes to only
  // enter via partnerScopes, we are doing the right thing because
  // partnerScopes will only be populated if currentOrg has parent
  // org AND the logged-in identity has s-o in that parent org. See
  // login() in AppContainer/sagas.js for how hasSubOrgFeature is
  // calculated and how processed scopes are produced and sent to
  // setProcessedScopes().
  if (orgScopes) {
    ret = ret.concat(orgScopes.filter((s) => !['s-o:r', 's-o:w'].includes(s)));
  }

  if (identityScopes) {
    ret = ret.concat(identityScopes);
  }

  if (partnerScopes) {
    ret = ret.concat(partnerScopes);
  }

  return ret;
}
