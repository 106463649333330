import { useState } from 'react';
import { OpDropdown } from 'new-components/DLS/OpDropdown/OpDropdown';
import { OpIdentityUserAvatar } from 'new-components/OpIdentityUserAvatar/OpIdentityUserAvatar';
import { MenuProps } from 'antd/es/menu';
import { useLogout } from 'utils/customHooks/useLogout';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { useTranslation } from 'react-i18next';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { ProfileDrawer } from './components';

import './UserMenu.scss';

export const UserMenu = () => {
  const { t } = useTranslation();
  const { identity, token } = useIdentityAndUser();
  const { logout } = useLogout();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const fullName = identity?.fullName
    ? identity?.fullName
    : `${identity?.firstName || ''} ${identity?.lastName || ''}`.trim();

  const userOrgs = token?.tokenScopeList?.filter(({ org }) => org?.id);
  const isShowingOrgName = (userOrgs?.length || 0) < 2;

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <OpSpace direction="vertical" size={4}>
          {fullName && (
            <OpText className="user-menu__user-name">{fullName}</OpText>
          )}
          <OpText className="user-menu__user-email">{identity?.email}</OpText>

          <OpSpace direction="vertical" size={2}>
            <OpText className="user-menu__user-namespace">
              {identity?.namespace?.nickname}
            </OpText>
            {isShowingOrgName && (
              <OpText className="user-menu__user-org">
                {identity?.namespace?.org?.name}
              </OpText>
            )}
          </OpSpace>
        </OpSpace>
      ),
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: t('My profile'),
      onClick: () => setDrawerIsOpen(true),
    },
    {
      key: '3',
      label: t('Sign out'),
      onClick: () => logout(),
    },
  ];

  return (
    <>
      <OpDropdown
        className="user-menu"
        overlayClassName="user-menu-overlay"
        trigger={['click']}
        menu={{ items }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
      >
        <div className="user-menu__icon" data-testid="user-menu">
          <OpIdentityUserAvatar size={36} isAvatarAllowed={false} />
        </div>
      </OpDropdown>
      <ProfileDrawer
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      />
    </>
  );
};
