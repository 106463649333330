import { ComponentProps } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpTagInput } from 'new-components/DLS/OpTagInput/OpTagInput';
import { useOpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

type OpFormTagInputProps = Pick<
  ComponentProps<typeof OpForm.Item>,
  | 'name'
  | 'label'
  | 'rules'
  | 'tooltip'
  | 'validateTrigger'
  | 'validateStatus' // Temp: For SUIR wrapped version
  | 'help' // Temp: For SUIR wrapped version
  | 'required' // Temp: For SUIR wrapped version
> &
  ComponentProps<typeof OpTagInput> & {
    formItemClassName?: string;
    isLoading?: boolean;
  };

export const OpFormTagInput = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  rules,
  tooltip,
  validateTrigger,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation
  testId = 'op-form-tag-input',

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,

  ...elementProps
}: OpFormTagInputProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useOpFormContext();

  return (
    <OpForm.Item
      className={clsx('op-form-tag-input', formItemClassName)}
      name={name}
      testId={`${testId}-container`}
      label={label}
      rules={rules}
      tooltip={tooltip}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpTagInput
          disabled={isReadOnly || disabled}
          testId={testId}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
