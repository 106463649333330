import { useTranslation } from 'react-i18next';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { OpSection } from 'new-components/DLS/OpSection/OpSection';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import clsx from 'clsx';

import './LockdownCard.scss';

export const LockdownCard = ({
  orgId,
  currentUserId,
  cloudKeyCredentialId,
  name,
  lockdownPlanId,
  isActive,
  hasTriggerRights,
  hasRevertRights,
}: {
  orgId: number;
  currentUserId: number;
  cloudKeyCredentialId: number;
  name: string;
  lockdownPlanId: number;
  isActive: boolean;
  hasTriggerRights: boolean;
  hasRevertRights: boolean;
}) => {
  const { t } = useTranslation();

  const cloudKeyTriggerLockdownPlanMutation = useOpMutation({
    apiEndpointName: 'cloudKeyTriggerLockdownPlan',
    queryKeysToInvalidate: [['listLockdownPlans']],
    onSuccessMessage: t('Your lockdown has been initiated!'),
  });

  const cloudKeyRevertLockdownPlanMutation = useOpMutation({
    apiEndpointName: 'cloudKeyRevertLockdownPlan',
    queryKeysToInvalidate: [['listLockdownPlans']],
    onSuccessMessage: t('Your lockdown has been reverted!'),
  });

  const handleTrigger = async () => {
    if (currentUserId && cloudKeyCredentialId) {
      try {
        await cloudKeyTriggerLockdownPlanMutation.mutateAsync({
          apiEndpointRequirements: [orgId, currentUserId, cloudKeyCredentialId],
          payload: {
            lockdownPlanId,
            description: t('Lockdown trigger via access'),
          },
        });
      } catch {
        // Do nothing
      }
    }
  };
  const handleRevert = async () => {
    if (currentUserId && cloudKeyCredentialId) {
      try {
        await cloudKeyRevertLockdownPlanMutation.mutateAsync({
          apiEndpointRequirements: [orgId, currentUserId, cloudKeyCredentialId],
          payload: {
            lockdownPlanId,
            description: t('Lockdown revert via access'),
          },
        });
      } catch {
        // Do nothing
      }
    }
  };

  return (
    <OpSection
      className={clsx('lockdown-card', {
        'lockdown-card--active': isActive,
      })}
    >
      <OpSpace className="lockdown-card__space" direction="vertical">
        <div>
          <div className="lockdown-card__title">{name}</div>
          <div className="lockdown-card__description">{`ID: ${lockdownPlanId}`}</div>
        </div>
        <div className="lockdown-card__actions-container">
          <OpButton
            className="lockdown-card__action-button"
            type="primary"
            disabled={!hasTriggerRights}
            onClick={handleTrigger}
          >
            {t('Trigger')}
          </OpButton>
          <OpButton
            className="lockdown-card__action-button"
            disabled={!hasRevertRights}
            onClick={handleRevert}
          >
            {t('Revert')}
          </OpButton>
        </div>
      </OpSpace>
    </OpSection>
  );
};
