import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useAppContext } from 'new-components/OpAppScaffold/AppContext';
import { getControlCenterOrigin } from 'utils/getControlCenterOrigin';
import { getWindowLocation } from 'utils/window';

export const useNavigateToAccess = () => {
  const { app } = useAppContext();
  const navigate = useNavigate();

  const handleNavigateToAccess = useCallback(() => {
    if (app === 'access') {
      navigate(0);
      return;
    }
    const accessUrl = getControlCenterOrigin();

    if (accessUrl) {
      getWindowLocation().href = accessUrl;
    }
  }, [app, navigate]);

  return {
    handleNavigateToAccess,
  };
};
