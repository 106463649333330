import { getAltaDomain, validDevelopmentUrlRegEx } from 'utils/domains';
import { getWindowLocation } from 'utils/window';

export const getControlCenterOrigin = () => {
  const { origin } = getWindowLocation();

  // local development
  if (validDevelopmentUrlRegEx.test(origin)) {
    return origin;
  }

  // for deployed envs
  const domain = getAltaDomain();

  if (domain) {
    const controlCenterProductionDomains = [
      'alta.avigilon.com',
      'eu.alta.avigilon.com',
      'openpath.com',
      'eu.openpath.com',
    ];

    if (controlCenterProductionDomains.includes(domain)) {
      return `https://control.${domain}`;
    }

    return `https://platinum.${domain}`;
  }
};
