import { useDispatch } from 'react-redux';
import { removeAccessToken } from 'utils/accessToken';
import { logoutSuccess } from 'routes/AppContainer/actions';
import { getControlCenterOrigin } from 'utils/getControlCenterOrigin';
import { BroadcastChannel } from 'broadcast-channel';
import { getWindowLocation } from 'utils/window';
import { useOpParams } from 'utils/useOpParams';
import { useOpMutation } from './useOpMutation';
import { useOpQuery } from './useOpQuery';

export const useLogout = () => {
  const logoutMutation = useOpMutation({
    apiEndpointName: 'logout',
  });

  const { orgId } = useOpParams();

  const org = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [Number(orgId)],
  });

  const dispatch = useDispatch();

  const logout = async () => {
    try {
      await logoutMutation.mutateAsync({});

      removeAccessToken();

      // tell oxygen to disconnect
      const broadcastChannel = new BroadcastChannel('oxygen');
      broadcastChannel.postMessage({
        command: 'DISCONNECT',
        target: '*',
        sender: 'logout',
      });

      // Clears out redux data when called in control center
      dispatch(logoutSuccess());

      const windowLocation = getWindowLocation();
      // handle control center and other apps on both alta.avigilon and openpath domains.
      const controlCenterOrigin =
        getControlCenterOrigin() ?? windowLocation.origin;

      // this hook is used by different apps which dont all have their own login page thus we need to hard set the url vs using react router
      // @ts-expect-error need types update. Unified orgs should be redirected to unified login (/signin)
      windowLocation.href = org.isUnified
        ? `${controlCenterOrigin}/signin`
        : `${controlCenterOrigin}/login`;
    } catch {
      // mutateAsync will display the error
    }
  };

  return { logout };
};
