import { getOpParams } from 'utils/getOpParams';
import { checkAuthIsUPCPartnerOrg } from 'utils/checkAuthIsUPCPartnerOrg';
import { getAccessToken } from 'utils/accessToken';
import { useOpQuery } from './useOpQuery';

type TokenScopeListItem = Utils.ArrayElement<
  Api.Response['describeAccessToken']['tokenScopeList']
>;

export const useIsDmpEnabled = () => {
  const { orgId } = getOpParams();
  const token = getAccessToken();

  const { data: accessToken } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [token],
    select: (heliumResponse) => heliumResponse.json.data,
  });

  /**
   * If any of the partners orgs passes checkAuthIsUPCPartnerOrg
   * then they are a partner with UPC access meaning show DMP
   */
  const isUnifiedPartner = accessToken?.tokenScopeList?.some(
    (tokenScopeListItem: TokenScopeListItem) => {
      return checkAuthIsUPCPartnerOrg(tokenScopeListItem);
    },
  );
  const { data: orgAwareConfig, isPending } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (data) => {
      return data?.json?.data?.orgAwareConfig;
    },
  });

  return {
    // Need for current partners that haven't been given access to UPC
    isDmpEnabled: Boolean(orgAwareConfig?.dmpOrgIdExt || isUnifiedPartner),
    isPending,
  };
};
