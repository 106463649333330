import { t } from 'i18next';
import * as Sentry from '@sentry/react';
import { awareClient } from './awareClient';
import { getAccessToken } from './accessToken';
import { setUnifiedCookie } from './setUnifiedCookie';

type UnifiedLoginError = {
  code: 'login_time_denied' | 'ip_address_denied';
  kind: string;
};

const UNIFIED_LOGIN_ERROR_MESSAGE = {
  login_time_denied: t('Aware login is schedule-restricted during this time.'),
  ip_address_denied: t(
    'Aware login not permitted from your current IP address.',
  ),
};

export interface UnifiedLoginArgs {
  url: string;
  urlToOpen?: string;
  options?: {
    org_id?: number;
    region?: string;
    redirect_url?: string;
    nonce?: string;
  };
  newTab?: boolean;
}

/**
 * @description A wrapper around other utilities for the purpose of "logging in" to Aware. Essentially
 * we auth to aware via setUnifiedCookie and then are able to navigate to the page immediately via urlToOpen
 */
export const unifiedLogin = async ({
  url,
  urlToOpen,
  options = {},
  newTab = true,
}: UnifiedLoginArgs) => {
  let awareUser: OP.Aware.LookupUserResponse | null;
  let finalNonce = options.nonce;
  const jwt = getAccessToken();
  const target = newTab ? '_blank' : '_self';

  // Can remove this once launched
  try {
    awareUser = await awareClient.lookupUser({
      deploymentUrl: url,
    });

    // Give preference to the nonce passed via prop
    finalNonce = finalNonce || awareUser?.nonce;
  } catch (err) {
    Sentry.captureMessage(`awareClient.lookupUser fetch failed`, {
      level: 'log',
      extra: {
        error: JSON.stringify(err),
      },
    });
  }
  /**
   * If we have a nonce this is the indicator to use the new auth flow.
   * A nonce can be explicitly passed OR found in the lookupUser call.
   * Currently we'd explicitly pass a nonce if available in the unified
   * login flow. App switching / deep linking would rely on the lookupUser
   * call
   */
  if (finalNonce) {
    const finalOptions = {
      jwt,
      ...options,
      nonce: finalNonce,
    };
    const finalUrl = new URL(`${url}/api/v1/setOpenpathCookie`);
    Object.entries(finalOptions).forEach(([key, value]) => {
      finalUrl.searchParams.append(key, String(value));
    });

    window.open(finalUrl.href, target, 'noopener,noreferrer');

    return;
  }

  /**
   * We potentially want to start rethinking this a bit but I'll wait until potentially one more scenario pops up
   * but because this handler also handles opening a new window there is a possibility we know before hand that
   * the cookie for authenticating has already been set thus we don't need to do it again. That being said we
   * may actually want to pull the window.open portion out of here soon as we probably don't want to assume
   * a unifiedLogin means opening a new window as well. Right now this issue only really popped up due to the
   * coupling of the AwareDeepLink component but now the PermissionedAwareDeepLink component that needs to know a user's
   * permissions ahead of time which would have required ensuring a cookie was already set to hit the api endpoint
   * needed to get the user's Aware permissions. Adding extra finalNonce check though it shouldn't come into play as we
   * should be getting redirected off this page before this hits
   */
  const response = await setUnifiedCookie({
    baseUrl: url,
    options,
  });

  if (response?.ok) {
    // default to opening the root url
    window.open(urlToOpen || url, target, 'noopener,noreferrer');
  } else {
    const resData: UnifiedLoginError = await response!.json();

    if (UNIFIED_LOGIN_ERROR_MESSAGE[resData.code]) {
      throw new Error(UNIFIED_LOGIN_ERROR_MESSAGE[resData.code]);
    } else {
      throw new Error(t('Failed to connect') as string);
    }
  }
};
