import Sider from 'antd/es/layout/Sider';
import clsx from 'clsx';
import { OpNavMenu } from './components/OpNavMenu/OpNavMenu';
import { OpNavProps } from './types';

import './OpNav.scss';

export const OpNav = ({
  logo,
  items,
  onLogoClick = () => {},
  defaultPrimaryKey,
  defaultSecondaryKey,
  footer,
}: OpNavProps) => {
  return (
    <div id="op-nav" className="op-nav" data-testid="op-nav">
      <Sider className="op-nav__sider" width={104}>
        {logo && (
          <div
            className={clsx('op-nav__logo', {
              'op-nav__logo--clickable': !!onLogoClick,
            })}
            data-testid="op-nav-logo"
            role="button"
            tabIndex={0}
            onClick={onLogoClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onLogoClick();
              }
            }}
          >
            {logo}
          </div>
        )}

        <OpNavMenu
          items={items}
          defaultPrimaryKey={defaultPrimaryKey || items[0]?.key}
          defaultSecondaryKey={
            defaultSecondaryKey || items[0]?.children?.[0]?.key
          }
        />

        {footer}
      </Sider>
    </div>
  );
};
