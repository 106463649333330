import { ComponentProps, ReactNode } from 'react';
import clsx from 'clsx';
import { OpImage } from 'new-components/DLS/OpImage/OpImage';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { useOpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

interface OpFormImageProps extends ComponentProps<typeof OpImage> {
  formItemClassName?: string;
  isLoading?: boolean;
  loadingPlaceholder: ReactNode;
}

export const OpFormImage = ({
  // Form Item props
  formItemClassName,

  // For skeleton loading state
  isLoading,

  loadingPlaceholder,
  ...elementProps
}: OpFormImageProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading } = useOpFormContext();
  const styles: { width?: string | number; height?: string | number } = {};
  if (elementProps.width) {
    styles.width = elementProps.width;
  }
  if (elementProps.height) {
    styles.height = elementProps.height;
  }

  const loadingContent = loadingPlaceholder ? (
    <OpSkeleton.Node style={styles} active>
      {loadingPlaceholder}
    </OpSkeleton.Node>
  ) : (
    <OpSkeleton.Image style={styles} active />
  );

  return (
    <OpForm.Item className={clsx('op-form-image', formItemClassName)}>
      {isDataLoading || isLoading ? (
        loadingContent
      ) : (
        <OpImage {...elementProps} />
      )}
    </OpForm.Item>
  );
};
