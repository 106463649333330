import {
  getActiveIdentityScopes,
  getActiveScopes,
  SUB_ORG_FEATURE_CODES,
  SUB_ORG_SCOPES,
} from 'utils/scopes';
import { MergedOrgsEntry } from '../OrgSwitcher';

/**
 * @description Checks if the list of scopes exists and includes portal scopes.
 * The only scope format that isnt a portal scope is o###-user###:
 * @param scopes - array of scopes
 * @returns true if any scope exists and isnt o###-user###: and false otherwise
 */
export const includesAnyPortalScope = (scopes: string[]) =>
  scopes.some((s) => !s.match(/^o[0-9]+-user[0-9]+:/));

/**
 * @description Finds orgs the current user has portal access scopes for
 * from the token
 * @param token - user's accessToken
 * @returns The orgs from the accessToken's tokenScopeList
 */
export const getOrgsWithPortalScopesForCurrentUser = (
  token?: Api.Response['describeAccessToken'],
) => {
  const orgsWithPortalScopes = token?.tokenScopeList?.filter(
    ({ scope, org }) => {
      return org?.id && includesAnyPortalScope(scope ?? []);
    },
  );

  return orgsWithPortalScopes?.map(({ org }) => org);
};

/**
 * @description Checks the identity scope for fsa (full support access required)
 * @param token - user's accessToken
 * @returns true if fsa is set and false if it is not
 */
export const getIsFullSupportAccessRequiredIdentityScope = (
  token?: Api.Response['describeAccessToken'],
) => {
  const identityScope = token?.tokenScopeList?.find(
    (tokenScope) => tokenScope.org?.id === null,
  );

  if (identityScope) {
    return (
      identityScope.scopeWithContext &&
      identityScope.scopeWithContext.some(
        ({ context }) => context && context.some(({ fsa }) => fsa),
      )
    );
  }

  return false;
};

/**
 * Checks if the currrent user has mastermode access
 * @param currentOrgId - user's accessToken
 * @param token - user's accessToken
 * @returns true if they have a mastermode scope and false if not.
 */
export const checkMasterModeAccess = ({
  token,
}: {
  token?: Api.Response['describeAccessToken'];
}) => {
  const validScopePrefixes = [
    'o:', // o:r, o:w
    'o-', // e.g. o-basic:r, o-basic:w, o-acu:r, o-acu:w, and more later...
    'i:', // i:r, i:w
    'pc:', // pc:r, pc:w
  ];

  const activeScopes = getActiveIdentityScopes({
    tokenScopeList: token?.tokenScopeList ?? [],
  });

  return activeScopes.some((activeScope) =>
    validScopePrefixes.some((mmScopePrefix) =>
      activeScope.startsWith(mmScopePrefix),
    ),
  );
};

/**
 * Checks if an org has suborgs or suborgs video
 * @param org - the org being checked
 * @returns true if the org has sub orgs feature and false if not.
 */
export const orgHasSuborgFeature = (
  org: NonNullable<
    Api.Response['describeAccessToken']['tokenScopeList']
  >[0]['org'],
) => {
  return org?.packagePlans?.find((packagePlan) => {
    return packagePlan.package?.packageFeatures?.some((packageFeature) =>
      // Old orgSwitcher code includes subOrgsVideo in this check - so keeping it here
      SUB_ORG_FEATURE_CODES.includes(packageFeature.feature?.code ?? ''),
    );
  });
};

/**
 * Checks if any org in the user's tokenScopeList has suborgs
 * @param currentOrgId - user's accessToken
 * @returns true if any org has sub orgs feature and the user has suborgs scopes
 * and false if not.
 */
export const checkIfAnyOrgHasSubOrgs = (
  token?: Api.Response['describeAccessToken'],
) => {
  // Old org switcher includes suborgs-video so leaving here.
  return Boolean(
    token?.tokenScopeList?.find((tokenScope) => {
      return (
        orgHasSuborgFeature(tokenScope.org) && // Check for suborgs feature
        tokenScope.scope?.find((s) => SUB_ORG_SCOPES.includes(s)) // Check for partner scopes
      );
    }),
  );
};

/**
 * Checks if the user has org switcher access
 * @param token - user's accessToken
 * @returns true if user has org switch access
 * and false if not.
 */
export const checkHasGeneralOrgSwitcherAccess = ({
  token,
}: {
  token?: Api.Response['describeAccessToken'];
}) => {
  const hasOrgWithSubOrgs = checkIfAnyOrgHasSubOrgs(token);
  const orgsWithScopes =
    token?.tokenScopeList?.filter(({ org }) => org?.id) ?? [];

  if (hasOrgWithSubOrgs || orgsWithScopes.length > 1) {
    // return early if has suborgs access or scopes for more than one org
    return true;
  }

  // get identity level scopes
  const activeScopes = getActiveScopes({
    tokenScopeList: token?.tokenScopeList ?? [],
  });

  const validScopePrefixes = [
    'o:', // o:r, o:w

    // NOTE: specifically NOT included here are the various o-
    // granular scopes like o-basic:r, etc., and the i: scopes and
    // the pc: scopes, because while those carry some permission for
    // Master Mode menu functions, they don't (by themselves) carry
    // enough permission to org-switch and have access to the
    // org-mode portal for an arbitrary org
  ];

  return activeScopes.some((scope) =>
    validScopePrefixes.some((prefix) => scope.startsWith(prefix)),
  );
};

/**
 * Checks if the org is a parent org
 * @param packagePlans - the packages of the current orgs
 * @returns true if org is parent
 * and false if not.
 */
export const checkIsParentOrg = ({
  packagePlans,
}: {
  packagePlans:
    | MergedOrgsEntry['enabledPackagePlans']
    | MergedOrgsEntry['packagePlans'];
}) => {
  return (
    packagePlans?.some((pp) =>
      pp?.package?.packageFeatures?.some(
        (pf) => pf?.feature?.code === 'subOrgs', // Do not include subOrgsVideo here
      ),
    ) || null
  );
};
