import { ComponentProps } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { v4 as uuidv4 } from 'uuid';
import { OpSiteSpecificUserDataFetchSelect } from 'new-components/DLS/OpSiteSpecificUserDataFetchSelect/OpSiteSpecificUserDataFetchSelect';
import { useOpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

type OpFormSiteSpecificUserDataFetchSelectProps = Pick<
  ComponentProps<typeof OpForm.Item>,
  | 'name'
  | 'label'
  | 'tooltip'
  | 'rules'
  | 'validateTrigger'
  | 'validateStatus' // Temp: For SUIR wrapped version
  | 'help' // Temp: For SUIR wrapped version
  | 'required' // Temp: For SUIR wrapped version
> &
  ComponentProps<typeof OpSiteSpecificUserDataFetchSelect> & {
    formItemClassName?: string;
    isLoading?: boolean;
    orgId: number;
  };

export const OpFormSiteSpecificUserDataFetchSelect = ({
  // Form Item props
  name,
  label,
  tooltip,
  rules,
  validateTrigger,
  formItemClassName,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,
  testId,
  ...selectProps
}: OpFormSiteSpecificUserDataFetchSelectProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useOpFormContext();

  const opFormItemTestId = testId
    ? `${testId}-op-form-item`
    : typeof label === 'string'
      ? `${label}-op-form-item`
      : undefined;
  const uuid = uuidv4();
  const opSelectId = `op-data-fetch-select-${uuid}`;

  return (
    <OpForm.Item
      className={clsx('op-form-data-fetch-select', formItemClassName)}
      name={name}
      label={label}
      tooltip={tooltip}
      rules={rules}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
      testId={opFormItemTestId}
      {...(!name && { htmlFor: opSelectId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpSiteSpecificUserDataFetchSelect
          disabled={isReadOnly || disabled}
          {...(!name && { id: opSelectId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
          {...selectProps}
          testId={testId}
        />
      )}
    </OpForm.Item>
  );
};
