import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const AltaAccessSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 36 36" {...svgProps}>
    <path d="M13 2.46489C13 1.19455 14.1689 0.246089 15.412 0.507794L30.412 3.66569C31.3375 3.86053 32 4.67701 32 5.62279V30.3772C32 31.323 31.3375 32.1395 30.412 32.3343L15.412 35.4922C14.1689 35.7539 13 34.8055 13 33.5351V2.46489Z" />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7.5C4 5.567 5.567 4 7.5 4H11V7H7.5C7.22386 7 7 7.22386 7 7.5V32H4V7.5Z"
    />
  </SvgWrapper>
);
