import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { TanstackDevtools } from 'new-components/TanstackDevtools/TanstackDevtools';
import { AppProvider } from 'new-components/OpAppScaffold/AppContext';
import { useCreateQueryClient } from 'utils/customHooks/useCreateQueryClient';
import { MailroomAppScaffold } from './MailroomAppScaffold';

import './App.scss';

/**
 * @component
 * @description Component that wraps the mailroom app and holds the top level functionality.
 */
export const App = () => {
  // Create a react-query client
  const queryClient = useCreateQueryClient();

  return (
    <AppProvider app="mailroom">
      <QueryClientProvider client={queryClient}>
        <MailroomAppScaffold />
        <TanstackDevtools />
      </QueryClientProvider>
    </AppProvider>
  );
};
