import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const AltaVideoSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 36 36" {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.0658 12.1388C31.8898 11.6918 31.5228 11.3638 31.0578 11.2388L8.21781 5.11776C6.35081 4.62376 4.43081 5.72876 3.93081 7.59376L2.11981 14.3558C1.61981 16.2188 2.73082 18.1418 4.59382 18.6418L22.7388 23.5038C23.0438 23.5848 23.3508 23.6248 23.6548 23.6248C24.8368 23.6248 25.9548 23.0248 26.6088 21.9828L31.9408 13.4848C32.1958 13.0768 32.2418 12.5868 32.0658 12.1388Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3257 15.3548C32.9857 15.1418 32.5827 15.0738 32.1927 15.1658C31.8027 15.2548 31.4707 15.4928 31.2587 15.8328L27.7957 21.3738C27.5837 21.7128 27.5157 22.1158 27.6057 22.5058C27.6967 22.8958 27.9327 23.2278 28.2727 23.4408C28.5197 23.5948 28.7937 23.6678 29.0647 23.6678C29.5657 23.6678 30.0547 23.4178 30.3397 22.9628L33.8027 17.4208C34.2407 16.7198 34.0267 15.7928 33.3257 15.3548Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 26.5002V21.8762L12 22.6802V26.5002C12 28.9822 9.981 31.0002 7.5 31.0002H5V31.0001H2V28.0001H5V28.0002H7.5C8.327 28.0002 9 27.3272 9 26.5002Z"
    />
  </SvgWrapper>
);
