import { ComponentProps } from 'react';
import clsx from 'clsx';
import Flex from 'antd/es/flex';

interface OpFlexProps extends ComponentProps<typeof Flex> {
  testId?: string;
}

export const OpFlex = ({
  className,
  testId = 'op-row',
  ...flexProps
}: OpFlexProps) => {
  return (
    <Flex
      className={clsx('op-flex', className)}
      data-testid={testId}
      {...flexProps}
    />
  );
};
