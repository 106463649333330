import { useTranslation } from 'react-i18next';
import { useShowSitePartitioningItems } from 'utils/customHooks/useShowSitePartitioningItems';
import intersection from 'lodash/intersection';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { ComponentProps } from 'react';
import { OpSiteSpecificUserDataFetchTransferProps } from './types';
import { OpTooltip } from '../OpTooltip/OpTooltip';
import { OpDataFetchTransfer } from '../OpDataFetchTransfer/OpDataFetchTransfer';
import { OpText } from '../OpText/OpText';

/**
 * Component that wraps `OpDataFetchTransfer` to fetch and display users in a way appropriate for
 * site specific access when enabled.
 *
 * @param {OpSiteSpecificUserDataFetchTransferProps} props - The properties for the component.
 * @param {string} props.orgId - The organization ID used to fetch site data.
 * @param {object} props.opDataFetchTransferProps - Additional properties passed to `OpDataFetchTransfer`.
 *
 * @returns {JSX.Element} The rendered `OpSiteSpecificUserDataFetchTransfer` component.
 */
export const OpSiteSpecificUserDataFetchTransfer = ({
  orgId,
  existingItemsQueryOptions,
  existingItemsLabel,
  ...opSiteSpecificUserDataFetchTransferProps
}: OpSiteSpecificUserDataFetchTransferProps) => {
  const { t } = useTranslation();

  const { isShowingSitePartitioningItems } =
    useShowSitePartitioningItems(orgId);

  const { data: sitesData } = useOpQuery({
    apiEndpointName: 'listSites',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  const createLabel: ComponentProps<
    typeof OpDataFetchTransfer
  >['createLabel'] = (record) => (
    <OpTooltip
      title={
        record.disabled
          ? t('Your site-specific role prevents management of this user')
          : null
      }
      placement="right"
    >
      <div>
        <OpText ellipsis>
          {record.title !== '--'
            ? `${record.title} (${record.key})`
            : `(${record.key})`}
        </OpText>
        <OpText ellipsis>{record.description}</OpText>
      </div>
    </OpTooltip>
  );

  return (
    <OpDataFetchTransfer
      // TODO - Find a way to prevent users that can not be added (e.g. users from the error message: "At least one user (id 15346 - Biz Dev) cannot be added to this group because the user belongs to an identity provider that has "auto-assign to groups" and "auto-remove users from groups" options turned on." -org 338 in DEV)
      availableItemsParams={{
        queryOptions: {
          apiEndpointName: 'listUsers',
          parameters: [
            orgId,
            {
              preFilter: 'status:(!=I)',
              ...(isShowingSitePartitioningItems && {
                options: 'withUserSites',
              }),
            },
          ],
        },
        createTitle: (item) => item.identity.fullName || '--',
        createDescription: (item) => item.identity.email,
      }}
      existingItemsParams={{
        queryOptions: existingItemsQueryOptions,
        createTitle: (item) => item.identity.fullName || '--',
        createDescription: (item) => item.identity.email,
        createDisabled: (item) => {
          if (!isShowingSitePartitioningItems) {
            return false;
          }

          const userSiteIds =
            item.sites?.map(({ id }: { id: number }) => id) || [];
          const siteIds = sitesData?.map(({ id }: { id: number }) => id) || [];

          /**
           * If the user has site(s) assigned to it and the signed in user
           * doesn't have access to any of those sites, disable the user
           */
          return (
            userSiteIds.length > 0 && !intersection(userSiteIds, siteIds).length
          );
        },
      }}
      createLabel={createLabel}
      existingItemsLabel={existingItemsLabel}
      // Custom filter option that checks both title and description
      filterOption={(inputValue, option) => {
        if (option.title && option.description) {
          return (
            option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            option.description.toLowerCase().indexOf(inputValue.toLowerCase()) >
              -1
          );
        }

        return false;
      }}
      {...opSiteSpecificUserDataFetchTransferProps}
    />
  );
};
