import { ComponentProps, MouseEvent, ReactNode } from 'react';
import Select, { DefaultOptionType } from 'antd/es/select';
import Tag from 'antd/es/tag';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { ChevronDownSvg } from 'components/svgs/ChevronDownSvg';
import clsx from 'clsx';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import './OpSelect.scss';

type InternalSelect<V, O extends DefaultOptionType> = Omit<
  ComponentProps<typeof Select<V, O>>,
  'options' | 'mode' | 'value' | 'onChange' | 'labelInValue'
>;

export type SelectableValueTypes = string | number | null;

export interface OpSelectOptionType<
  T extends SelectableValueTypes = SelectableValueTypes,
> extends DefaultOptionType {
  label?: ReactNode;
  value: T;
}

type CommonTypes<
  ValueType extends SelectableValueTypes,
  OptionType extends DefaultOptionType,
> = InternalSelect<ValueType | ValueType[], OptionType> & {
  gtm?: string;
  testId?: string;
  tagTestId?: string;
  labelValidator?: (value: ReactNode) => boolean;
  options?: OptionType[];
};

export type OpSelectSingleProps<
  ValueType extends SelectableValueTypes,
  OptionType extends DefaultOptionType = OpSelectOptionType<ValueType>,
> = {
  value?: ValueType;
  onChange?: (value: ValueType, option: OptionType) => void;
} & CommonTypes<ValueType, OptionType>;

export type OpSelectMultipleProps<
  ValueType extends SelectableValueTypes,
  OptionType extends DefaultOptionType,
> = {
  value?: ValueType[];
  onChange?: (values: ValueType[], options: OptionType[]) => void;
} & CommonTypes<ValueType, OptionType>;

type OpSelectProps<
  ValueType extends SelectableValueTypes,
  OptionType extends DefaultOptionType,
> =
  | ({ mode?: undefined } & OpSelectSingleProps<ValueType, OptionType>)
  | ({ mode: 'multiple' | 'tags' } & OpSelectMultipleProps<
      ValueType,
      OptionType
    >);

/**
 * @component
 * @description Main select component to be used. When using a Form you can use the OpForm.Select component which is a wrapper around this component. There is also OpDataFetchSelect if you need the select to fetch external api data. If you want to sort options passed alphabetically you should use our utility function from utils/sortOptionsAlphabetically
 *
 * @example
 * <OpSelect
 *  options={options}
 *  filterSort={sortOptionsAlphabetically}
 * />
 */
export const OpSelect = <
  ValueType extends SelectableValueTypes,
  OptionType extends DefaultOptionType = OpSelectOptionType<ValueType>,
>({
  className,
  gtm,
  testId = 'op-select',
  tagTestId = 'op-select-tag',
  labelValidator,
  suffixIcon = <ChevronDownSvg />,
  // suffixIcon = <ChevronDownSvg fill={'#65697C'} />,
  ...selectProps
}: OpSelectProps<ValueType, OptionType>) => {
  const tagRender = ({ label, closable, onClose }: CustomTagProps) => {
    const validationError =
      labelValidator && label && !labelValidator(String(label));

    const onPreventMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        bordered={false}
        className={clsx('op-select__tag', {
          'op-select__tag--error': validationError,
        })}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        data-testid={tagTestId}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      className={clsx('op-select', className)}
      // As we are using a custom suffixIcon we need to show the loading icon manually when loading
      suffixIcon={selectProps.loading ? <LoadingOutlined /> : suffixIcon}
      tagRender={tagRender}
      // Defaulting to label as Select should always have options and antd docs recommend using label
      optionFilterProp="label"
      // Casting to any as we handle the types ourselves
      {...(selectProps as any)}
      data-testid={testId}
      data-gtm={gtm}
    />
  );
};

OpSelect.Option = Select.Option;
