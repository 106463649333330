import InputNumber from 'antd/es/input-number';
import { ComponentProps, forwardRef } from 'react';
import clsx from 'clsx';

import './OpInputNumber.scss';

interface OpInputNumberProps
  extends ComponentProps<typeof InputNumber<string | number>> {
  testId?: string;
  gtm?: string;
}

export const OpInputNumber = forwardRef<HTMLInputElement, OpInputNumberProps>(
  (
    { testId = 'op-input-number', className, gtm, ...inputNumberProps },
    ref,
  ) => {
    return (
      <InputNumber
        ref={ref}
        className={clsx('op-input-number', className)}
        data-testid={testId}
        data-gtm={gtm}
        {...inputNumberProps}
      />
    );
  },
);
