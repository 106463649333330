// Add to this as needed
export const featureCodes = {
  users: 1,
  basicRulesEngine: 30,
  advancedRulesEngine: 31,
  voiceAssistant: 72,
  samlSso: 85,
  scheduledReports: 66,
  subOrgsAccess: 19,
  subOrgsVideo: 81,
  mobileCredentials: 93,
};
