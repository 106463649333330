import opConfig from 'openpathConfig';
import { getAccessToken } from './accessToken';
import { setUnifiedCookie } from './setUnifiedCookie';

/** Used for local development against a remote deployment url. Running the `proxy-server` will allow api calls to make it through successfully */
const { AWARE_PROXY_URL, IS_NEW_UNIFICATION_AUTH_ENABLED } = opConfig;

type AwareClient = {
  [K in keyof OP.Aware.Client]: (
    params: Parameters<OP.Aware.Client[K]>[0],
  ) => ReturnType<OP.Aware.Client[K]> | null;
};

interface AwareRequest<T extends Record<string, any> = Record<string, any>> {
  url: string;
  method?: 'PUT' | 'GET' | 'PATCH' | 'POST';
  body?: T;
}

interface RequestParams {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH';
  credentials: 'include';
  headers: Record<string, string>;
  body?: string;
}

const awareRequest = async <
  T extends Record<string, any> = Record<string, any>,
>({
  url,
  method = 'GET',
  body,
}: AwareRequest<T>) => {
  const accessToken = getAccessToken();
  const params: RequestParams = {
    method,
    credentials: 'include', // Needed to send the Aware cookie with the request for authentication
    headers: {},
  };

  if (IS_NEW_UNIFICATION_AUTH_ENABLED) {
    params.headers = {
      ...params.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  if (body) {
    params.body = JSON.stringify(body);
    params.headers = {
      'Content-Type': 'application/json',
    };
  }

  // Legacy set cookie first. moved out of useAwareClient into here
  if (!IS_NEW_UNIFICATION_AUTH_ENABLED) {
    await setUnifiedCookie({ baseUrl: url });
  }

  let response = await fetch(url, params);

  // Lets suppress this for now (in QueryClient in App.js)
  // If we want to show a message just add it to the object below and remove suppressErrorMessage
  const errorObject = {
    suppressErrorMessage: true,
  };

  if (!response.ok) {
    if (!IS_NEW_UNIFICATION_AUTH_ENABLED) {
      throw errorObject;
    }

    // Try set a cookie and try once more (attempting legacy auth)
    // need to remove the Bearer token before we retry
    delete params.headers.Authorization;
    await setUnifiedCookie({ baseUrl: url });

    response = await fetch(url, params);

    if (!response.ok) {
      throw errorObject;
    }
  }

  // Check if the response body is empty
  if (response.headers.get('content-length') === '0') {
    return null;
  }

  // Try to parse the response body as JSON
  let data;
  try {
    data = await response.json();
  } catch (err) {
    console.error('Failed to parse JSON:', err);
    throw err;
  }

  return data;
};

/**
 * @description Main client utility for making api calls to Aware. This is being build on a as
 * needed basis thus should be extended as use cases increase. An example of this being used can
 * be found within useAwareQuery which is used within the PermissionedAwareDeepLink component. The utility
 * is typed so that when you need specific parameters for example in describeDevicePermission you will
 * get proper Typescript help to know when you need them and what they are
 */
export const awareClient: AwareClient = {
  describeUser: async ({ deploymentUrl }) => {
    const normalizedUrl = deploymentUrl.replace(/\/$/, '');
    const finalUrl = AWARE_PROXY_URL || normalizedUrl;

    return awareRequest({ url: `${finalUrl}/api/v1/me` });
  },

  describeDevicePermission: async ({ deploymentUrl, parameters }) => {
    const normalizedUrl = deploymentUrl.replace(/\/$/, '');
    const finalUrl = AWARE_PROXY_URL || normalizedUrl;

    return awareRequest({
      url: `${finalUrl}/api/v1/permissions/${parameters?.deviceId}`,
    });
  },

  lookupUser: async ({ deploymentUrl }) => {
    const normalizedUrl = deploymentUrl.replace(/\/$/, '');
    const finalUrl = AWARE_PROXY_URL || normalizedUrl;

    return awareRequest({
      url: `${finalUrl}/api/v1/public/lookupUser`,
    });
  },

  listRules: async ({ deploymentUrl }) => {
    const normalizedUrl = deploymentUrl.replace(/\/$/, '');
    const finalUrl = AWARE_PROXY_URL || normalizedUrl;

    return awareRequest({
      url: `${finalUrl}/api/v1/rules3`,
    });
  },

  listRulesPermissions: async ({ deploymentUrl }) => {
    const normalizedUrl = deploymentUrl.replace(/\/$/, '');
    const finalUrl = AWARE_PROXY_URL || normalizedUrl;

    return awareRequest({
      url: `${finalUrl}/api/v1/rules3Permissions`,
    });
  },

  updateRule: async ({ deploymentUrl, parameters }) => {
    const normalizedUrl = deploymentUrl.replace(/\/$/, '');
    const finalUrl = AWARE_PROXY_URL || normalizedUrl;
    const [awareRuleId, body] = parameters;

    return awareRequest<(typeof parameters)[1]>({
      url: `${finalUrl}/api/v1/rules3/${awareRuleId}`, // The uuid of the rule
      method: 'PUT',
      body,
    });
  },
};
