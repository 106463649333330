const upcUrl: Record<string, string> = {
  prod: 'https://partner.alta.avigilon.com',
  prodeu1: 'https://partner.eu.alta.avigilon.com',
  dev: 'https://partner.dev.alta.avigilon.com',
  sandbox: 'https://platinum.sandbox.alta.avigilon.com',
  mine: 'http://localhost:3000/partner',
  test: 'http://localhost:3000/partner',
};

export const getUpcUrl = (region: string): string => {
  return `${upcUrl[region]}`;
};
