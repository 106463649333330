import { ComponentProps } from 'react';
import Steps from 'antd/es/steps';
import clsx from 'clsx';

interface OpStepsProps extends ComponentProps<typeof Steps> {
  testId?: string;
}

export const OpSteps = ({
  children,
  className,
  testId,
  ...stepsProps
}: OpStepsProps) => (
  <Steps
    className={clsx('op-steps', className)}
    data-testid={testId}
    {...stepsProps}
  />
);
