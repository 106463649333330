import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { getOpParams } from 'utils/getOpParams';
import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getAccessToken } from 'utils/accessToken';
import { validateScopes, getActiveScopes } from 'utils/scopes';
import { useCreateNavItem } from 'new-components/OpNav/helpers/useCreateNavItem';
import { isFalsy } from 'utils/isFalsy';
import { baseRoute } from '../../../../partnerCenterApp/constants/routes';

export const useCreateAppItems = ({
  rawMenuItems,
  selectedPrimaryMenuKey,
  setSelectedPrimaryMenuKey,
}: {
  rawMenuItems: OpRawMenuItemType[];
  selectedPrimaryMenuKey: string | null;
  setSelectedPrimaryMenuKey: Dispatch<SetStateAction<string | null>>;
}) => {
  const navigate = useNavigate();
  const { orgId } = getOpParams();
  const accessToken = getAccessToken();

  // Fetch the current org information
  const { data: orgData, isLoading: isLoadingOrg } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  // Fetch org features to check against requiredFeatureCode
  const { data: orgFeatureData, isLoading: isLoadingOrgFeature } = useOpQuery({
    apiEndpointName: 'listOrgFeatures',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  const { data: tokenData, isLoading: isLoadingToken } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    select: (data) => data.json.data,
  });

  const onClick = useCallback(
    (route: string) => {
      navigate(
        `${baseRoute.replace(':orgId', orgId.toString() ?? '')}/${route}`,
      );
    },
    [navigate, orgId],
  );

  const shouldShowItem = useCallback(
    (item: OpRawMenuItemType) => {
      // Dont check until we have everything needed or
      // If the config item has hidden explicitly set to true, dont need to check anything else.
      if (
        item.hidden ||
        isLoadingOrg ||
        isLoadingOrgFeature ||
        isLoadingToken
      ) {
        return false;
      }

      // Get the active scopes for the current user
      const orgScopes = getActiveScopes({
        tokenScopeList: tokenData?.tokenScopeList ?? [],
        orgId: Number(orgId),
        parentOrgId: orgData?.parentOrg?.id,
      });
      const partnerScopes = getActiveScopes({
        tokenScopeList: tokenData?.tokenScopeList ?? [],
        parentOrgId: orgData?.parentOrg?.id,
      });
      const activeScopes = [...orgScopes, ...partnerScopes];

      // If the menu item has allowedScopes, validate the user's scopes
      if (
        item.scope &&
        !validateScopes({
          activeScopes,
          rawAllowedScopes: item.scope ?? [],
          orgId: orgId.toString() ?? '',
        })
      ) {
        return false;
      }

      if (orgId ? item.admin : !item.admin) return false;
      // Check the org for the requiredFeatureCode

      if (
        item.requiredFeatureCode &&
        !orgFeatureData?.find(
          (feature) => feature.code === item.requiredFeatureCode,
        )
      ) {
        return false;
      }
      return true;
    },
    [
      isLoadingOrg,
      isLoadingOrgFeature,
      isLoadingToken,
      orgData?.parentOrg?.id,
      orgFeatureData,
      orgId,
      tokenData?.tokenScopeList,
    ],
  );

  const navItemOnClick = useCallback(
    (item: OpRawMenuItemType) => {
      onClick(item.route ?? '');
    },
    [onClick],
  );

  const { createNavItem } = useCreateNavItem({
    shouldShowItem,
    selectedPrimaryMenuKey,
    setSelectedPrimaryMenuKey,
    onClick: navItemOnClick,
  });

  const menuItems = useMemo(() => {
    return rawMenuItems
      .map((rawMenuItem) => createNavItem(rawMenuItem, true))
      .filter(isFalsy);
  }, [rawMenuItems, createNavItem]);

  return menuItems;
};
