import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useAppContext } from 'new-components/OpAppScaffold/AppContext';
import { getWindowLocation } from 'utils/window';
import { getMailroomOrigin } from 'utils/getMailroomOrigin';

export const useNavigateToMailroom = ({ orgId }: { orgId: number }) => {
  const { app } = useAppContext();
  const navigate = useNavigate();

  const handleNavigateToMailroom = useCallback(() => {
    if (app === 'mailroom') {
      navigate(0);
      return;
    }

    getWindowLocation().href = `${getMailroomOrigin()}/o/${orgId}`;
  }, [app, navigate, orgId]);

  return {
    handleNavigateToMailroom,
  };
};
