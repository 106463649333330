import { getOpParams } from '../getOpParams';
import { useOpQuery } from './useOpQuery';

export const useAwareDeploymentUrl = (): string | undefined => {
  const { orgId } = getOpParams();
  const { data } = useOpQuery({
    apiEndpointName: 'listVideoProviders',
    parameters: [orgId],
  });

  const awareVideoProvider = data?.json?.data?.find(
    (provider) => provider.videoProviderTypeId === 5,
  );

  const awareDeploymentUrl = awareVideoProvider?.authState?.serverAddress;

  return awareDeploymentUrl;
};
