import { ComponentProps } from 'react';
import clsx from 'clsx';
import { OpTimePicker } from 'new-components/DLS/OpTimePicker/OpTimePicker';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { v4 as uuidv4 } from 'uuid';
import { useOpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

interface OpFormTimePickerProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      | 'name'
      | 'label'
      | 'tooltip'
      | 'rules'
      | 'validateTrigger'
      | 'validateStatus' // Temp: For SUIR wrapped version
      | 'help' // Temp: For SUIR wrapped version
      | 'required' // Temp: For SUIR wrapped version
    >,
    Omit<ComponentProps<typeof OpTimePicker>, 'name'> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormTimePicker = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,
  rules,
  validateTrigger,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,
  testId,
  ...elementProps
}: OpFormTimePickerProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useOpFormContext();

  const uuid = uuidv4();
  const opTimePickerId = `op-time-picker-${uuid}`;

  return (
    <OpForm.Item
      className={clsx('op-form-time-picker', formItemClassName)}
      name={name}
      label={label}
      tooltip={tooltip}
      rules={rules}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
      {...(!name && { htmlFor: opTimePickerId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
    >
      {isDataLoading || isLoading ? (
        /** A placeholder in the shape of the component
         * so we can immediately render the structure of the form while
         * data is loading */
        <OpSkeleton.Input active block />
      ) : (
        <OpTimePicker
          disabled={isReadOnly || disabled}
          {...(!name && { id: opTimePickerId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
          {...elementProps}
          testId={testId}
        />
      )}
    </OpForm.Item>
  );
};
