import { featureCodes } from 'constants/featureCodes';

export const checkAuthIsUPCPartnerOrg = (
  tokenScopeItem?: Utils.ArrayElement<
    Api.Response['describeAccessToken']['tokenScopeList']
  >,
) => {
  if (!tokenScopeItem) return false;

  const { scope, org } = tokenScopeItem;

  // Check if any package features associated with the org are for a partner
  const hasSubOrgsFeature = org?.packagePlans?.some((packagePlan) => {
    return packagePlan?.package?.packageFeatures?.some((packageFeature) =>
      [featureCodes.subOrgsAccess, featureCodes.subOrgsVideo].includes(
        packageFeature.feature?.id || -1,
      ),
    );
  });

  // Check partner scopes
  const hasPartnerScope = scope?.some((scopeItem) =>
    ['s-o:w', 's-o:r', 's-o-video:w', 's-o-video:r'].includes(scopeItem),
  );

  return Boolean(hasPartnerScope && hasSubOrgsFeature && org?.isUnified);
};
