import { useCreateAppDefaultKeys } from 'new-components/OpAppNav/helpers/useCreateAppDefaultKeys';
import { OpNav } from 'new-components/OpNav/OpNav';
import { ProductBranding } from 'new-components/ProductBranding/ProductBranding';

import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import clsx from 'clsx';
import { useCreateAppItems } from './helpers/useCreateAppItems';

import './OpAppNav.scss';

export const OpAppNav = ({
  items,
  title,
  onLogoClick,
  setIsShowingNavOnMobile,
  hidden,
}: {
  items: OpRawMenuItemType[];
  title: string;
  onLogoClick?: () => void;
  setIsShowingNavOnMobile: Dispatch<SetStateAction<boolean>>;
  hidden?: boolean;
}) => {
  const [selectedPrimaryMenuKey, setSelectedPrimaryMenuKey] = useState<
    string | null
  >(null);

  const navItems = useCreateAppItems({
    rawMenuItems: items,
    selectedPrimaryMenuKey,
    setSelectedPrimaryMenuKey,
  });

  const { defaultPrimaryKey, defaultSecondaryKey } = useCreateAppDefaultKeys({
    rawMenuItems: items,
  });

  useEffect(() => {
    setSelectedPrimaryMenuKey(defaultPrimaryKey);
  }, [defaultPrimaryKey]);

  const classes = clsx('op-app-nav', {
    'op-app-nav--hidden': hidden,
  });

  return (
    <div className={classes}>
      <div className="op-app-nav__close-button-container">
        <OpButton
          icon={<CloseOutlined />}
          onClick={() => setIsShowingNavOnMobile(false)}
        />
      </div>
      <OpNav
        logo={<ProductBranding label={title} testId={`${title}-logo`} />}
        items={navItems}
        defaultPrimaryKey={defaultPrimaryKey}
        defaultSecondaryKey={defaultSecondaryKey}
        onLogoClick={onLogoClick}
      />
    </div>
  );
};
