import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from 'routes/AppContainer/actions';
import { unifiedLogin, UnifiedLoginArgs } from 'utils/unifiedLogin';

interface UseUnifiedLoginArgs extends Omit<UnifiedLoginArgs, 'url'> {
  url?: string;
}

export const useUnifiedLogin = ({
  url,
  urlToOpen,
  options,
  newTab = true,
}: UseUnifiedLoginArgs) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();

  const handleUnifiedLogin = useCallback(async () => {
    setIsLoggingIn(true);
    try {
      if (url) {
        await unifiedLogin({ url, urlToOpen, options, newTab });
      } else {
        throw Error('No deployment url provided');
      }
    } catch (err) {
      const typedError = err as Error;

      dispatch(setAlert('error', typedError.message, null, { timeout: 5000 }));
    } finally {
      setIsLoggingIn(false);
    }
  }, [dispatch, newTab, options, url, urlToOpen]);

  return {
    handleUnifiedLogin,
    isLoggingIn,
  };
};
