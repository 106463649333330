import { useTranslation } from 'react-i18next';
import { PencilSvg } from 'components/svgs/PencilSvg';
import { EyeSvg } from 'components/svgs/EyeSvg/EyeSvg';
import { TrashSvg } from 'components/svgs/TrashSvg';
import {
  OpCustomRowActionButton,
  OpRowActionFuncParams,
  OpRowActions,
} from 'new-components/DLS/OpTable/OpTable';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { MouseEvent, ReactNode } from 'react';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { OpTableRecordType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { OpTooltip } from 'new-components/DLS/OpTooltip/OpTooltip';
import { useHasReadWriteAccess } from 'utils/customHooks/useHasReadWriteAccess';

export const RowActions = ({
  rowActions,
  record,
  numRecords,
}: {
  rowActions: OpRowActions;
  record: OpTableRecordType;
  numRecords?: number;
}) => {
  const { t } = useTranslation();

  const { hasWriteAccess } = useHasReadWriteAccess();

  const confirmModal = useConfirmModal();

  const onEditClick = () => {
    rowActions.onEditClick?.(record);
  };

  const onDeleteClick = () => {
    rowActions.onDeleteClick?.(record);
  };

  function getFinalText(
    property:
      | ReactNode
      | ((params: OpRowActionFuncParams) => ReactNode)
      | undefined,
    defaultText: string,
  ): ReactNode {
    return typeof property === 'function'
      ? property({ record, numRecords })
      : typeof property === 'string'
        ? property
        : defaultText;
  }

  const handleCustomClick = (
    e: MouseEvent,
    onClick: (record: OpTableRecordType) => void,
  ) => {
    e.stopPropagation();
    onClick(record);
  };

  const handleDeleteClick = (e: MouseEvent) => {
    // Prevents edit callback from firing
    e.stopPropagation();
    const title = getFinalText(
      rowActions.deleteModalTitle,
      t('Confirm delete'),
    );

    const content = getFinalText(
      rowActions.deleteModalContent,
      t('Are you sure you want to delete this?'),
    );

    const cancelText = getFinalText(
      rowActions.deleteModalCancelText,
      t('Cancel'),
    );

    const okText = getFinalText(rowActions.deleteModalOkText, t('Yes'));

    confirmModal({
      title,
      content,
      cancelText,
      okText,
      onOk() {
        onDeleteClick();
      },
    });
  };

  const getCustomActionButton = (
    action: OpCustomRowActionButton,
    idx: number,
  ) => {
    if (action.icon) {
      return (
        <OpButton
          key={idx}
          testId="custom-table-item-button"
          onClick={(e) => handleCustomClick(e, action.onClick)}
          size="small"
          shape="circle"
          type="text"
          icon={action.icon}
        />
      );
    }
    // icon and getIcon can't exist simultaneously
    if (action.getIcon(record)) {
      return (
        <OpButton
          key={idx}
          testId="custom-table-item-button"
          onClick={(e) => handleCustomClick(e, action.onClick)}
          size="small"
          shape="circle"
          type="text"
          icon={action.getIcon(record)}
        />
      );
    }
    // If the custom icon is null, we treat this as a defacto 'don't show this button'
    return null;
  };

  return (
    <OpSpace size={0}>
      {rowActions.additionalActions &&
        rowActions.additionalActions.map((action, idx) =>
          action?.tooltip ? (
            <OpTooltip key={idx} title={action.tooltip}>
              {getCustomActionButton(action, idx)}
            </OpTooltip>
          ) : (
            getCustomActionButton(action, idx)
          ),
        )}
      {rowActions.onEditClick && (
        <OpButton
          testId="edit-table-item-button"
          onClick={(e) => {
            e.stopPropagation();
            onEditClick();
          }}
          size="small"
          shape="circle"
          type="text"
          icon={hasWriteAccess ? <PencilSvg /> : <EyeSvg />}
        />
      )}
      {rowActions.onDeleteClick && (
        <OpButton
          testId="delete-table-item-button"
          size="small"
          shape="circle"
          type="text"
          icon={<TrashSvg />}
          onClick={handleDeleteClick}
        />
      )}
    </OpSpace>
  );
};
