import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { OpNavMenuKey } from 'new-components/OpNav/components/OpNavMenu/types';

const findKey = (items: OpRawMenuItemType[], key: string): OpNavMenuKey => {
  for (const item of items) {
    if (item.key === key) {
      return item.key;
    }
    if (item.items) {
      const foundKey = findKey(item.items, key);
      if (foundKey) {
        return foundKey;
      }
    }
  }

  return null;
};

export const findNavDefaultKeys = ({
  rawMenuItems,
  pathToMatch,
  notFoundPrimaryKey,
}: {
  rawMenuItems: OpRawMenuItemType[];
  pathToMatch: string;
  notFoundPrimaryKey: string;
}) => {
  if (!pathToMatch) {
    return {
      defaultPrimaryKey: '',
      defaultSecondaryKey: '',
    };
  }

  for (const item of rawMenuItems) {
    // Allow id paths to match like /customers/123
    const pathCheck = new RegExp(`^${item.key}(\\/\\d{1,9})?$`);
    if (pathCheck.test(pathToMatch)) {
      return {
        defaultPrimaryKey: item.key,
        defaultSecondaryKey: '',
      };
    }

    if (item.items) {
      for (const subItem of item.items) {
        if (subItem.key === pathToMatch) {
          return {
            defaultPrimaryKey: item.key,
            defaultSecondaryKey: subItem.key,
          };
        }

        if (subItem.type === 'group' && subItem.items) {
          const foundKey = findKey(subItem.items, pathToMatch);
          if (foundKey) {
            return {
              defaultPrimaryKey: item.key,
              defaultSecondaryKey: foundKey,
            };
          }
        }
      }
    }
  }

  return {
    defaultPrimaryKey: notFoundPrimaryKey,
    defaultSecondaryKey: '',
  };
};
