import { ComponentProps, ReactElement, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { OpDrawer } from '../OpDrawer/OpDrawer';
import { FormButtons } from '../OpForm/FormButtons';
import { OpForm } from '../OpForm/OpForm';
import { OpFormDrawerContextProvider } from './OpFormDrawerContext';

interface OpFormDrawerProps extends ComponentProps<typeof OpDrawer> {
  formComponent: ReactElement;
}

export const OpFormDrawer = <T extends Store>({
  onClose,
  formComponent,
  ...opDrawerProps
}: OpFormDrawerProps) => {
  const { t } = useTranslation();
  const confirmModal = useConfirmModal();

  const [form] = OpForm.useForm<T>();

  const finalOnClose: OpFormDrawerProps['onClose'] = (e) => {
    if (form.isFieldsTouched()) {
      confirmModal({
        title: t('Confirm discard?'),
        content: t(
          'You have unsaved changes. Are you sure you wish to discard them?',
        ),
        cancelText: t('No'),
        closable: true,
        okText: t('Yes'),
        onOk: () => {
          form.resetFields();
          onClose?.(e);
        },
      });
    } else {
      onClose?.(e);
    }
  };

  // Add the form instance to passed form component
  const modifiedForm = cloneElement(formComponent, {
    form,
    defaultButtons: false,
  });

  return (
    <OpFormDrawerContextProvider>
      <OpDrawer
        data-testid="op-form-drawer"
        footer={
          <FormButtons
            form={form}
            isReadOnly={formComponent.props.isReadOnly}
            tooltip={formComponent.props.tooltip}
          />
        }
        onClose={finalOnClose}
        /**
         * This prevents an issue where the form data is retained when the
         * drawer is closed and reopened
         *
         * E.g. when editing a form, closing the drawer, and then opening
         * the drawer again to create a new form
         */
        destroyOnClose
        {...opDrawerProps}
      >
        {modifiedForm}
      </OpDrawer>
    </OpFormDrawerContextProvider>
  );
};
