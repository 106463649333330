import clsx from 'clsx';
import { OpSelect, OpSelectSingleProps } from '../OpSelect/OpSelect';
import timezones from './timezones.json';

export const OpTimezonePicker = ({
  testId = 'op-timezone-picker',
  gtm,
  children,
  className,
  ...opSelectProps
}: Omit<OpSelectSingleProps<string, any>, 'options'>) => {
  const timezoneOptions = Object.entries(timezones).map(([label, value]) => ({
    value,
    label,
  }));

  return (
    <OpSelect
      className={clsx('op-timezone-picker', className)}
      testId={testId}
      gtm={gtm}
      showSearch
      {...opSelectProps}
      options={timezoneOptions}
    />
  );
};
