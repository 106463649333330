import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { t as translate } from 'i18next';
import clsx from 'clsx';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { Store } from 'antd/es/form/interface';
import { OpButton } from '../OpButton/OpButton';
import { useOpFormContext } from './OpFormContext';
import { OpTooltip } from '../OpTooltip/OpTooltip';
import { OpForm } from './OpForm';
import { OpFlex } from '../OpFlex/OpFlex';
import { useOpFormDrawerContext } from '../OpFormDrawer/OpFormDrawerContext';

import './FormButtons.scss';

interface FormButtonsProps<T extends Store> {
  className?: string;
  form: ComponentProps<typeof OpForm<T>>['form'];
  isFormLoading?: boolean;
  isSubmitButtonLoading?: boolean;
  submitButtonLabel?: string;
  resetButtonLabel?: string;
  isReadOnly?: boolean;
  tooltip?: string;
  gtm?: string;
  onResetClick?: () => void;
}

export const FormButtons = <T extends Store = Store>({
  className,
  form,
  isFormLoading = false,
  isSubmitButtonLoading = false,
  submitButtonLabel = translate('Save'),
  resetButtonLabel = translate('Reset'),
  isReadOnly = false,
  tooltip,
  gtm,
  onResetClick,
}: FormButtonsProps<T>) => {
  const confirmModal = useConfirmModal();
  const { t } = useTranslation();
  const { isSubmitting: isDrawerFormSubmitting } = useOpFormDrawerContext();
  const { isSubmitting: isCoreFormSubmitting } = useOpFormContext();

  const isSubmitting = isDrawerFormSubmitting || isCoreFormSubmitting;

  const handleResetClick = () => {
    if (form?.isFieldsTouched()) {
      confirmModal({
        title: t('Confirm discard?'),
        content: t(
          'You have unsaved changes. Are you sure you wish to discard them?',
        ),
        cancelText: t('No'),
        closable: true,
        okText: t('Yes'),
        onOk: () => {
          form.resetFields();
          onResetClick?.();
        },
      });
      return;
    }

    onResetClick?.();
  };

  const onSubmitClick = () => {
    form?.submit();
    onResetClick?.();
  };

  return (
    <OpTooltip title={tooltip} placement="topRight">
      <OpFlex
        className={clsx('form-buttons', className)}
        justify="end"
        align="center"
        gap="middle"
      >
        <OpButton
          gtm={gtm && `${gtm}-reset-form-button`}
          testId="reset-form-button"
          onClick={handleResetClick}
          disabled={isReadOnly || isFormLoading || isSubmitting}
        >
          {resetButtonLabel}
        </OpButton>
        <OpButton
          gtm={gtm && `${gtm}-submit-form-button`}
          testId="submit-form-button"
          type="primary"
          onClick={onSubmitClick}
          disabled={isReadOnly || isFormLoading}
          loading={isSubmitting || isSubmitButtonLoading}
        >
          {submitButtonLabel}
        </OpButton>
      </OpFlex>
    </OpTooltip>
  );
};
