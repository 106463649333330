import { MenuProps } from 'antd/es/menu';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { OpDropdown } from 'new-components/DLS/OpDropdown/OpDropdown';
import { AppSwitcherSvg } from 'components/svgs/AppSwitcherSvg';
import { NativeAppSwitcherMenu } from './NativeAppSwitcherMenu';
import { useNativeAppSwitcher } from './useNativeAppSwitcher';

import '../AppSwitcher/AppSwitcher.scss';
/**
 * @component
 * @description Component used to app switch between "native" apps.
 * @returns JSX.Element | null
 */
export const NativeAppSwitcher = () => {
  const { access, video, intrusion, mailroom, dmp, partnerCenter, isLoading } =
    useNativeAppSwitcher();

  const items: MenuProps['items'] = [
    {
      key: '1',
      className:
        'native-app-switcher-overlay__menu-item app-switcher-overlay__menu-item',
      icon: (
        <NativeAppSwitcherMenu
          access={access}
          mailroom={mailroom}
          video={video}
          intrusion={intrusion}
          dmp={dmp}
          partnerCenter={partnerCenter}
        />
      ),
    },
  ];

  return (
    <div data-testid="native-app-switcher">
      <OpDropdown
        trigger={['click']}
        menu={{ items }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        overlayClassName="native-app-switcher-overlay app-switcher-overlay"
      >
        <div className="native-app-switcher__icon app-switcher__icon">
          {isLoading ? <LoadingOutlined /> : <AppSwitcherSvg />}
        </div>
      </OpDropdown>
    </div>
  );
};
