import { t } from 'i18next';
import { OnSubmitArgs } from 'new-components/DLS/OpForm/OpForm';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { useSearchParams } from 'react-router-dom-v5-compat';

/**
 * Hook for RoleForm mutations.
 * @param {number} orgId - ID of the org
 * @param {number} roleId - ID of the role if in edit mode
 * @param {Boolean} isEditable - is the role editable (not a default role) - affects which calls we can make here.
 * @returns onSubmit handler for RoleForm
 */
export const useRoleFormMutation = ({
  orgId,
  roleId,
  isEditable,
}: {
  orgId: number;
  roleId?: number;
  isEditable?: boolean;
}) => {
  const [_, setSearchParams] = useSearchParams();

  /* Mutations for create and update */

  const setRoleScopeIdsMutation = useOpMutation({
    apiEndpointName: 'setRoleScopeIds',
    queryKeysToInvalidate: [['listRoleScopeIds']],
  });

  const setRoleUserIdsMutation = useOpMutation({
    apiEndpointName: 'setRoleUserIds',
    queryKeysToInvalidate: [['listRoleUsers'], ['listRoles'], ['describeRole']],
  });

  const setRoleSiteIdsMutation = useOpMutation({
    apiEndpointName: 'setRoleSiteIds',
    queryKeysToInvalidate: [['listRoles'], ['describeRole']],
  });

  // For editable roles we call this so we only get one success message. See comment above updateRoleUserIdsMutationWitSuccessMessage.
  const updateRoleUserIdsMutation = useOpMutation({
    apiEndpointName: 'updateRoleUserIds',
    queryKeysToInvalidate: [['listRoleUsers'], ['listRoles'], ['describeRole']],
  });

  // For non-editable roles (Default roles) we don't call the updateRole mutation.
  // We only allow user additions and removals. So we need a version of this with a
  // success message and a callback to close the drawer.
  const updateRoleUserIdsMutationWitSuccessMessage = useOpMutation({
    apiEndpointName: 'updateRoleUserIds',
    queryKeysToInvalidate: [['listRoleUsers']],
    onSuccessMessage: t('Successfully updated role'),
    onSuccessCallback: () => {
      setSearchParams({});
    },
  });

  const createRoleMutation = useOpMutation({
    apiEndpointName: 'createRole',
    onSuccessMessage: t('Successfully created role'),
    queryKeysToInvalidate: [['listRoles'], ['describeRole']],
    onSuccessCallback: (response, args) => {
      if (args.roleScopes) {
        setRoleScopeIdsMutation.mutate({
          apiEndpointRequirements: [orgId, response?.id!],
          payload: { scopeIds: args.roleScopes },
        });
      }

      if (args.sites) {
        setRoleSiteIdsMutation.mutate({
          apiEndpointRequirements: [orgId, response?.id!],
          payload: { siteIds: args.sites },
        });
      }

      if (args.users) {
        setRoleUserIdsMutation.mutate({
          apiEndpointRequirements: [orgId, response?.id!],
          payload: { userIds: args.users },
        });
      }

      setSearchParams({});
    },
  });

  const updateRoleMutation = useOpMutation({
    apiEndpointName: 'updateRole',
    onSuccessMessage: t('Successfully updated role'),
    queryKeysToInvalidate: [['listRoles'], ['describeRole']],
    onSuccessCallback: (response, args) => {
      if (args.roleScopes) {
        setRoleScopeIdsMutation.mutate({
          apiEndpointRequirements: [orgId, roleId!],
          payload: { scopeIds: args.roleScopes },
        });
      }

      if (args.sites) {
        setRoleSiteIdsMutation.mutate({
          apiEndpointRequirements: [orgId, roleId!],
          payload: { siteIds: args.sites },
        });
      }

      if (args.users?.add?.length > 0 || args.users?.remove?.length > 0) {
        updateRoleUserIdsMutation.mutate({
          apiEndpointRequirements: [orgId, roleId!],
          payload: args.users,
        });
      }

      setSearchParams({});
    },
  });

  /* Submit handler */
  const handleSubmit = async ({ touchedValues }: OnSubmitArgs) => {
    const {
      name,
      description,
      isMfaRequired,
      isSiteSpecific,
      sites,
      users,
      roleScopes,
    } = touchedValues;

    const payload = {
      name,
      description,
      isMfaRequired,
      isSiteSpecific,
    };

    if (roleId) {
      if (isEditable) {
        updateRoleMutation.mutate({
          apiEndpointRequirements: [orgId, roleId!],
          payload,
          sites: isSiteSpecific === false ? [] : sites,
          roleScopes,
          users,
        });
      } else {
        // only allow user add and removal for roles that arent editable
        updateRoleUserIdsMutationWitSuccessMessage.mutate({
          apiEndpointRequirements: [orgId, roleId!],
          payload: users,
        });
      }
    } else {
      createRoleMutation.mutate({
        apiEndpointRequirements: [orgId],
        payload,
        sites: isSiteSpecific === false ? [] : sites,
        roleScopes,
        users: users?.add,
      });
    }
  };

  return {
    handleSubmit,
  };
};
