import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface OpFormDrawerContextProps {
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>> | undefined;
}

const OpFormDrawerContext = createContext<OpFormDrawerContextProps>({
  isSubmitting: false,
  setIsSubmitting: undefined, // Set to undefined so we can use Form context if falsy
});

/**
 * Provides context for the OpFormDrawer, including submission state.
 *
 * @param {Object} props - The properties object.
 * @param {ReactNode} props.children - The child components that will have access to the context.
 *
 * @returns {JSX.Element} The provider component that wraps its children with the OpFormDrawer context.
 *
 * @context {boolean} isSubmitting - Indicates whether a form is currently being submitted.
 * @context {Function} setIsSubmitting - Function to update the submission state.
 */
export const OpFormDrawerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const contextValue = useMemo(
    () => ({ isSubmitting, setIsSubmitting }),
    [isSubmitting],
  );

  return (
    <OpFormDrawerContext.Provider value={contextValue}>
      {children}
    </OpFormDrawerContext.Provider>
  );
};

/**
 * Custom hook to access the OpFormDrawerContext.
 *
 * This hook provides access to the context value of the OpFormDrawerContext.
 * It should be used within a component that is a descendant of the OpFormDrawerContext provider.
 *
 * @returns {OpFormDrawerContextType} The current context value of the OpFormDrawerContext.
 */
export const useOpFormDrawerContext = () => {
  const context = useContext(OpFormDrawerContext);

  return context;
};
