import Form from 'antd/es/form';
import { OpSwitch } from 'new-components/DLS/OpSwitch/OpSwitch';
import { OpTable } from 'new-components/DLS/OpTable/OpTable';
import { useTranslation } from 'react-i18next';
import { OpTableRecordType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { useFeatureFlag } from 'utils/customHooks/useFeatureFlag';
import { ComponentProps, useCallback, useMemo } from 'react';
import { OpForm } from 'new-components/DLS/OpForm/OpForm';
import { OpInfoTooltip } from 'new-components/DLS/OpInfoTooltip/OpInfoTooltip';
import { scopeToLabelText } from './helpers/constants';
import { PermissionCell } from './PermissionCell';

interface ScopeResourceProps {
  orgId: number;
  scopeResource: Api.Response['listScopeResources'][0];
  form?: ComponentProps<typeof OpForm>['form'];
  disabled?: boolean;
}

type Scope = {
  id: number;
  parentScopeId?: number | null;
  code: string;
  value: boolean;
};

type ScopeResourceTableRecordType = {
  groupCode: string;
  permission: string;
  readScope?: Scope;
  writeScope?: Scope;
  description?: string;
  isGranular?: boolean | null;
};

const SCOPE_TYPE_IDENTIFIER = {
  Read: 'r',
  Write: 'w',
};

const isScopeInRole = ({
  scopeId,
  parentScopeId,
  roleScopes,
}: {
  scopeId: number;
  parentScopeId?: number | null;
  roleScopes: number[];
}) => {
  return !!roleScopes?.find((id) => id === scopeId || id === parentScopeId);
};

// TODO Remove when ENABLE_MAILROOM feature flag is removed
const mailroomScopes = [
  'o{orgId}-parcelMgmtParcels:r',
  'o{orgId}-parcelMgmtParcels:w',
];

export const ScopeResource = ({
  orgId,
  scopeResource,
  form,
  disabled,
}: ScopeResourceProps) => {
  const { t } = useTranslation();
  const { setFieldsValue } = Form.useFormInstance();

  const roleScopes = Form.useWatch('roleScopes', form);

  const { value: enabledUserRoleScopes } = useFeatureFlag<string[]>(
    'ENABLED_USER_ROLE_SCOPES',
    orgId,
  );

  const { value: mailroomEnabled } = useFeatureFlag<string[]>(
    'ENABLE_MAILROOM',
    orgId,
  );

  const dataSource = useMemo(
    () =>
      scopeResource.scopes?.reduce<ScopeResourceTableRecordType[]>(
        (acc, curScope) => {
          const scopes = [...acc];

          // extract the part of the scope code that is shared by both read and write -
          // not including "o{orgId}-" part to make the label text constant easier to read.
          const [, , , , scopeGroupCode] =
            curScope.code?.match(/((o){(\d*\w*)}-)?(.*?):(\w)/) ?? [];
          const scopeType = curScope.code!.split(':')[1]; // Get the r or w from the end.

          // Do not show scope if scope code is not enabled
          if (
            !enabledUserRoleScopes?.includes(curScope.code!) ||
            (!mailroomEnabled && mailroomScopes.includes(curScope.code!)) // TODO remove this line when ENABLE_MAILROOM feature flag is removed
          ) {
            return scopes;
          }

          const currentScopeGroup = scopes.find(
            (scopeGroup) => scopeGroup.groupCode === scopeGroupCode,
          );

          // if scope group already exists - add the 2nd scope
          if (currentScopeGroup) {
            if (scopeType === SCOPE_TYPE_IDENTIFIER.Read) {
              currentScopeGroup.readScope = {
                id: curScope.id,
                parentScopeId: curScope.parentScopeId,
                code: curScope.code!,
                value: isScopeInRole({
                  scopeId: curScope.id,
                  parentScopeId: curScope.parentScopeId,
                  roleScopes,
                }),
              };
            }

            if (scopeType === SCOPE_TYPE_IDENTIFIER.Write) {
              currentScopeGroup.writeScope = {
                id: curScope.id,
                parentScopeId: curScope.parentScopeId,
                code: curScope.code!,
                value: isScopeInRole({
                  scopeId: curScope.id,
                  parentScopeId: curScope.parentScopeId,
                  roleScopes,
                }),
              };

              currentScopeGroup.description = curScope.description ?? '';
            }
          } else {
            scopes.push({
              groupCode: scopeGroupCode,
              permission: scopeToLabelText(scopeGroupCode) ?? '',
              readScope:
                scopeType === SCOPE_TYPE_IDENTIFIER.Read
                  ? {
                      id: curScope.id,
                      parentScopeId: curScope.parentScopeId,
                      code: curScope.code!,
                      value: isScopeInRole({
                        scopeId: curScope.id,
                        parentScopeId: curScope.parentScopeId,
                        roleScopes,
                      }),
                    }
                  : undefined,
              writeScope:
                scopeType === SCOPE_TYPE_IDENTIFIER.Write
                  ? {
                      id: curScope.id,
                      parentScopeId: curScope.parentScopeId,
                      code: curScope.code!,
                      value: isScopeInRole({
                        scopeId: curScope.id,
                        parentScopeId: curScope.parentScopeId,
                        roleScopes,
                      }),
                    }
                  : undefined,
              description:
                scopeType === SCOPE_TYPE_IDENTIFIER.Write &&
                curScope?.description
                  ? curScope.description
                  : '',
              isGranular: !!curScope.parentScopeId,
            });
          }

          return scopes;
        },
        [],
      ) ?? [],
    [enabledUserRoleScopes, mailroomEnabled, roleScopes, scopeResource.scopes],
  );

  const getChildScopes = useCallback(
    (id: number | undefined, scopeAccessor: 'readScope' | 'writeScope') => {
      const childScopes = dataSource.reduce<Scope[]>((acc, cur) => {
        const scopes = [...acc];
        const curScope = cur[scopeAccessor];
        if (curScope && curScope?.parentScopeId === id) {
          scopes.push(curScope);
        }

        return scopes;
      }, []);

      return childScopes.map((cs) => cs.id);
    },
    [dataSource],
  );

  // NOTE: granular permissions refers to both site specific AND child scopes. its important that if the
  // parent scope is toggled on, no child scopes are in the scope list.
  const toggleWriteScope = useCallback(
    ({
      value,
      readScope,
      writeScope,
    }: {
      value: boolean;
      readScope?: Scope;
      writeScope: Scope;
    }) => {
      let updatedRoleScopes = [...(roleScopes ?? [])];

      if (value) {
        updatedRoleScopes.push(writeScope.id);

        const childScopes = getChildScopes(writeScope.id, 'writeScope');

        // Add readScope to iff not already added
        if (readScope && !updatedRoleScopes.includes(readScope.id)) {
          updatedRoleScopes.push(readScope.id);
          // Remove any child scopes for the readScope
          childScopes.push(...getChildScopes(readScope.id, 'readScope'));
        }

        updatedRoleScopes = updatedRoleScopes.filter(
          (id) => !childScopes.includes(id),
        );
      } else {
        // If the toggled writeScope has a parentScope and the parentScope is currently toggled on
        // we need to remove the parent scope and add other child scopes
        if (
          writeScope.parentScopeId &&
          roleScopes.includes(writeScope.parentScopeId)
        ) {
          // find all parent's child scopes that havent been added and aren't the current scope
          const childScopes = getChildScopes(
            writeScope.parentScopeId,
            'writeScope',
          ).filter(
            (id) => id !== writeScope.id && !updatedRoleScopes.includes(id),
          );

          updatedRoleScopes.push(...childScopes);
        }

        updatedRoleScopes = updatedRoleScopes.filter(
          (id) => id !== writeScope.id && id !== writeScope.parentScopeId,
        );
      }
      setFieldsValue({ roleScopes: updatedRoleScopes });
    },
    [getChildScopes, roleScopes, setFieldsValue],
  );

  const toggleReadScope = useCallback(
    ({
      value,
      readScope,
      writeScope,
    }: {
      value: boolean;
      readScope: Scope;
      writeScope?: Scope;
    }) => {
      let updatedRoleScopes = [...(roleScopes ?? [])];

      if (value) {
        updatedRoleScopes.push(readScope.id);
        const childScopes = getChildScopes(readScope.id, 'readScope');
        updatedRoleScopes = updatedRoleScopes.filter(
          (id) => !childScopes.includes(id),
        );
      } else {
        // If the readScope has a parent that is currently toggled on
        // we need to find all childscopes that aren't the current one and add them to the list.
        if (
          readScope.parentScopeId &&
          roleScopes.includes(readScope.parentScopeId)
        ) {
          // find all parent's child scopes
          const childScopes = getChildScopes(
            readScope.parentScopeId,
            'readScope',
          ).filter(
            (id) => id !== readScope.id && !updatedRoleScopes.includes(id),
          );
          updatedRoleScopes.push(...childScopes);
        }

        // If the writeScope has a parent that is currently toggled on
        // we need to find all childscopes that aren't the current one and add them to the list.
        if (
          writeScope?.parentScopeId &&
          roleScopes.includes(writeScope.parentScopeId)
        ) {
          // find all parent's child scopes
          const childScopes = getChildScopes(
            writeScope.parentScopeId,
            'writeScope',
          ).filter(
            (id) => id !== writeScope.id && !updatedRoleScopes.includes(id),
          );

          updatedRoleScopes.push(...childScopes);
        }

        // Finally filter out the scope that was toggled off and its parent
        updatedRoleScopes = updatedRoleScopes.filter(
          (s) =>
            ![
              readScope.id,
              readScope.parentScopeId,
              writeScope?.id,
              writeScope?.parentScopeId,
            ].includes(s),
        );
      }

      setFieldsValue({ roleScopes: updatedRoleScopes });
    },
    [getChildScopes, roleScopes, setFieldsValue],
  );

  const columns = [
    {
      label: t('Permission')!,
      dataIndex: ['permission'],
      width: 295,
      render: (permission: string, record: OpTableRecordType) => {
        return (
          <PermissionCell label={permission} isGranular={record.isGranular} />
        );
      },
    },
    {
      label: t('Read')!,
      dataIndex: ['readScope'],
      width: 105,
      render: (
        readScope: { code: string; value: boolean },
        record: OpTableRecordType,
      ) =>
        readScope ? (
          <OpSwitch
            testId={`${record.groupCode}-read`}
            disabled={disabled}
            onChange={(value) =>
              toggleReadScope({
                value,
                readScope: record.readScope,
                writeScope: record.writeScope,
              })
            }
            checked={readScope?.value}
          />
        ) : (
          ' ' // ' ' to prevent --
        ),
    },
    {
      label: t('Write')!,
      dataIndex: ['writeScope'],
      width: 105,
      render: (
        writeScope: { code: string; value: boolean },
        record: OpTableRecordType,
      ) => {
        return writeScope ? (
          <OpSwitch
            testId={`${record.groupCode}-write`}
            disabled={disabled}
            onChange={(value) =>
              toggleWriteScope({
                value,
                readScope: record.readScope,
                writeScope: record.writeScope,
              })
            }
            checked={writeScope?.value}
          />
        ) : (
          ' ' // ' ' to prevent --
        );
      },
    },
    {
      label: t('Description')!,
      dataIndex: ['description'],
      width: 435,
      render: (description: string) => description || ' ', // ' ' to prevent --
    },
  ];

  return dataSource?.length ? (
    <OpTable
      uiStateKey="ScopeResourceSectionTable"
      label={
        scopeResource.description ? (
          <div>
            {scopeResource.name}
            <OpInfoTooltip title={scopeResource.description} />
          </div>
        ) : (
          scopeResource.name
        )
      }
      rowKey="groupCode"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      allowExport={false}
      allowGlobalSearch={false}
      allowShowHideColumns={false}
    />
  ) : null;
};
