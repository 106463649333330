import { ComponentProps, ReactNode } from 'react';
import clsx from 'clsx';
import { OpRadio } from 'new-components/DLS/OpRadio/OpRadio';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { useOpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormRadio.scss';

interface OpFormRadioProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'tooltip'
    >,
    Omit<ComponentProps<typeof OpRadio>, 'name' | 'tooltip'> {
  formItemClassName?: string;
  isLoading?: boolean;
  radioTooltip?: ReactNode;
}

export const OpFormRadio = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  children,
  radioTooltip,

  disabled,
  ...elementProps
}: OpFormRadioProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useOpFormContext();

  return (
    <OpForm.Item
      className={clsx('op-form-radio', formItemClassName)}
      name={name}
      tooltip={tooltip}
      label={label}
      valuePropName="checked"
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Avatar active size="small" shape="circle" />
      ) : (
        <OpRadio
          disabled={isReadOnly || disabled}
          tooltip={radioTooltip}
          {...elementProps}
        >
          {children}
        </OpRadio>
      )}
    </OpForm.Item>
  );
};
