import React, { lazy } from 'react';
import ConfigProvider from 'antd/es/config-provider';
import { OpApp } from 'new-components/DLS/OpApp/OpApp';
import { OpAppScaffold } from 'new-components/OpAppScaffold/OpAppScaffold';
import { getBasenameForApp } from 'utils/getBasenameForApp';
import { Route } from 'react-router-dom-v5-compat';
import { OpAuthenticatedRoute } from 'new-components/OpAppScaffold/OpAuthenticatedRoute';
import { OpScopeGuard } from 'new-components/OpScopeGuard/OpScopeGuard';
import { OpPageNotFound } from 'new-components/OpAppScaffold/OpPageNotFound';
import AlertWrapper from 'containers/AlertWrapper';
import RolesPage from 'pages/RolesPage/RolesPage';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { useThemeObserver } from 'utils/customHooks/useThemeObserver';
import { OrgIdOutlet } from '../app/new-components/OpAppScaffold/OrgIdOutlet';
import {
  dashboardMenuItem,
  mailroomListMenuItem,
  RolesMenuItem,
  sidebarItems,
} from './constants/sidebarItems';

import {
  baseRoute,
  mailroomDashboardRoute,
  mailroomListRoute,
  rolesRoute,
} from './constants/routes';

import './App.scss';

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Mailrooms = lazy(() => import('./pages/Mailrooms/Mailrooms'));
const Packages = lazy(() => import('./pages/Packages/Packages'));

/**
 * @component
 * @description Component that wraps the mailroom app and holds the top level functionality.
 */
export const MailroomAppScaffold = () => {
  // Gets the correct basename for the app based on the current environment
  const basename = getBasenameForApp('mailroom');

  const { identityId } = useIdentityAndUser();
  const { theme } = useThemeObserver({ identityId });

  return (
    <ConfigProvider theme={theme}>
      <OpApp className="op-mailroom-app">
        <AlertWrapper />
        <OpAppScaffold
          appScopePrefix="parcelMgmt"
          basename={basename}
          homePath={`${baseRoute}/${mailroomDashboardRoute}`}
          sidebarItems={sidebarItems()}
          noLayoutRoutes={<Route path="packages" element={<Packages />} />}
          title="Mailroom"
        >
          {/* Routes requiring an orgId should be placed inside this route */}
          <Route
            path={`${baseRoute}/`}
            element={<OrgIdOutlet homePage={mailroomDashboardRoute} />}
          >
            {/* All Routes that require login should be inside here */}
            <Route element={<OpAuthenticatedRoute />}>
              <Route
                path={mailroomDashboardRoute}
                element={
                  <OpScopeGuard
                    allowedScopes={dashboardMenuItem().scope ?? []}
                    element={<Dashboard />}
                    fallback={<OpPageNotFound />}
                  />
                }
              />
              <Route
                path={mailroomListRoute}
                element={
                  <OpScopeGuard
                    allowedScopes={mailroomListMenuItem().scope ?? []}
                    element={<Mailrooms />}
                    fallback={<OpPageNotFound />}
                  />
                }
              />
              <Route
                path={rolesRoute}
                element={
                  <OpScopeGuard
                    allowedScopes={RolesMenuItem().scope ?? []}
                    element={
                      <RolesPage
                        uiStateKey="RolesTable-mailroom"
                        defaultFilters={{ name: ['Mailroom'] }}
                      />
                    }
                    fallback={<OpPageNotFound />}
                  />
                }
              />
            </Route>
          </Route>
        </OpAppScaffold>
      </OpApp>
    </ConfigProvider>
  );
};
