import i18n from 'i18next';
// FUTURE READERS: also look in config.{env}.json for ENABLED_USER_ROLE_SCOPES, you have to whitelist each one individually. you're welcome.
export const scopeToLabelText = (scopeGroupCode: string) =>
  ({
    dash: i18n.t('Dashboards'),
    dashAlarm: i18n.t('Alarms dashboard'),
    dashActivity: i18n.t('Activity dashboard'),
    dashEntry: i18n.t('Entry dashboard'),
    user: i18n.t('Users'),
    role: i18n.t('Roles'),
    site: i18n.t('Sites'),
    ldp: i18n.t('Lockdown plans'),
    rpt: i18n.t('Reports'),
    hw: i18n.t('Devices'),
    admin: i18n.t('Administration'),
    's-o': i18n.t('Sub-organizations'),
    i: 'Identities',
    o: i18n.t('Organizations'),
    'o-support': i18n.t('Org info for customer support'),
    'o-basic': i18n.t('Basic org account info'),
    'o-acu': i18n.t('Basic device and site/zone/entry configuration'),
    'o-completeHookAction': i18n.t('Complete hook actions'),
    pc: i18n.t('Physical credentials'),
    credential: i18n.t('Credentials'),
    siteGeneral: i18n.t('All other site items'),
    entryState: i18n.t('Entry states'),
    rptAlarm: i18n.t('Alarm report'),
    rptDownload: i18n.t('Report download'),
    rptGeneral: i18n.t('All other report items'),
    entryAccessAudit: i18n.t('Entry access audit'),
    portalAudit: i18n.t('Portal audit report'),
    integrations: i18n.t('App marketplace'),
    outboundWebhooks: i18n.t('Rules'),
    account: i18n.t('Account'),
    mobileAppSettings: i18n.t('Mobile app settings'),
    musterReport: i18n.t('Muster report'),
    occupancyReport: i18n.t('Occupancy report'),
    alertSettings: i18n.t('Alerts'),
    quickStart: i18n.t('Quick start page'),
    configurations: i18n.t('Configurations'),
    alarmActions: i18n.t('Alarm actions'),
    alarmConfigurations: i18n.t('Alarm'),
    presence: i18n.t('Presence'),
    erpParentCenter: i18n.t('Partner billing site'),
    erpParentStore: i18n.t('Partner device store'),
    opvideoDevice: i18n.t('Openpath video device'),
    videoProviderLive: i18n.t('View live feed'),
    videoProviderPlayback: i18n.t('Playback video clips'),
    building: i18n.t('Buildings'),
    badgePrinting: i18n.t('Badge printing'),
    exportClips: i18n.t('Export video clips'),
    opvideoIntercomStartSession: i18n.t('Initialize intercom session'),
    opvideoIntercom: i18n.t('Openpath video intercom'),
    parcelMgmtParcels: i18n.t('Mailroom'),
  })[scopeGroupCode];
