import React from 'react';
import clsx from 'clsx';

import './OpClickable.scss';

type ClickableProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  inline?: boolean;
  className?: string;
  [key: string]: any;
};

export const OpClickable = ({
  onClick,
  inline,
  className = '',
  ...htmlButtonProps
}: ClickableProps) => {
  const classnames = clsx(
    'op-clickable',
    { 'op-clickable--inline': inline },
    { [className]: Boolean(className) },
  );
  return (
    <button
      type="button"
      className={classnames}
      onClick={onClick}
      {...htmlButtonProps}
    />
  );
};
