import { useEffect, useState } from 'react';
import { LoaderWithoutSuir } from 'components/Loader/LoaderWithoutSuir';

/**
 * The purpose of this component is to act as the OpAppScaffold Suspense fallback for when
 * a potential lazy loaded component isn't available yet. The reason for the loader only being shown
 * after 500ms is that psychologically pages feel slower when a loader is shown within a few hundred ms
 * vs showing nothing. Since lazy loading doesn't generally take long we should only show the loader if something
 * is relatively "slow" to load
 */
export const OpAppSuspenseLoader = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setShowLoader(true);
    }, 500);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, []);

  if (showLoader) return <LoaderWithoutSuir />;

  return null;
};
