import { useTranslation } from 'react-i18next';
import { OpForm } from 'new-components/DLS/OpForm/OpForm';
import clsx from 'clsx';
import Form from 'antd/es/form';
import { useHasFeatures } from 'utils/customHooks/useHasFeatures';
import { useShowSitePartitioningItems } from 'utils/customHooks/useShowSitePartitioningItems';

import { useHasReadWriteAccess } from 'utils/customHooks/useHasReadWriteAccess';
import { ScopeResourceSection } from '../../ScopeResourceSection/ScopeResourceSection';
import { FormProps } from '../formTypes';
import { useRoleFormMutation } from './hooks/useRoleFormMutation';
import { useRoleFormData } from './hooks/useRoleFormData';

interface RoleFormProps extends FormProps {
  orgId: number;
  roleId?: number;
}

export const RoleForm = ({
  className,
  orgId,
  roleId,
  form,
  ...opFormProps
}: RoleFormProps) => {
  const { t } = useTranslation();

  const { isShowingSitePartitioningItems } =
    useShowSitePartitioningItems(orgId);

  const { hasFeatures: hasGranularPermissions } = useHasFeatures(orgId, [
    'granularPermissions',
  ]);

  const isSiteSpecific = Form.useWatch('isSiteSpecific', form);

  // Gets initial data for the form
  const { initialValues, hasError, isLoading, isEditable } = useRoleFormData({
    orgId,
    roleId,
  });

  // Submit handler for create/update
  const { handleSubmit } = useRoleFormMutation({
    orgId,
    roleId,
    isEditable,
  });

  const { hasWriteAccess } = useHasReadWriteAccess();

  return (
    <OpForm
      className={clsx('role-form', className)}
      hasError={hasError}
      form={form}
      isReadOnly={!hasWriteAccess}
      isLoading={isLoading}
      initialValues={initialValues}
      testId="role-form"
      onSubmit={handleSubmit}
      {...opFormProps}
    >
      <OpForm.Input
        name="name"
        label={t('Name')}
        rules={[{ required: true, message: t('Name is required') }]}
        disabled={!isEditable}
      />
      <OpForm.Input
        name={'description'}
        label={t('Description')}
        disabled={!isEditable}
      />
      <OpForm.Switch
        name={'isMfaRequired'}
        label={t('Enforce MFA')}
        tooltip={t(
          'If enabled, users assigned to this role will be required to set up Multi-factor Authentication (MFA) to log in',
        )}
        disabled={!isEditable}
      />
      <OpForm.Switch
        name={'isSiteSpecific'}
        label={t('Limit to specific sites')}
        disabled={!hasGranularPermissions || !isEditable}
        tooltip={
          hasGranularPermissions
            ? t(
                'By turning on the "Limit to specific sites" toggle, you may limit the sites that this role can modify. Permissions that can be restricted by site are limited. Refer to the details in the tool tips below. Devices, Integrations, Configurations and Administration are not site-specific.',
              )
            : t('This feature requires a package upgrade.')
        }
      />
      {isSiteSpecific && hasGranularPermissions && (
        <OpForm.SiteSpecificSiteDataFetchSelect
          orgId={orgId}
          allowClear
          disabled={!isEditable}
          name="sites"
          label={t('Sites')}
          mode="multiple"
          placeholder={t('Click to add sites')}
          gtm={'role-form-sites-select'}
          testId="role-site-select"
          tagTestId="role-site-tag"
          rules={[
            {
              required: true,
              message: t(
                'At least one site must be added in order for site-specific roles to apply',
              ),
            },
          ]}
        />
      )}

      <OpForm.SiteSpecificUserDataFetchTransfer
        testId="user-role-selection"
        orgId={orgId}
        name="users"
        label={t('Users')}
        existingItemsLabel={t('users in role')}
        existingItemsQueryOptions={{
          apiEndpointName: 'listRoleUsers',
          parameters: isShowingSitePartitioningItems
            ? [orgId, roleId!, { options: 'withUserSites' }]
            : [orgId, roleId!],
        }}
      />

      <ScopeResourceSection
        orgId={orgId}
        form={form}
        disabled={!isEditable || !hasWriteAccess}
      />
    </OpForm>
  );
};
