import React, { ReactElement } from 'react';

import './AppSwitcherButton.scss';

export const AppSwitcherButton = ({
  label,
  icon,
  onClick,
}: {
  label: string;
  icon: ReactElement;
  onClick(): void;
}) => {
  return (
    <div
      className="app-switcher-button"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      <div className="app-switcher-button__icon-container">{icon}</div>
      <p className="app-switcher-button__label">{label}</p>
    </div>
  );
};
