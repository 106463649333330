import opConfig from 'openpathConfig';
import { getAccessToken } from './accessToken';

/** Used for local development against a remote deployment url. Running the `proxy-server` will allow api calls to make it through successfully */
const { AWARE_PROXY_URL } = opConfig;

interface SetUnifiedCookie {
  baseUrl: string;
  options?: {
    org_id?: number;
    region?: string;
  };
}

/**
 * @name getAwareOrigin
 *
 * The primary concern here is getting the full origin for the deployment.
 * In the instance that the deployment is Intrusion and the deployment URL is
 * "deployment-url.com/intrusion?maybe=not-realistic" the system needs to be
 * able to reliably pluck the origin out.
 *
 * - Removes any url params if exists
 * - Removes ending "/" if exists
 */
const getAwareOrigin = (baseUrl: string): string => {
  const parsed = new URL(baseUrl);

  // Remove trailing slash & any query params
  return parsed.origin;
};

/**
 * @description This utility helps in authenticating between Openpath and Ava. Since we do not share
 * a common domain each side had to create a custom endpoint to send a cookie to so that we can
 * be authenticated. An example being when we log into Openpath and "app switch" to Ava, we don't want to have
 * to go through the login flow on their site as well.
 */
export const setUnifiedCookie = ({ baseUrl, options }: SetUnifiedCookie) => {
  const normalizedBaseUrl = getAwareOrigin(AWARE_PROXY_URL || baseUrl);
  const jwt = getAccessToken();

  return fetch(`${normalizedBaseUrl}/api/v1/setOpenpathCookie`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      jwt,
      ...options,
    }),
  });
};
