export const baseRoute = '/o/:orgId';

export const partnerDashboardRoute = 'dashboard';

export const partnerCustomersRoute = 'customers';

export const partnerAccessRoute = 'access';
export const partnerSubscriptionRenewalsTableRoute = 'access/renewals';
export const partnerRequestsTableRoute = 'access/requests';
export const partnerBillingRoute = 'access/billing';
export const partnerStoreRoute = 'access/store';

export const partnerIntrusionRoute = 'intrusion';
export const partnerIntrusionDeploymentsRoute = 'intrusion/deployments';

export const partnerMailroomRoute = 'mailroom';

export const partnerVideoRoute = 'video';
export const partnerVideoDeploymentsRoute = 'video/deployments';
