import { useEffect, useState } from 'react';
import opConfig from 'openpathConfig';
import { useOpQuery } from './useOpQuery';

export const useFeatureFlag = <T>(
  flag: keyof typeof opConfig,
  orgId?: number,
) => {
  const [flagValue, setFlagValue] = useState<T | undefined | null>();

  const {
    data: heliumOrgFlags,
    isLoading: isLoadingHeliumOrgFlags,
    isPending: isPendingHeliumOrgFlags,
    isFetching: isFetchingHeliumOrgFlags,
  } = useOpQuery({
    apiEndpointName: 'listOrgFeatureFlags',
    parameters: [orgId!],
    select: (data) => data.json.data,
  });

  // Check for the flag value based on helium and environment config
  useEffect(() => {
    // Only run after the helium calls finish loading
    if (isLoadingHeliumOrgFlags) {
      return;
    }

    // Check if the flag is set in helium - will get org setting, if set and global default if not
    const heliumOrgFlag = heliumOrgFlags?.find(
      (hf) => hf.featureFlag?.name === flag,
    );

    // If the helium flag exists and the value is set, use. If not set or does not exist, move on.
    if (
      heliumOrgFlag &&
      heliumOrgFlag.value !== undefined &&
      heliumOrgFlag.value !== null
    ) {
      setFlagValue(heliumOrgFlag.value as T);
      return;
    }

    // Handle if the flag does not exist or the value was never set in helium
    const opConfigFlag = opConfig[flag];

    setFlagValue(opConfigFlag as T);
  }, [flag, heliumOrgFlags, isLoadingHeliumOrgFlags]);

  return {
    value: flagValue,
    isLoading: isLoadingHeliumOrgFlags,
    isFetching: isFetchingHeliumOrgFlags,
    isPending: isPendingHeliumOrgFlags,
  };
};
