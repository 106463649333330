import { ForwardedRef, forwardRef } from 'react';
import { MenuProps } from 'antd/es/menu';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { OpDropdown } from 'new-components/DLS/OpDropdown/OpDropdown';
import { useAwareDeploymentUrl } from 'utils/customHooks/useAwareDeploymentUrl';
import { AppSwitcherSvg } from 'components/svgs/AppSwitcherSvg';
import { useUnifiedLogin } from 'utils/customHooks/useUnifiedLogin';
import { getOpParams } from 'utils/getOpParams';
import opConfig from 'openpathConfig';
import { getDmpUrl } from 'utils/getDmpUrl';
import { useIsAppSwitcherVisible } from 'utils/customHooks/useIsAppSwitcherVisible';
import { AppSwitcherMenu } from './AppSwitcherMenu';
import { useNavigateToAccess } from './hooks/useNavigateToAccess';
import { useNavigateToMailroom } from './hooks/useNavigateToMailroom';

import './AppSwitcher.scss';
/**
 * @component
 * @description Component used to app switch between Aware and Openpath. Utilizes the useUnifiedLogin
 * hook to handle the business logic necessary to properly authenticate and switch apps
 * @returns JSX.Element | null
 */
export const AppSwitcher = forwardRef(
  (_: unknown, ref: ForwardedRef<HTMLDivElement>) => {
    const { orgId } = getOpParams();
    const {
      isAppSwitcherVisible,
      isAwareEnabled,
      isDmpEnabled,
      isLoadingIsDmpEnabled,
      isMailroomEnabled,
      isLoadingIsMailroomEnabled,
    } = useIsAppSwitcherVisible();

    const awareDeploymentUrl = useAwareDeploymentUrl();
    const dmpUrl = getDmpUrl();

    // Aware login
    const {
      handleUnifiedLogin: handleAwareLogin,
      isLoggingIn: isAwareLoggingIn,
    } = useUnifiedLogin({
      url: awareDeploymentUrl,
      options: {
        org_id: orgId,
        region: opConfig.ENV,
      },
    });

    // dmp login
    const { handleUnifiedLogin: handleDmpLogin, isLoggingIn: isDmpLoggingIn } =
      useUnifiedLogin({
        url: dmpUrl,
        options: { org_id: orgId, region: opConfig.ENV },
      });

    // Navigate to access
    const { handleNavigateToAccess } = useNavigateToAccess();

    // Navigate to mailroom
    const { handleNavigateToMailroom } = useNavigateToMailroom({ orgId });

    if (!isAppSwitcherVisible) {
      return null;
    }

    const items: MenuProps['items'] = [
      {
        key: '1',
        className: 'app-switcher-overlay__menu-item',
        icon: (
          <AppSwitcherMenu
            {...(isAwareEnabled && { handleAwareLogin })}
            {...(isDmpEnabled && { handleDmpLogin })}
            {...(isMailroomEnabled && { handleNavigateToMailroom })}
            handleNavigateToAccess={handleNavigateToAccess}
          />
        ),
      },
    ];

    const isLoading =
      isAwareLoggingIn ||
      isDmpLoggingIn ||
      isLoadingIsDmpEnabled ||
      isLoadingIsMailroomEnabled;

    return (
      <div data-testid="app-switcher" ref={ref}>
        <OpDropdown
          trigger={['click']}
          menu={{ items }}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
          overlayClassName="app-switcher-overlay"
        >
          <div className="app-switcher__icon">
            {isLoading ? <LoadingOutlined /> : <AppSwitcherSvg />}
          </div>
        </OpDropdown>
      </div>
    );
  },
);
