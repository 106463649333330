import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getActiveScopes } from 'utils/scopes';
import { useNavigateToOrg } from './useNavigateToOrg';

const debounceTimeout = 300;

export const useOrgSwitcher = ({
  homePath,
  token,
  identityId,
  currentOrgId,
  setCurrentOrgId,
}: {
  homePath?: string;
  token: Api.Response['describeAccessToken'];
  identityId?: number;
  currentOrgId: string | null;
  setCurrentOrgId(orgId: string | null): void;
}) => {
  const [waitingForNavigate, setWaitingForNavigate] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  // Check user's identity and partner permissions to call getOrgs
  const activeScopes = getActiveScopes({
    tokenScopeList: token?.tokenScopeList ?? [],
  });

  const hasPermissionToCallOrgsEndpoint = activeScopes.some((scope) =>
    /^(o:|o-basic:|s-o:)/.test(scope),
  );

  // Get the orgs using the current search value
  const { data: orgData, isPending: orgDataIsPending } = useOpQuery({
    apiEndpointName: 'listOrgs',
    parameters: [
      {
        limit: 50,
        filter: 'status:(A,F)',
        options: 'withPackages',
        ...(searchValue && { q: searchValue }),
      },
    ],
    select: (data) => data.json.data ?? [],
    enabled: hasPermissionToCallOrgsEndpoint,
  });

  // Handles org navigation when the selected org is changed
  const { isOrgSwitching } = useNavigateToOrg({
    orgId: currentOrgId ? Number(currentOrgId) : undefined,
    identityId,
    homePath,
    token,
    setWaitingForNavigate,
  });

  // Handlers for the org select

  // This will need updated as more apps are added
  const handleSelectChange = (value: string | string[]) => {
    if (Array.isArray(value)) return; // It wont be an array
    setWaitingForNavigate(true);
    // Trigger the org change code - requires getting information from the database so not
    // doing in this handler
    setCurrentOrgId(value);
  };

  // Handle Search
  const handleSearchChange = useCallback((value: string | string[]) => {
    if (!Array.isArray(value)) {
      // shouldnt ever be an array here
      setSearchValue(value);
    }
  }, []);

  const debouncedSetSearchValue = useMemo(
    () => debounce(handleSearchChange, debounceTimeout),
    [handleSearchChange],
  );

  return {
    currentOrgId,
    orgData,
    isPending: hasPermissionToCallOrgsEndpoint && orgDataIsPending,
    setSearchValue: debouncedSetSearchValue,
    isOrgSwitching: waitingForNavigate || isOrgSwitching,
    handleSelectChange,
  };
};
