import UserOutlined from '@ant-design/icons/UserOutlined';
import { OpImage } from 'new-components/DLS/OpImage/OpImage';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getOpParams } from 'utils/getOpParams';

import './OpUserAvatar.scss';

interface OpUseAvatarProps {
  className?: string;
  userId?: number;
  initials?: string;
  size?: number;
  preview?: boolean;
  isAvatarAllowed?: boolean;
}
export const OpUserAvatar = ({
  className = '',
  userId,
  initials,
  size = 36,
  preview,
  isAvatarAllowed = true,
}: OpUseAvatarProps) => {
  const { orgId } = getOpParams();
  const { isFetching, data: userAvatarUrl } = useOpQuery({
    apiEndpointName: 'listUserPictures',
    parameters: [Number(orgId), userId!],
    select: (heliumResponse) => {
      const userPictures = heliumResponse?.json?.data;
      return userPictures?.find(({ isAvatar }) => isAvatar)?.picture?.url;
    },
    suppressErrorMessage: true,
    retry: false,
  });

  let placeHolder;

  // Show loader if the user pictures are fetching
  if (userId && isFetching) {
    // putting initials here for when refetch happens on losing and gaining focus (dont want to swap back to icon)
    placeHolder = initials || <UserOutlined />;
  } else if (initials) {
    // Set the placeholder to the initials if we have them
    placeHolder = initials;
  } else {
    // Set the placeholder to the user icon if we don't have initials
    placeHolder = <UserOutlined />;
    // placeHolder = <Icon component={UserIcon} style={{ fontSize: size / 2 }} />;
  }

  if (isAvatarAllowed && userAvatarUrl) {
    return (
      <OpImage
        className={`op-user-avatar ${className}`.trim()}
        // Don't show the preview option if there is no user avatar url
        {...(userAvatarUrl && { preview })}
        style={{
          width: size,
          height: size,
        }}
        src={userAvatarUrl}
        placeholder={
          <div
            className="op-user-avatar__placeholder"
            style={{ fontSize: size / 2 }}
          >
            {placeHolder}
          </div>
        }
      />
    );
  }

  return (
    <div
      className={`op-user-avatar ${className}`.trim()}
      style={{
        width: size,
        height: size,
      }}
    >
      <div
        className="op-user-avatar__placeholder"
        style={{ fontSize: size / 2 }}
      >
        {placeHolder}
      </div>
    </div>
  );
};
