import clsx from 'clsx';
import { ComponentProps } from 'react';
import { OpHeader } from 'new-components/OpHeader.tsx/OpHeader';
import { UserMenu } from 'new-components/UserMenu/UserMenu';
import { OrgSwitcher } from 'new-components/OrgSwitcher/OrgSwitcher';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { HamburgerMenuSvg } from 'components/svgs/HamburgerMenuSvg';
import { AppSwitcher } from 'new-components/AppSwitcher/AppSwitcher';
import { HelpMenu } from 'new-components/HelpMenu/HelpMenu';
import { LockdownSelector } from 'new-components/LockdownSelector/LockdownSelector';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getOpParams } from 'utils/getOpParams';
import { NativeAppSwitcher } from 'new-components/NativeAppSwitcher/NativeAppSwitcher';

import './OpAppHeader.scss';

interface OpAppHeaderProps extends ComponentProps<typeof OpHeader> {
  homePath: string;
  onHamburgerClick?: () => void;
  showOrgSwitcher?: boolean;
  showHamburger?: boolean;
}

export const OpAppHeader = ({
  className,
  homePath,
  onHamburgerClick,
  showHamburger,
  showOrgSwitcher = true,
  ...headerProps
}: OpAppHeaderProps) => {
  const { orgId } = getOpParams();

  const { data: isUnified } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (data) => data.json.data.isUnified,
  });

  return (
    <OpHeader className={clsx('op-app-header', className)} {...headerProps}>
      <div className="op-app-header__content">
        <div className="op-app-header__left-content">
          {showHamburger && (
            <OpButton
              className="op-app-header__hamburger"
              onClick={onHamburgerClick}
              icon={<HamburgerMenuSvg />}
              gtm="op-app-header-hamburger"
              data-testid="op-app-header-hamburger"
            />
          )}
          {showOrgSwitcher && <OrgSwitcher homePath={homePath} />}
        </div>
        <div className="op-app-header__right-content">
          <LockdownSelector />
          <HelpMenu />
          {isUnified ? <NativeAppSwitcher /> : <AppSwitcher />}
          <UserMenu />
        </div>
      </div>
    </OpHeader>
  );
};
