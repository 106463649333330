import { getAltaDomain, validDevelopmentUrlRegEx } from 'utils/domains';
import { getWindowLocation } from 'utils/window';

/**
 * @returns the mailroom origin url for the current env
 */
export const getMailroomOrigin = () => {
  const { origin } = getWindowLocation();

  // local development
  if (validDevelopmentUrlRegEx.test(origin)) {
    return `${origin}/mailroom`;
  }

  // for deployed envs
  const domain = getAltaDomain();

  return domain ? `https://mailroom.${domain}` : '';
};
