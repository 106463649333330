import { ReactElement, useRef, useState } from 'react';
import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { useTranslation } from 'react-i18next';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getOpParams } from 'utils/getOpParams';
import { useDebounce } from 'utils/customHooks';
import { getAccessToken } from 'utils/accessToken';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import clsx from 'clsx';
import { keepPreviousData } from '@tanstack/react-query';
import { opTime } from 'utils/dates';
import { checkUserRights } from './helpers/lockdownUserRights';
import { LockdownCard } from './components/LockdownCard';

import './LockdownSelector.scss';

export const LockdownSelector = () => {
  const { orgId } = getOpParams();
  const { t } = useTranslation();

  /**
   * In order to only show the selector when a user is allowed to trigger
   * or revert at least one lockdown plan, we need to base the flag on the
   * first call with no filter. If we modify based on when there is a filter,
   * then we will end up hiding the selector when we shouldn't be. Using a ref
   * allows us to persist the value between renders.
   */
  const doesUserHaveRights = useRef(false);

  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { data: lockdownPlans, isFetching: isFetchingListLockdownPlans } =
    useOpQuery({
      apiEndpointName: 'listLockdownPlans',
      parameters: [
        orgId,
        debouncedSearchTerm ? { filter: `name:(${debouncedSearchTerm})` } : {},
      ],
      select: (heliumResponse) => heliumResponse.json.data,
      placeholderData: keepPreviousData,
    });

  const accessToken = getAccessToken();

  const { data: tokenData } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    select: (heliumResponse) => heliumResponse.json.data,
  });

  const currentUserId = tokenData?.tokenScopeList?.find((scope) => {
    if (!scope?.org) return false;
    return scope.org.id === orgId;
  })?.user?.id;

  const { data: cloudKeys } = useOpQuery({
    apiEndpointName: 'listCredentials',
    parameters: [
      orgId,
      currentUserId!,
      { filter: 'credentialType.modelName:(cloudKey)' },
    ],
    select: (heliumResponse) => heliumResponse?.json?.data,
  });

  const validCloudKeyId = cloudKeys?.find((cloudKey) => {
    const validStart = cloudKey.startDate
      ? opTime(cloudKey.startDate).isBefore(opTime())
      : true;
    const validEnd = cloudKey.endDate
      ? opTime(cloudKey.endDate).isAfter(opTime())
      : true;
    return validStart && validEnd;
  })?.id;

  const { data: currentUserGroups } = useOpQuery({
    apiEndpointName: 'listUserGroups',
    parameters: [orgId, currentUserId!],
    select: (data) => data?.json?.data,
  });

  const [lockdownCards, hasActiveLockdownPlan] = (lockdownPlans || []).reduce<
    [ReactElement[], boolean, boolean]
  >(
    (
      acc,
      {
        id,
        name,
        isActive,
        triggerUsers,
        triggerGroups,
        revertUsers,
        revertGroups,
      },
    ) => {
      if (!(currentUserId && validCloudKeyId && currentUserGroups)) {
        return acc;
      }

      const hasTriggerRights = checkUserRights({
        userId: currentUserId,
        userGroups: currentUserGroups,
        lockdownUsers: triggerUsers,
        lockdownUserGroups: triggerGroups,
      });

      const hasRevertRights = checkUserRights({
        userId: currentUserId,
        userGroups: currentUserGroups,
        lockdownUsers: revertUsers,
        lockdownUserGroups: revertGroups,
      });

      const userHasRights = hasTriggerRights || hasRevertRights;

      if (!userHasRights) {
        return acc;
      }

      doesUserHaveRights.current = true; // User has rights to trigger or revert a lockdown plan

      if (isActive) {
        // eslint-disable-next-line no-param-reassign
        acc[1] = true; // Org has an active lockdown plan
      }

      acc[0].push(
        <LockdownCard
          orgId={orgId}
          currentUserId={currentUserId}
          cloudKeyCredentialId={validCloudKeyId}
          key={id}
          name={name || t('Unknown name')}
          lockdownPlanId={id}
          isActive={Boolean(isActive)}
          hasTriggerRights={hasTriggerRights}
          hasRevertRights={hasRevertRights}
        />,
      );

      return acc;
    },
    [[], false, false],
  );

  const dropdownWithArrow:
    | ((menu: ReactElement) => ReactElement)
    | undefined = (menu) => (
    <>
      <div className="lockdown-selector__dropdown-arrow" />
      {lockdownCards?.length ? (
        <OpSpace
          direction="vertical"
          className="lockdown-selector__cards-container"
        >
          {lockdownCards}
        </OpSpace>
      ) : (
        menu
      )}
    </>
  );

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  return currentUserId && validCloudKeyId && doesUserHaveRights.current ? (
    <OpSelect
      testId={'lockdown-selector'}
      loading={isFetchingListLockdownPlans}
      className={clsx('lockdown-selector', {
        'lockdown-selector--active': hasActiveLockdownPlan,
      })}
      open={open}
      onDropdownVisibleChange={() => {
        setOpen((prev) => !prev);
      }}
      allowClear={Boolean(searchTerm)}
      showSearch
      dropdownAlign={{
        points: ['tl', 'bl'], // Align top-left of dropdown to bottom-left of input
        offset: [0, 12], // Offset horizontal and vertical alignment
      }}
      dropdownRender={dropdownWithArrow}
      popupClassName="lockdown-selector-popup"
      onSearch={handleSearch}
      value={t('Lockdown')}
    />
  ) : null;
};
