export const checkUserRights = ({
  userId,
  userGroups = [],
  lockdownUsers = [],
  lockdownUserGroups = [],
}: {
  userId: number;
  userGroups: Api.Response['listUserGroups'];
  lockdownUsers:
    | Api.Response['listLockdownPlans'][0]['triggerUsers']
    | Api.Response['listLockdownPlans'][0]['revertUsers'];
  lockdownUserGroups:
    | Api.Response['listLockdownPlans'][0]['triggerGroups']
    | Api.Response['listLockdownPlans'][0]['revertGroups'];
}) => {
  const hasGroupRights = userGroups.some(({ id: userGroupId }) =>
    lockdownUserGroups.find(({ id: groupId }) => groupId === userGroupId),
  );
  const hasUserRights = lockdownUsers.some(({ id }) => id === userId);

  return hasUserRights || hasGroupRights;
};

export const checkHasRightsToAnyLockdownPlan = ({
  lockdownPlans = [],
  userId,
  userGroups = [],
}: {
  lockdownPlans: Api.Response['listLockdownPlans'];
  userId: number;
  userGroups: Api.Response['listUserGroups'];
}) => {
  return lockdownPlans.find(
    ({ triggerUsers, triggerGroups, revertUsers, revertGroups }) => {
      const triggerRights = checkUserRights({
        userId,
        userGroups,
        lockdownUsers: triggerUsers,
        lockdownUserGroups: triggerGroups,
      });

      const revertRights = checkUserRights({
        userId,
        userGroups,
        lockdownUsers: revertUsers,
        lockdownUserGroups: revertGroups,
      });

      return triggerRights || revertRights;
    },
  );
};
