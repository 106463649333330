import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from 'react-use';
import noop from 'lodash/noop';
import { OpForm } from 'new-components/DLS/OpForm/OpForm';
import { OpSection } from 'new-components/DLS/OpSection/OpSection';
import { OpModal } from 'new-components/DLS/OpModal/OpModal';
import { OpSpace } from 'new-components/DLS/OpSpace/OpSpace';
import {
  setCurrentUserPreferences,
  setIntercomNotificationsAreEnabled,
  setAlert,
} from 'routes/AppContainer/actions';
import FeatureGate from 'containers/FeatureGate';
import { getOpParams } from 'utils/getOpParams';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { useUpdateUserPreferenceSet } from 'utils/customHooks/api/useUpdateUserPreferenceSet';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { OpImage } from 'new-components/DLS/OpImage/OpImage';
import { AppColorThemePicker } from 'new-components/AppColorThemePicker/AppColorThemePicker';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { useSuirRemovedUi } from 'routes/AppContainer/SuirRemovedUiContext';

import './ProfileSettingsTabContent.scss';

export const colorThemes = [
  {
    label: (
      <>
        <OpImage
          src={require('assets/img/theme-lightmode.png')}
          alt="Light theme"
        />
        <OpText className="profile-settings-tab-content-org-settings__color-theme-text">
          Light mode
        </OpText>
      </>
    ),
    value: 'light',
  },
  {
    label: (
      <>
        <OpImage
          src={require('assets/img/theme-darkmode.png')}
          alt="Dark theme"
        />
        <OpText className="profile-settings-tab-content-org-settings__color-theme-text">
          Dark mode
        </OpText>
      </>
    ),
    value: 'dark',
  },
  {
    label: (
      <>
        <OpImage
          src={require('assets/img/theme-system.png')}
          alt="System theme"
        />
        <OpText className="profile-settings-tab-content-org-settings__color-theme-text">
          Match system
        </OpText>
      </>
    ),
    value: 'system',
  },
];

const OpUserPreference = ({
  preferenceMeta: { columnName, label, tooltip, feature },
  type,
  initialUserSettingsValues,
}: {
  preferenceMeta: Api.Response['describePreferenceMeta'];
  type: string;
  initialUserSettingsValues: Api.Response['describeUserPreferenceSet'];
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = getOpParams();
  const { userId } = useIdentityAndUser();

  const updateUserPreferenceSetMutation = useUpdateUserPreferenceSet({
    onSuccessCallback: (
      // updateUserPreferenceSet response
      _: any,
      // Passed to .mutate()
      {
        payload,
      }: {
        payload: Api.Payload['updateUserPreferenceSet'];
      },
    ) => {
      dispatch(
        setCurrentUserPreferences({
          ...initialUserSettingsValues,
          ...payload,
        }),
      );
    },
  });

  // TODO: This is flickering
  const renderPreferenceControl = useCallback(
    ({ disabled = false } = {}) => {
      switch (type) {
        case 'switch':
          return (
            <OpForm.Switch
              name={columnName!}
              label={t(label!)}
              tooltip={tooltip ? t(tooltip) : undefined}
              disabled={disabled}
              testId={`${columnName}-switch`}
              onChange={(value) => {
                updateUserPreferenceSetMutation.mutate({
                  apiEndpointRequirements: [orgId, userId!],
                  payload: {
                    playAlarmNotificationSound: value,
                  },
                });
              }}
            />
          );
        default:
          console.error(`Unknown preference type: ${type}`);
          return null;
      }
    },
    [
      columnName,
      label,
      tooltip,
      type,
      t,
      orgId,
      userId,
      updateUserPreferenceSetMutation,
    ],
  );

  if (feature?.code) {
    return (
      <FeatureGate featureCode={feature.code}>
        {renderPreferenceControl}
      </FeatureGate>
    );
  }

  return renderPreferenceControl();
};

export default () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Using the useLocalStorage hook in order to force a
  // rerender when the value in local storage changes
  // NOTE: Did not see any issues going between mailroom and control center tabs - they don't
  // share local storage, but seems to behave fine.
  const [intercomNotificationsPermission, setIntercomNotificationPermission] =
    useLocalStorage('intercomNotificationsPermission', 'granted');

  const initialBrowserSettingsValues = useMemo(
    () => ({
      intercomNotificationsAreEnabled:
        intercomNotificationsPermission === 'granted',
    }),
    [intercomNotificationsPermission],
  );

  const { orgId } = getOpParams();
  const { userId, identityId } = useIdentityAndUser();

  const { data: preferenceMetas = [] } = useOpQuery({
    apiEndpointName: 'listPreferenceMetas',
    parameters: [
      orgId,
      userId!,
      {
        filter: 'columnName:(=playAlarmNotificationSound)',
      },
    ],
    select: (heliumResponse) => heliumResponse?.json?.data || [],
  });
  const playAlarmNotificationSoundMeta = preferenceMetas[0];

  const {
    data: initialUserSettingsValues = {
      playAlarmNotificationSound: true,
    },
    isFetching,
  } = useOpQuery({
    apiEndpointName: 'describeUserPreferenceSet',
    parameters: [orgId, userId!],
    select: (heliumResponse) => heliumResponse?.json?.data,
  });

  const { data: orgName } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    enabled: Boolean(orgId && userId), // We only need this if we have a user associated with this org!
    select: (heliumResponse) => heliumResponse?.json?.data?.name,
  });

  const {
    hasUserSelectedSuirRemovedUi,
    setHasUserSelectedSuirRemovedUi,
    hasSuirRemovedUiFeature,
  } = useSuirRemovedUi();

  return (
    <div
      className="profile-settings-tab-content-org-settings"
      data-testid="profile-settings-tab-content"
    >
      <OpSection
        label={t('Browser settings')}
        tooltip={t(
          'These settings apply to all Avigilon Alta tabs in this browser.',
        )}
      >
        <OpForm
          hasError={false}
          initialValues={initialBrowserSettingsValues}
          testId="profile-settings-tab-content-browser-settings-form"
          defaultButtons={false}
          onSubmit={noop}
        >
          <OpForm.Switch
            name="intercomNotificationsAreEnabled"
            label={t('Enable intercom notifications')}
            tooltip={t(
              'This will enable/disable the use of intercom notifications in the control panel.',
            )}
            testId="intercomNotificationsAreEnabled-switch"
            onChange={(intercomNotificationsAreEnabled) => {
              if (
                Notification.permission === 'default' &&
                intercomNotificationsPermission !== 'granted' &&
                intercomNotificationsAreEnabled
              ) {
                // We save in the modal's onOk handler in this case
                setModalIsOpen(true);
              } else {
                // Sets in global state (used to allow permission prompt immediately)
                dispatch(
                  setIntercomNotificationsAreEnabled(
                    intercomNotificationsAreEnabled,
                  ),
                );

                // Sets in local storage (used to show actual alert in Platinum)
                setIntercomNotificationPermission(
                  intercomNotificationsAreEnabled ? 'granted' : 'denied',
                );

                dispatch(
                  setAlert(
                    'success',
                    t('Successfully updated browser settings'),
                  ),
                );
              }
            }}
          />
        </OpForm>

        <OpModal
          title={t('Reminder!')}
          open={modalIsOpen}
          closable={false}
          okText={t('Got it!')}
          onOk={() => {
            // Sets in global state (used to allow permission prompt immediately)
            dispatch(setIntercomNotificationsAreEnabled(true));

            // Sets in local storage (used to show actual alert in Platinum)
            setIntercomNotificationPermission('granted');
            dispatch(
              setAlert('success', t('Successfully updated browser settings')),
            );

            setModalIsOpen(false);
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          {t(
            'Make sure you allow browser notifications or else intercom notifications will not work and you will have to adjust the permissions manually in the browser later.',
          )}
        </OpModal>
      </OpSection>
      {userId && orgName && playAlarmNotificationSoundMeta ? (
        <OpSection
          label={t('User settings')}
          tooltip={t('These settings apply to your user in {{orgName}}.', {
            orgName,
          })}
        >
          <OpForm
            isLoading={isFetching}
            initialValues={initialUserSettingsValues}
            onSubmit={noop}
            defaultButtons={false}
            testId="profile-settings-tab-content-user-settings-form"
            hasError={false}
          >
            <OpSpace
              direction="vertical"
              size="large"
              className="profile-settings-tab-content-org-settings"
            >
              <OpUserPreference
                preferenceMeta={playAlarmNotificationSoundMeta}
                initialUserSettingsValues={initialUserSettingsValues}
                type="switch"
              />
            </OpSpace>
          </OpForm>
        </OpSection>
      ) : null}

      {hasSuirRemovedUiFeature && (
        <OpSection label={t('UI settings')}>
          <OpForm
            isLoading={isFetching}
            initialValues={initialUserSettingsValues}
            onSubmit={noop}
            defaultButtons={false}
            testId="profile-settings-tab-content-ui-settings-form"
            hasError={false}
          >
            <OpForm.Switch
              label={t('Use SUIR removed UI')}
              checked={hasUserSelectedSuirRemovedUi}
              onChange={(value) => setHasUserSelectedSuirRemovedUi(value)}
            />
          </OpForm>
        </OpSection>
      )}

      <OpSection label={t('Theme')}>
        <AppColorThemePicker identityId={identityId} />
      </OpSection>
    </div>
  );
};
