import { OpFormDrawer } from 'new-components/DLS/OpFormDrawer/OpFormDrawer';
import { RoleForm } from 'new-components/forms/RoleForm/RoleForm';
import { useUrlSearchParams } from 'utils/customHooks/useUrlSearchParams';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { OpPage } from 'new-components/OpPage/OpPage';
import { useTranslation } from 'react-i18next';
import { getOpParams } from 'utils/getOpParams';
import { RolesTable } from 'pages/RolesPage/RolesTable';
import { tableStateKeys } from 'new-components/DLS/OpTableCore/constants/tableStateKeys';

const RolesPage = ({
  uiStateKey = 'RolesTable',
  defaultFilters,
}: {
  uiStateKey?: Utils.ValueOf<typeof tableStateKeys>;
  defaultFilters?: { name: string[] };
}) => {
  const { orgId } = getOpParams();
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();
  const { drawer, roleId } = useUrlSearchParams();

  const pageTooltip = (
    <>
      <p style={{ marginBottom: 11 }}>
        {t(
          'A role is a set of portal access permissions that can be assigned to a user. Note that a role just defines portal access, but does not define entry (door) access. A user can have one or more roles.',
        )}
      </p>
      <p style={{ marginBottom: 11 }}>
        {t(
          'The Super Admin role has full permissions to read and edit all sections of the portal, while the Super Admin Read-Only role has full permissions only to read all sections of the portal.',
        )}
      </p>
    </>
  );

  return (
    <OpPage className="roles-page" title={t('Roles')} tooltip={pageTooltip}>
      <RolesTable
        orgId={orgId}
        uiStateKey={uiStateKey}
        defaultFilters={defaultFilters}
      />
      <OpFormDrawer
        title={
          !drawer
            ? ''
            : t('{{action}} role', {
                action: drawer === 'edit' ? 'Edit' : 'Create',
              })
        }
        width={1000}
        onClose={() => {
          setSearchParams({});
        }}
        open={Boolean(drawer)}
        formComponent={
          <RoleForm
            orgId={orgId}
            roleId={roleId ? Number(roleId) : undefined}
          />
        }
      />
    </OpPage>
  );
};

export default RolesPage;
