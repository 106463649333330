import i18n from 'i18next';
import { MailroomSvg } from 'components/svgs/MailroomSvg/MailroomSvg';
import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { HomeOutlinedSvg } from 'components/svgs/HomeOutlinedSvg/HomeOutlinedSvg';
import { HomeFilledSvg } from 'components/svgs/HomeFilledSvg/HomeFilledSvg';

import { UserOutlinedSvg } from 'components/svgs/UserOutlinedSvg/UserOutlinedSvg';
import { UserFilledSvg } from 'components/svgs/UserFilledSvg/UserFilledSvg';
import {
  mailroomDashboardRoute,
  mailroomListRoute,
  rolesRoute,
} from './routes';

export const dashboardMenuItem = (): OpRawMenuItemType => ({
  key: mailroomDashboardRoute,
  title: i18n.t('Home'),
  route: mailroomDashboardRoute,
  icon: <HomeOutlinedSvg />,
  selectedIcon: <HomeFilledSvg />,
  scope: [
    'o{orgId}-parcelMgmtParcels:r',
    'o{orgId}-parcelMgmtParcels:w',
    'o:w',
    'o:r',
    's-o:r',
    's-o:w',
  ],
});

export const mailroomListMenuItem = (): OpRawMenuItemType => ({
  key: mailroomListRoute,
  title: i18n.t('Mailroom'),
  route: mailroomListRoute,
  icon: <MailroomSvg />,
  selectedIcon: <MailroomSvg />,
  scope: [
    'o{orgId}-parcelMgmtParcels:r',
    'o{orgId}-parcelMgmtParcels:w',
    'o:w',
    'o:r',
    's-o:r',
    's-o:w',
  ],
});

export const RolesMenuItem = (): OpRawMenuItemType => ({
  key: rolesRoute,
  title: i18n.t('Roles'),
  route: rolesRoute,
  icon: <UserOutlinedSvg />,
  selectedIcon: <UserFilledSvg />,
  scope: [
    'o{orgId}-role:r',
    'o{orgId}-role:w',
    'o:w',
    'o:r',
    's-o:r',
    's-o:w',
    'o-role:r',
    'o-role:w',
  ],
});

export const sidebarItems = (): OpRawMenuItemType[] => [
  dashboardMenuItem(),
  mailroomListMenuItem(),
  RolesMenuItem(),
];
