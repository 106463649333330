/*
 *
 * App constants
 *
 */

import {
  appMarketplaceRoute,
  alarmDashboardRoute,
  hardwareDashboardRoute,
  entryDashboardRoute,
  buildingsRoute,
  licensesRoute,
  usersTableRoute,
  userSchedulesTableRoute,
  userCustomFieldsTableRoute,
  userCredentialsManagementRoute,
  presenceReportRoute,
  usersImportRoute,
  rolesRoute,
  sitesTableRoute,
  zonesTableRoute,
  entriesTableRoute,
  entryStatesTableRoute,
  entrySchedulesTableRoute,
  acusTableRoute,
  readersTableRoute,
  activityLogsRoute,
  userActivityRoute,
  entryActivityRoute,
  userActivitySummaryRoute,
  entryActivitySummaryRoute,
  entryAccessAuditRoute,
  userAccessAuditRoute,
  portalAuditReportRoute,
  integrationsRoute,
  mobileAppSettingsRoute,
  badgeTemplatesTablePageRoute,
  intercomUserDirectoryRoute,
  quickStartRoute,
  accountPageRoute,
  releaseNotesRoute,
  masterDashboardRoute,
  masterOrgTableRoute,
  masterIdentitiesTableRoute,
  masterRolesTableRoute,
  masterAcusTableRoute,
  masterEntriesTableRoute,
  masterThirdPartyDevicesRoute,
  masterAllegionFobsRoute,
  masterFeatureFlagsTableRoute,
  masterPortalAuditReportRoute,
  masterAcuSoftwareReportRoute,
  masterReleaseNotesRoute,
  myAppsTableRoute,
  partnerRequestsTableRoute,
  alertSettingsRoute,
  lockdownTableRoute,
  camerasRoute,
  cameraDetailsRoute,
  subscriptionsTableRoute,
  visualActivityReportRoute,
  wirelessLockGatewaysTableRoute,
  wirelessLocksTableRoute,
  deviceUpdateManagement,
  partnerAcusTableRoute,
  partnerDashboardRoute,
  partnerStoreRoute,
  partnerSubscriptionRenewalsTableRoute,
  partnerOrgTableRoute,
  partnerOrgCreateRoute,
  scheduledReportsRoute,
  cameraDeeplinkRedirectRoute,
  alarmReportRoute,
  musterReportsRoute,
  accessGroupsRoute,
  activityDashboardRoute,
  videoIntercomReadersRoute,
  videoReadersRoute,
  occupancyReportRoute,
  alarmConfigurationsRoute,
  partnerThirdPartyDevicesRoute,
  partnerEntriesTableRoute,
  partnerBillingRoute,
} from '../constants';

export const CHECK_ACCESS_TOKEN = 'app/App/CHECK_ACCESS_TOKEN';
export const CHECK_INVITE_TOKEN = 'app/App/CHECK_INVITE_TOKEN';
export const CHECK_RESET_TOKEN = 'app/App/CHECK_RESET_TOKEN';
// export const CHECK_USER_RESET_TOKEN = 'app/App/CHECK_USER_RESET_TOKEN'
export const INIT_STORE_FROM_COOKIE = 'app/App/INIT_STORE_FROM_COOKIE';

export const FINALIZE_LOGIN = 'app/App/FINALIZE_LOGIN';
export const LOGIN_SSO_REQUEST = 'app/App/LOGIN_SSO_REQUEST';
export const LOGIN_SSO_CALLBACK = 'app/App/LOGIN_SSO_CALLBACK';
export const LOGIN_SUCCESS = 'app/App/LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'app/App/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/App/LOGOUT_SUCCESS';
export const FORGOT_PASSWORD = 'app/App/FORGOT_PASSWORD';
export const REQUEST_RESET_PASSWORD = 'app/App/REQUEST_RESET_PASSWORD';
export const LOGIN_REFRESHED = 'app/App/LOGIN_REFRESHED';

// new maintenance mode stuff
export const CHECK_FOR_MAINTENANCE_MODE = 'app/App/CHECK_FOR_MAINTENANCE_MODE';
export const CHECK_VERSION_NUMBER = 'app/App/CHECK_VERSION_NUMBER';
export const SET_MAINTENANCE_MODE = 'app/App/SET_MAINTENANCE_MODE';
export const SET_UPDATE_REQUIRED = 'app/App/SET_UPDATE_REQUIRED';
export const SET_DISABLED_ROUTES = 'app/App/SET_DISABLED_ROUTES';
export const CHECK_DISABLED_ROUTES = 'app/App/CHECK_DISABLED_ROUTES';

export const SET_CURRENT_ORG = 'app/App/SET_CURRENT_ORG';
export const SET_CURRENT_ORG_NAME = 'app/App/SET_CURRENT_ORG_NAME';

export const APP_ERROR = 'app/App/APP_ERROR';

export const SET_ORG_DROPDOWN_VISIBILITY =
  'app/App/SET_ORG_DROPDOWN_VISIBILITY';
export const SET_LOCKDOWN_DROPDOWN_VISIBILITY =
  'app/App/SET_LOCKDOWN_DROPDOWN_VISIBILITY';

// export const USER_LOGIN_REQUEST = 'app/App/USER_LOGIN_REQUEST'
// export const USER_FORGOT_PASSWORD = 'app/App/USER_FORGOT_PASSWORD'
// export const REQUEST_USER_RESET_PASSWORD = 'app/App/REQUEST_USER_RESET_PASSWORD'

export const SET_ALERT = 'app/Authenticated/SET_ALERT';
export const CLEAR_ALERT = 'app/Authenticated/CLEAR_ALERT';

export const INITIAL_FORM_STATE = 0;
export const LEAVING_FORM_STATE = 1;
export const SUBMITTING_FORM_STATE = 2;
export const LEFT_FORM_STATE = 3;
export const SUBMITTED_FORM_STATE = 4;

export const SET_ERROR_MESSAGE = 'app/Authenticated/SET_ERROR_MESSAGE';

export const SET_ORG_USER_PROFILE_IMAGE_URL =
  'app/App/SET_ORG_USER_PROFILE_IMAGE_URL';
export const SET_IDENTITY_INFO = 'app/App/SET_IDENTITY_INFO';

export const SET_CONFIRMATION_PROPS = 'app/App/SET_CONFIRMATION_PROPS';
export const CHECK_INITIAL_LOGIN_FOR_EXISTING_COOKIE =
  'app/App/CHECK_INITIAL_LOGIN_FOR_EXISTING_COOKIE';

export const SET_PUSH_NOTIFICATION_IS_ENABLED =
  'app/App/SET_PUSH_NOTIFICATION_IS_ENABLED';

export const SET_CURRENT_USER_PREFERENCES =
  'app/App/SET_CURRENT_USER_PREFERENCES';

export const FETCH_GET_MFA_CREDENTIALS = 'app/App/FETCH_GET_MFA_CREDENTIALS';

export const REQUEST_SWITCH_ORG = 'app/App/REQUEST_SWITCH_ORG';

export const MENUITEMS_ROUTES = [
  partnerDashboardRoute,
  partnerStoreRoute,
  partnerOrgTableRoute,
  partnerOrgCreateRoute,
  partnerSubscriptionRenewalsTableRoute,
  partnerRequestsTableRoute,
  partnerAcusTableRoute,
  partnerThirdPartyDevicesRoute,
  partnerEntriesTableRoute,
  partnerBillingRoute,
  activityDashboardRoute,
  alarmDashboardRoute,
  entryDashboardRoute,
  hardwareDashboardRoute,
  camerasRoute,
  cameraDetailsRoute,
  cameraDeeplinkRedirectRoute,
  usersTableRoute,
  usersImportRoute,
  accessGroupsRoute,
  rolesRoute,
  userSchedulesTableRoute,
  userCustomFieldsTableRoute,
  sitesTableRoute,
  buildingsRoute,
  zonesTableRoute,
  entriesTableRoute,
  entryStatesTableRoute,
  entrySchedulesTableRoute,
  lockdownTableRoute,
  acusTableRoute,
  readersTableRoute,
  wirelessLocksTableRoute,
  wirelessLockGatewaysTableRoute,
  videoReadersRoute,
  videoIntercomReadersRoute,
  deviceUpdateManagement,
  activityLogsRoute,
  alarmReportRoute,
  userCredentialsManagementRoute,
  entryAccessAuditRoute,
  entryActivityRoute,
  entryActivitySummaryRoute,
  musterReportsRoute,
  occupancyReportRoute,
  portalAuditReportRoute,
  scheduledReportsRoute,
  userAccessAuditRoute,
  userActivityRoute,
  userActivitySummaryRoute,
  visualActivityReportRoute,
  appMarketplaceRoute,
  myAppsTableRoute,
  integrationsRoute,
  alarmConfigurationsRoute,
  subscriptionsTableRoute,
  alertSettingsRoute,
  mobileAppSettingsRoute,
  badgeTemplatesTablePageRoute,
  intercomUserDirectoryRoute,
  presenceReportRoute,
  masterDashboardRoute,
  masterOrgTableRoute,
  masterIdentitiesTableRoute,
  masterRolesTableRoute,
  masterAcusTableRoute,
  masterEntriesTableRoute,
  masterThirdPartyDevicesRoute,
  masterAllegionFobsRoute,
  masterFeatureFlagsTableRoute,
  masterPortalAuditReportRoute,
  masterAcuSoftwareReportRoute,
  masterReleaseNotesRoute,
  accountPageRoute,
  licensesRoute,
  quickStartRoute,
  releaseNotesRoute,
];
