import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const AltaMailroomSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 36 36" {...svgProps}>
    <path d="M11 3V21.94C11 22.45 11.26 22.93 11.69 23.2C11.94 23.36 12.22 23.44 12.5 23.44C12.71 23.44 12.93 23.39 13.13 23.3L17.79 21.15C17.92 21.09 18.08 21.09 18.21 21.15L22.87 23.3C23.34 23.51 23.87 23.48 24.31 23.2C24.74 22.92 25 22.45 25 21.94V3H11Z" />
    <path
      opacity="0.6"
      d="M28.5 3H27V6H28.5C29.88 6 31 7.12 31 8.5V27.5C31 28.88 29.88 30 28.5 30H7.5C6.12 30 5 28.88 5 27.5V8.5C5 7.12 6.12 6 7.5 6H9V3H7.5C4.47 3 2 5.47 2 8.5V27.5C2 30.53 4.47 33 7.5 33H28.5C31.53 33 34 30.53 34 27.5V8.5C34 5.47 31.53 3 28.5 3Z"
    />
  </SvgWrapper>
);
