import { Outlet, useNavigate } from 'react-router-dom-v5-compat';
import { OpLayout } from 'new-components/OpLayout/OpLayout';
import { OpContent } from 'new-components/OpContent/OpContent';
import { Suspense, useCallback, useState } from 'react';
import { OpAppHeader } from 'new-components/OpAppHeader/OpAppHeader';
import { OpAppNav } from 'new-components/OpAppNav/OpAppNav';
import { ORG_ID_ROUTE_TEMPLATE } from 'new-components/OpAppScaffold/constants';
import { getOpParams } from 'utils/getOpParams';
import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { OpAppSuspenseLoader } from 'new-components/OpAppScaffold/OpAppSuspenseLoader';

import './OpAppLayout.scss';

interface OpAppLayoutProps {
  homePath: string;
  items: OpRawMenuItemType[];
  title: string;
  showOrgSwitcher?: boolean;
}

export const OpAppLayout = ({
  homePath,
  items,
  title,
  showOrgSwitcher,
}: OpAppLayoutProps) => {
  const { orgId } = getOpParams();
  const navigate = useNavigate();
  const [isShowingNavOnMobile, setIsShowingNavOnMobile] = useState(true);

  const handleLogoClick = useCallback(() => {
    navigate(homePath.replace(ORG_ID_ROUTE_TEMPLATE, orgId?.toString() ?? ''));
  }, [homePath, navigate, orgId]);

  const handleHamburgerClick = useCallback(() => {
    setIsShowingNavOnMobile((prev) => !prev);
  }, [setIsShowingNavOnMobile]);

  return (
    <OpLayout className="op-app-layout">
      <OpAppNav
        items={items}
        title={title}
        onLogoClick={handleLogoClick}
        setIsShowingNavOnMobile={setIsShowingNavOnMobile}
        /**
         * Using a prop here so we can use css to hide and show the nav so that when the state still has
         * this hidden, on tablet + it can still be visible
         */
        hidden={!isShowingNavOnMobile}
      />
      <OpLayout>
        <OpAppHeader
          showHamburger={!isShowingNavOnMobile}
          onHamburgerClick={handleHamburgerClick}
          homePath={homePath}
          showOrgSwitcher={showOrgSwitcher}
        />
        <OpContent className="op-app-layout__content">
          <Suspense fallback={<OpAppSuspenseLoader />}>
            <Outlet /> {/* Render content from child routes */}
          </Suspense>
        </OpContent>
      </OpLayout>
    </OpLayout>
  );
};
