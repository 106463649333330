import { Store } from 'antd/es/form/interface';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface OpFormContextProps {
  isDataLoading: boolean;
  isReadOnly: boolean;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>> | undefined;
  currentFormValues?: Store;
  setCurrentFormValues?: Dispatch<SetStateAction<Store>> | undefined;
}

/**
 * Context for the OpForm component.
 *
 * This context provides the properties and methods required for the OpForm component
 * to function correctly. It is initialized with an undefined value.
 *
 * @type {OpFormContextProps | undefined}
 */
export const OpFormContext = createContext<OpFormContextProps>({
  isDataLoading: false,
  isReadOnly: false,
  isSubmitting: false,
  setIsSubmitting: undefined,
});

/**
 * Custom hook to access the OpFormContext.
 *
 * @returns {OpFormContextProps} The context value of OpFormContext.
 */
export const useOpFormContext = (): OpFormContextProps => {
  const context = useContext(OpFormContext);

  return context;
};
