import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpSiteSpecificUserDataFetchTransfer } from 'new-components/DLS/OpSiteSpecificUserDataFetchTransfer/OpSiteSpecificUserDataFetchTransfer';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormSiteSpecificUserDataFetchTransfer.scss';

interface OpFormSiteSpecificUserDataFetchTransferProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      | 'name'
      | 'label'
      | 'tooltip'
      | 'rules'
      | 'validateTrigger'
      | 'validateStatus' // Temp: For SUIR wrapped version
      | 'help' // Temp: For SUIR wrapped version
      | 'required' // Temp: For SUIR wrapped version
    >,
    ComponentProps<typeof OpSiteSpecificUserDataFetchTransfer> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormSiteSpecificUserDataFetchTransfer = ({
  // Form Item props
  name,
  label,
  tooltip,
  rules,
  validateTrigger,
  formItemClassName,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,

  ...transferProps
}: OpFormSiteSpecificUserDataFetchTransferProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx(
        'op-form-site-specific-user-data-fetch-transfer',
        formItemClassName,
      )}
      name={name}
      label={label}
      tooltip={tooltip}
      rules={rules}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpSiteSpecificUserDataFetchTransfer
          disabled={isReadOnly || disabled}
          {...transferProps}
        />
      )}
    </OpForm.Item>
  );
};
