import { selectIdentityId } from 'global/accessToken/selectors';
import { OpForm } from 'new-components/DLS/OpForm/OpForm';
import { OpModal } from 'new-components/DLS/OpModal/OpModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectorJs } from 'utils/customHooks';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { OpClickable } from 'new-components/DLS/OpClickable/OpClickable';

import './FeedbackMenuItem.scss';

export const FeedbackMenuItem = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const mutation = useOpMutation({
    apiEndpointName: 'identitySendFeedback',
    onSuccessMessage: t('Thanks for your feedback!'),
    onSuccessCallback: () => {
      setOpen(false);

      // Clear the message area so that a new message can be sent
      form.resetFields();
    },
  });

  const identityId = useSelectorJs(selectIdentityId);

  const [form] = OpForm.useForm<{ message: string }>();

  return (
    <>
      <OpClickable onClick={() => setOpen(true)}>
        {t('Send feedback')}
      </OpClickable>
      <OpModal
        title={t('Feedback')}
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <OpForm
          hasError={false}
          form={form}
          onSubmit={({ touchedValues: { message } }) => {
            if (message) {
              mutation.mutate({
                apiEndpointRequirements: [identityId],
                payload: {
                  subject: `${message.split(' ').slice(0, 5).join(' ')}...`,
                  message,
                  userAgent: navigator.userAgent,
                  appId: 2, // 2 is for Platinum
                },
              });
            }
          }}
          defaultButtons={{ submitButtonLabel: t('Send') }}
        >
          <OpForm.TextAreaInput
            className="feedback-menu-item__text-area-input"
            autoFocus
            name="message"
          />
        </OpForm>
      </OpModal>
    </>
  );
};
