export type ColorThemes = 'light' | 'dark' | 'system';

export const appColorThemeManager = {
  localStorageKey: 'colorTheme',
  baseEl: document.documentElement,

  handleSystemThemeChange(event: MediaQueryListEvent) {
    const newColorScheme = event.matches ? 'dark' : 'light';

    this.handleThemeToggle(newColorScheme);
  },

  initSystemThemeChangeListener() {
    this.removeSystemThemeChangeListener();
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this.handleSystemThemeChange.bind(this));
  },

  removeSystemThemeChangeListener() {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', this.handleSystemThemeChange.bind(this));
  },

  addDarkClass() {
    this.baseEl.classList.add('dark');
  },

  removeDarkClass() {
    this.baseEl.classList.remove('dark');
  },

  addSystemClass() {
    this.baseEl.classList.add('system');
  },

  removeSystemClass() {
    this.baseEl.classList.remove('system');
  },

  getCurrentElColorTheme() {
    if (this.baseEl.classList.contains('system')) {
      return 'system';
    }

    if (this.baseEl.classList.contains('dark')) {
      return 'dark';
    }

    return 'light';
  },

  isSystemDarkMode() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  },

  handleThemeToggle(colorTheme: ColorThemes) {
    switch (colorTheme) {
      case 'light': {
        this.removeDarkClass();
        this.removeSystemClass();
        break;
      }

      case 'dark': {
        this.addDarkClass();
        this.removeSystemClass();
        break;
      }

      case 'system': {
        const isSystemDarkMode = window.matchMedia(
          '(prefers-color-scheme: dark)',
        ).matches;

        if (isSystemDarkMode) {
          this.addDarkClass();
        } else {
          this.removeDarkClass();
        }

        this.addSystemClass();

        break;
      }

      default: {
        console.error(`Passed unsupported colorTheme ${colorTheme}`);
      }
    }
  },

  getColorThemeForProvider(colorTheme: ColorThemes) {
    switch (colorTheme) {
      case 'light': {
        return 'light';
      }

      case 'dark': {
        return 'dark';
      }

      case 'system': {
        return this.isSystemDarkMode() ? 'dark' : 'light';
      }

      default: {
        return 'light';
      }
    }
  },
};
