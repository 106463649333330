import clsx from 'clsx';
import { OpText } from 'new-components/DLS/OpText/OpText';

import './PermissionCell.scss';

interface PermissionCellProps {
  label: string;
  isGranular: boolean;
}

export const PermissionCell = ({ label, isGranular }: PermissionCellProps) => (
  <div
    className={clsx('permission-cell', {
      'permission-cell--granular': isGranular,
    })}
  >
    {isGranular && <span className="permission-cell__icon">&#183;</span>}
    <OpText inline>{label}</OpText>
  </div>
);
