import { ComponentProps, ReactNode } from 'react';
import clsx from 'clsx';
import { OpCheckbox } from 'new-components/DLS/OpCheckbox/OpCheckbox';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { v4 as uuidv4 } from 'uuid';
import { useOpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormCheckbox.scss';

interface OpFormCheckbox
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'tooltip' | 'rules'
    >,
    Omit<ComponentProps<typeof OpCheckbox>, 'name' | 'tooltip'> {
  formItemClassName?: string;
  isLoading?: boolean;
  checkboxTooltip?: ReactNode;
}

export const OpFormCheckbox = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,
  rules,

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  children,
  checkboxTooltip,
  testId,
  disabled,
  ...elementProps
}: OpFormCheckbox) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useOpFormContext();

  const requiredRule = rules?.find(
    (rule) => typeof rule === 'object' && rule.required,
  );

  const isCheckboxRequired = Boolean(requiredRule);

  /** Adding the `{ required: true }` rule to the `rules` prop doesn't
   * show the UI error, so we must add a custom validator */
  if (rules && isCheckboxRequired) {
    rules.push({
      validator: async (_, checked: boolean) => {
        if (!checked) {
          return Promise.reject(
            new Error(
              typeof requiredRule === 'object' &&
              typeof requiredRule.message === 'string'
                ? requiredRule.message
                : 'Checkbox must be checked',
            ),
          );
        }
      },
    });
  }

  const uuid = uuidv4();
  const opCheckboxId = `op-checkbox-${uuid}`;

  return (
    <OpForm.Item
      className={clsx('op-form-checkbox', formItemClassName)}
      name={name}
      tooltip={tooltip}
      label={label}
      rules={rules}
      valuePropName="checked"
      {...(!name && { htmlFor: opCheckboxId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Avatar active size="small" shape="square" />
      ) : (
        <OpCheckbox
          disabled={isReadOnly || disabled}
          tooltip={checkboxTooltip}
          {...(!name && { id: opCheckboxId })} // If a name isn't passed there is no association between the label and the input. This creates that association.
          {...elementProps}
          testId={testId}
        >
          {children}
          {isCheckboxRequired && !label && (
            <span className="op-form-checkbox__required-asterisk">*</span>
          )}
        </OpCheckbox>
      )}
    </OpForm.Item>
  );
};
