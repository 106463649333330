import { KeyboardEvent, useState } from 'react';
import { DefaultOptionType } from 'antd/es/select';
import { OpSelect, OpSelectMultipleProps } from '../OpSelect/OpSelect';

import './OpTagInput.scss';

type SelectProps<
  T extends string | number,
  Option extends DefaultOptionType,
> = OpSelectMultipleProps<T, Option>;

interface OpTagInputProps<
  T extends string | number,
  Option extends DefaultOptionType,
> extends Omit<
    SelectProps<T, Option>,
    'mode | notFoundContent' | 'open' | 'showArrow'
  > {
  gtm?: string;
  testId?: string;
}

export const OpTagInput = <
  Value extends string | number,
  Option extends DefaultOptionType = DefaultOptionType,
>({
  className = '',
  onChange = () => {},
  gtm,
  testId = 'op-tag-input',
  ...opSelectProps
}: OpTagInputProps<Value, Option>) => {
  const [searchValue, setSearchValue] = useState('');

  // Update the search value in state when typing in the input
  const handleSearch = (val: string) => setSearchValue(val);

  // Reset the value when a tag is created so we can hide the "dropdown"
  const handleChange = (value: Value[], option: Option[]) => {
    setSearchValue('');

    // Execute the onChange callback if passed through (normally from Form.Item wrapper)
    onChange(value, option);
  };

  /** Normally both pressing "Enter" or clicking outside the element would create
   * a tag and fire onChange. However, when the tag is a duplicate, then the tag
   * is not created and onChange is not fired. We must handle these cases manually
   * by clearing the search value on key down and on blur. */
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      setSearchValue('');
    }
  };
  const handleBlur = () => setSearchValue('');

  return (
    /** This would be easier if we wrapped in a div and put relative
     * positioning on it so that we could position the "mock dropdown"
     * off of the Select wrapper, but that would mess up any classes
     * passed to the Select */
    <>
      <OpSelect
        className={`op-tag-input ${className}`.trim()}
        mode="tags"
        notFoundContent={null}
        open={false}
        suffixIcon={null}
        onSearch={handleSearch}
        onBlur={handleBlur}
        onChange={(value, options) => handleChange(value, options)}
        onKeyDown={handleKeyDown}
        testId={testId}
        gtm={gtm} // Pass the gtm through to OpSelect to handle
        // Other props are passed through to OpSelect
        {...opSelectProps}
      />
      {searchValue && (
        <div className="op-tag-input__mock-dropdown">
          <div className="op-tag-input__mock-dropdown-item">
            <strong>Add</strong> {searchValue}
          </div>
        </div>
      )}
    </>
  );
};
