import { useEffect, useState } from 'react';

import { getAccessToken } from 'utils/accessToken';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getActiveScopes, validateScopes } from 'utils/scopes';
import { useFeatureFlag } from 'utils/customHooks/useFeatureFlag';

export const useIsMailroomEnabled = ({ orgId }: { orgId: number }) => {
  const [isMailroomEnabled, setIsMailroomEnabled] = useState(false);
  const accessToken = getAccessToken();

  // Fetch the current org information
  const { data: orgData, isPending: isOrgIdPending } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  const { data: tokenData, isPending: isTokenPending } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    select: (data) => data.json.data,
  });

  const { value: isMailroomEnabledFF, isPending: isFeatureFlagPending } =
    useFeatureFlag<Boolean>('ENABLE_MAILROOM', orgId);

  useEffect(() => {
    if (isTokenPending || isOrgIdPending || isFeatureFlagPending) return;
    // Get org & identity scopes
    const orgScopes = getActiveScopes({
      tokenScopeList: tokenData?.tokenScopeList ?? [],
      orgId: Number(orgId),
      parentOrgId: orgData?.parentOrg?.id,
    });

    // Get partner-level scopes
    const partnerScopes = getActiveScopes({
      tokenScopeList: tokenData?.tokenScopeList ?? [],
      parentOrgId: orgData?.parentOrg?.id,
    });

    // Get all the applicable scopes for the user and current org
    const activeScopes = [...orgScopes, ...partnerScopes];
    const hasMailroomRights =
      isMailroomEnabledFF &&
      validateScopes({
        activeScopes,
        rawAllowedScopes: [
          `o${orgId}-parcelMgmtParcels:r`,
          `o${orgId}-parcelMgmtParcels:w`,
          'o:w',
          'o:r',
          's-o:r',
          's-o:w',
        ],
        orgId: String(orgId),
      });

    setIsMailroomEnabled(!!hasMailroomRights);
  }, [
    isFeatureFlagPending,
    isMailroomEnabledFF,
    isOrgIdPending,
    isTokenPending,
    orgData?.parentOrg?.id,
    orgId,
    tokenData?.tokenScopeList,
  ]);

  return {
    isMailroomEnabled,
    isLoading: isTokenPending || isOrgIdPending || isFeatureFlagPending,
  };
};
