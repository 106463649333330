import { getOpParams } from 'utils/getOpParams';
import { useIsMailroomEnabled } from 'new-components/AppSwitcher/hooks/useIsMailroomEnabled';
import { useIsDmpEnabled } from './useIsDmpEnabled';
import { useIsUnificationEnabled } from './useIsUnificationEnabled';

export const useIsAppSwitcherVisible = () => {
  const { orgId } = getOpParams();
  const isAwareEnabled = useIsUnificationEnabled();
  const { isDmpEnabled, isPending: isLoadingIsDmpEnabled } = useIsDmpEnabled();

  const { isMailroomEnabled, isLoading: isLoadingIsMailroomEnabled } =
    useIsMailroomEnabled({ orgId });

  const isAppSwitcherVisible =
    isAwareEnabled || isDmpEnabled || !!isMailroomEnabled;

  return {
    isAppSwitcherVisible,
    isAwareEnabled,
    isDmpEnabled,
    isLoadingIsDmpEnabled,
    isMailroomEnabled,
    isLoadingIsMailroomEnabled,
  };
};
