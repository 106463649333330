import {
  ComponentProps,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import unionBy from 'lodash/unionBy';
import { useTranslation } from 'react-i18next';
import { useIdentityAndUser } from 'utils/customHooks/useIdentityAndUser';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { DefaultOptionType } from 'antd/es/select';
import { getOpParams } from 'utils/getOpParams';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { MasterModeButton } from 'new-components/MastermodeButton/MasterModeButton';
import debounce from 'lodash/debounce';

import { OrgSwitcherOptionLabel } from './OrgSwitcherOptionLabel';
import { useOrgSwitcher } from './hooks/useOrgSwitcher';
import {
  checkHasGeneralOrgSwitcherAccess,
  checkIsParentOrg,
  checkMasterModeAccess,
  getIsFullSupportAccessRequiredIdentityScope,
  getOrgsWithPortalScopesForCurrentUser,
} from './helpers/orgAccess';
import { getIconForOrg } from './helpers/getIconForOrg';
import { getLabelsForOrg } from './helpers/getLabelsForOrg';

import './OrgSwitcher.scss';

interface OrgSwitcherOptionType extends DefaultOptionType {
  labelString: string;
}

// Using a custom type with the properties needed from both types of org in mergedOrgs
export interface MergedOrgsEntry {
  id: number;
  parentOrg: { id: number; name: string } | null;
  allowFullParentAccess?: boolean;
  allowFullSupportAccess?: boolean;
  name: string;
  isLicenseBased?: boolean;
  enabledPackagePlans?: Api.Response['listOrgs'][0]['enabledPackagePlans'];
  packagePlans?: Utils.DeepNonNullable<
    Api.Response['describeAccessToken']['tokenScopeList']
  >[0]['org']['packagePlans'];
}

type OpSelectOptions = ComponentProps<typeof OpSelect>['options'];

const MOBILE_WIDTH = 768;

export const OrgSwitcher = ({
  isLoading,
  homePath,
}: {
  isLoading?: boolean;
  homePath?: string;
}) => {
  const { t } = useTranslation();
  const { token, identity } = useIdentityAndUser();
  const { orgId } = getOpParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      setIsMobile(() => window.innerWidth <= MOBILE_WIDTH);
    }, 25);

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const [currentOrgId, setCurrentOrgId] = useState<string | null>(
    String(orgId),
  );

  const {
    orgData: allOrgs,
    isPending: orgDataIsPending,
    setSearchValue,
    isOrgSwitching,
    handleSelectChange,
  } = useOrgSwitcher({
    homePath,
    token: token ?? {},
    identityId: identity?.id,
    currentOrgId,
    setCurrentOrgId,
  });

  // Get current org information
  const { data: currentOrg } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [Number(currentOrgId)],
    select: (data) => data.json.data,
  });

  // Get all orgs the user has portal scopes for to include in the org select initially.
  const portalScopeOrgsForIdentity = useMemo(
    () => getOrgsWithPortalScopesForCurrentUser(token),
    [token],
  );

  // Check if the user's identity scopes require full support access
  const isFullSupportAccessRequired = useMemo(
    () => getIsFullSupportAccessRequiredIdentityScope(token),
    [token],
  );

  // Check if user has mastermode access
  const hasMasterModeAccess = useMemo(
    () =>
      checkMasterModeAccess({
        token,
      }),
    [token],
  );

  // Check if the user can org switching access
  const hasGeneralOrgSwitchAccess = useMemo(
    () =>
      checkHasGeneralOrgSwitcherAccess({
        token,
      }),
    [token],
  );

  // Create the org options for the current user
  const orgOptions = useMemo(() => {
    if (isLoading || orgDataIsPending || !hasGeneralOrgSwitchAccess) {
      return [];
    }

    // Ensure user's portal scope orgs are in the list
    // Note allOrgs has the type of listOrgs response but portalScopeOrgsForIdentity only has what
    // is on the return of describeAccessToken. It's the same as the old org switcher, so not bothering
    // to fetch those orgs seperately.
    const mergedOrgs = unionBy(portalScopeOrgsForIdentity, allOrgs, 'id');
    const portalScopeOrgIds = portalScopeOrgsForIdentity?.map((org) => org?.id);

    const finalOrgOptions = mergedOrgs?.reduce(
      (acc: OrgSwitcherOptionType[], curOrg) => {
        const {
          id,
          name,
          parentOrg,
          allowFullParentAccess,
          allowFullSupportAccess,
          isLicenseBased,
          enabledPackagePlans,
          packagePlans,
          // casting to custom type with the properties needed from both listOrgs and the describeAccessToken.tokenScopeList[0].org
          // to best mimic the old org switcher logic - undefined should be ok for the orgs of the opposite type.
        } = curOrg as MergedOrgsEntry;

        // Check for distinctions that determine if the org should be in the org switcher or not and how they should show
        const isSelectedOrg = String(id) === currentOrgId;
        const hasParentOrg = !!parentOrg;
        const hasAccessViaIdentity = portalScopeOrgIds?.includes(id);
        const isParentOrg = checkIsParentOrg({
          packagePlans: enabledPackagePlans ?? packagePlans, // enabledPackagePlans come from listOrgs and packagePlans come from describeAccessToken
        });

        // Check if the current user has access for the current org being processed.
        const noAccessThroughParent =
          hasParentOrg &&
          !allowFullParentAccess &&
          !hasAccessViaIdentity &&
          !hasMasterModeAccess;

        const noAccessDueToFSA =
          isFullSupportAccessRequired &&
          !allowFullSupportAccess &&
          !hasAccessViaIdentity;

        const accessRemoved = noAccessThroughParent || noAccessDueToFSA;

        // get the option icon
        const icon = getIconForOrg({
          isSelected: isSelectedOrg,
          accessRemoved,
          isParentOrg,
        });

        // get the option labels
        const subHeaderLabels = getLabelsForOrg({
          hasMasterModeAccess,
          isLicenseBased,
          isParentOrg,
        });

        const option: OrgSwitcherOptionType = {
          value: String(id),
          disabled: accessRemoved,
          labelString: name, // Used for filtering (so ban text isnt included in filtering)
          title: accessRemoved // Used for popup when hovering over option and selected text
            ? t(
                'This organization has limited your access. If you need access restored, please contact them and ask them to re-enable at Administration > Account > Security Settings.',
              )
            : name,
          label: (
            <OrgSwitcherOptionLabel
              isMobile={isMobile}
              name={name}
              orgId={id}
              icon={icon}
              labels={subHeaderLabels}
            />
          ),
        };

        // We want the selectedOrg to be the first in the list of options
        return isSelectedOrg ? [option].concat(acc) : acc.concat(option);
      },
      [],
    ) as OpSelectOptions;

    return finalOrgOptions;
  }, [
    isLoading,
    orgDataIsPending,
    hasGeneralOrgSwitchAccess,
    portalScopeOrgsForIdentity,
    allOrgs,
    currentOrgId,
    hasMasterModeAccess,
    isFullSupportAccessRequired,
    t,
    isMobile,
  ]);

  const selectedLabelRender = useCallback(() => {
    const icon = isOrgSwitching ? <LoadingOutlined /> : null;

    return orgId > 0 ? (
      <div className="org-switcher__selected-label">
        {icon}
        <span>{currentOrg?.name}</span>
      </div>
    ) : (
      t('Master mode')
    );
  }, [currentOrg?.name, isOrgSwitching, orgId, t]);

  const dropdownWithArrow:
    | ((menu: ReactElement) => ReactElement)
    | undefined = (menu) => (
    <>
      <div className="org-switcher__dropdown-arrow" />
      {menu}
    </>
  );

  return (
    <div className="org-switcher">
      <MasterModeButton
        isMobile={isMobile}
        isControlCenter={!homePath}
        onMastermodeSwitch={() => {
          setCurrentOrgId(null);
        }}
      />
      {hasGeneralOrgSwitchAccess && (
        <OpSelect
          testId="org-switcher-select"
          key={orgId}
          disabled={isOrgSwitching}
          className="org-switcher__select"
          showSearch
          notFoundContent={
            orgDataIsPending || isLoading ? <LoadingOutlined /> : undefined
          } // Pass undefined to trigger default behavior
          popupClassName="org-switcher__dropdown"
          value={currentOrgId || '0'}
          onSearch={setSearchValue}
          onChange={handleSelectChange}
          options={orgOptions as OpSelectOptions}
          dropdownRender={dropdownWithArrow}
          labelRender={selectedLabelRender}
          filterOption={(input, option) =>
            (option?.labelString ?? '')
              .toLowerCase()
              .includes(input.toLowerCase()) ||
            String(option?.value ?? '').includes(input)
          }
          dropdownAlign={{
            points: ['tl', 'bl'], // Align top-left of dropdown to bottom-left of input
            offset: [0, 12], // Offset horizontal and vertical alignment
          }}
        />
      )}
      {/* {!hasGeneralOrgSwitchAccess && <div>{currentOrg?.name}</div>} */}
    </div>
  );
};
