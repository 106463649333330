import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { ComponentProps } from 'react';
import { OpForm } from 'new-components/DLS/OpForm/OpForm';
import { OpFormHiddenItemForDataSetting } from 'new-components/DLS/OpForm/formComponents/OpFormHiddenItemForDataSetting/OpFormHiddenItemForDataSetting';
import { ScopeResource } from './ScopeResource';

import './ScopeResourceSection.scss';

interface ScopeResourceSectionProps {
  orgId: number;
  form?: ComponentProps<typeof OpForm>['form'];
  disabled?: boolean;
}

export const ScopeResourceSection = ({
  orgId,
  form,
  disabled,
}: ScopeResourceSectionProps) => {
  const { data: scopeResourceData } = useOpQuery({
    apiEndpointName: 'listScopeResources',
    parameters: [orgId],
    select: (data) => data.json.data ?? [],
  });

  return (
    <div className="scope-resource-section">
      {scopeResourceData?.map((scopeResource) => (
        <ScopeResource
          key={scopeResource.id}
          disabled={disabled}
          orgId={orgId}
          scopeResource={scopeResource}
          form={form}
        />
      ))}
      <OpFormHiddenItemForDataSetting name="roleScopes" />
    </div>
  );
};
