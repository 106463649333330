import { ComponentProps } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'new-components/DLS/OpSkeleton/OpSkeleton';
import { OpPhoneInput } from 'new-components/DLS/OpPhoneInput/OpPhoneInput';
import { useTranslation } from 'react-i18next';
import { isE164PhoneNumber, isPhoneNumber } from 'utils/validators';
import { useOpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

interface OpFormPhoneInputProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'rules' | 'validateTrigger'
    >,
    Omit<ComponentProps<typeof OpPhoneInput>, 'name'> {
  formItemClassName?: string;
  testId?: string;
  isLoading?: boolean;
}

export const OpFormPhoneInput = ({
  name,
  label,
  rules,
  validateTrigger,
  formItemClassName,
  isLoading,

  // Wrapped element props
  disabled,

  ...inputProps
}: OpFormPhoneInputProps) => {
  const { t } = useTranslation();

  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useOpFormContext();
  return (
    <OpForm.Item
      name={name}
      label={label}
      rules={[
        {
          validator: async (_, phoneNumber: string = '') => {
            // Empty phoneNumber is valid as it is optional
            if (!phoneNumber.length) {
              return Promise.resolve();
            }

            // Check if it is in E.164 format
            if (!isE164PhoneNumber(phoneNumber)) {
              return Promise.reject(
                new Error(
                  t(
                    `${phoneNumber} is not in the E.164 format (e.g. +15556667777)`,
                  ),
                ),
              );
            }

            // Check if it is a valid phone number
            if (!isPhoneNumber(phoneNumber)) {
              return Promise.reject(
                new Error(t(`${phoneNumber} is not a valid phone number`)),
              );
            }
          },
        },

        ...(rules || []),
      ]}
      validateTrigger={validateTrigger}
      className={clsx('op-form-phone-input', formItemClassName)}
    >
      {isDataLoading || isLoading ? (
        /** A placeholder in the shape of the component
         * so we can immediately render the structure of the form while
         * data is loading */
        <OpSkeleton.Input active block />
      ) : (
        /** The actual component that is rendered when we have the data */
        <OpPhoneInput disabled={isReadOnly || disabled} {...inputProps} />
      )}
    </OpForm.Item>
  );
};
