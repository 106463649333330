import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';
import { OpImage } from 'new-components/DLS/OpImage/OpImage';
import { OpText } from 'new-components/DLS/OpText/OpText';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { appColorThemeManager, ColorThemes } from 'utils/appColorThemeManager';
import { OpSegmented } from 'new-components/DLS/OpSegmented/OpSegmented';

import './AppColorThemePicker.scss';

export const colorThemes = [
  {
    label: (
      <>
        <OpImage
          src={require('assets/img/theme-lightmode.png')}
          alt="Light theme"
        />
        <OpText className="profile-settings-tab-content-org-settings__color-theme-text">
          Light mode
        </OpText>
      </>
    ),
    value: 'light',
  },
  {
    label: (
      <>
        <OpImage
          src={require('assets/img/theme-darkmode.png')}
          alt="Dark theme"
        />
        <OpText className="profile-settings-tab-content-org-settings__color-theme-text">
          Dark mode
        </OpText>
      </>
    ),
    value: 'dark',
  },
  {
    label: (
      <>
        <OpImage
          src={require('assets/img/theme-system.png')}
          alt="System theme"
        />
        <OpText className="profile-settings-tab-content-org-settings__color-theme-text">
          Match system
        </OpText>
      </>
    ),
    value: 'system',
  },
];

export const AppColorThemePicker = ({
  identityId,
}: {
  identityId?: number;
}) => {
  const { t } = useTranslation();

  const { data: identityPreferences } = useOpQuery({
    apiEndpointName: 'describeIdentityPreferenceSet',
    parameters: [identityId!],
    select: (response) => response.json.data,
    gcTime: 0, // Don't cache
  });

  const setIdentityPreferences = useOpMutation({
    apiEndpointName: 'updateIdentityPreferenceSet',
    queryKeysToInvalidate: [['describeIdentityPreferenceSet']],
    shouldSuppressErrorMessage: true,
    onSuccessMessage: t('Successfully updated preference'),
  });

  const [colorTheme, setColorTheme] = useLocalStorage<ColorThemes>(
    'colorTheme',
    identityPreferences?.colorTheme as ColorThemes,
  );

  return (
    <OpSegmented
      options={colorThemes}
      value={colorTheme || 'light'}
      className="app-color-theme-picker"
      onChange={(newColorTheme) => {
        setColorTheme(newColorTheme as ColorThemes);

        appColorThemeManager.handleThemeToggle(newColorTheme as ColorThemes);

        setIdentityPreferences.mutate({
          apiEndpointRequirements: [identityId!],
          payload: {
            colorTheme: newColorTheme as ColorThemes,
          },
        });
      }}
    />
  );
};
