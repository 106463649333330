import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const AltaPartnersSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 36 36" {...svgProps}>
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4C10.268 4 4 10.268 4 18C4 18.8284 3.32843 19.5 2.5 19.5C1.67157 19.5 1 18.8284 1 18C1 8.61116 8.61116 1 18 1C27.3888 1 35 8.61116 35 18C35 27.3888 27.3888 35 18 35C17.1716 35 16.5 34.3284 16.5 33.5C16.5 32.6716 17.1716 32 18 32C25.732 32 32 25.732 32 18C32 10.268 25.732 4 18 4Z"
    />
    <path d="M18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3431 12.3431C9.21895 15.4673 9.21895 20.5327 12.3431 23.6569C15.4673 26.781 20.5327 26.781 23.6569 23.6569C24.2426 23.0711 25.1924 23.0711 25.7782 23.6569C26.364 24.2426 26.364 25.1924 25.7782 25.7782C21.4824 30.0739 14.5176 30.0739 10.2218 25.7782C5.92606 21.4824 5.92606 14.5176 10.2218 10.2218C14.5176 5.92606 21.4824 5.92606 25.7782 10.2218C26.364 10.8076 26.364 11.7574 25.7782 12.3431C25.1924 12.9289 24.2426 12.9289 23.6569 12.3431C20.5327 9.21895 15.4673 9.21895 12.3431 12.3431Z"
    />
  </SvgWrapper>
);
