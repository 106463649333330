import React, { Fragment, ReactNode } from 'react';

import './TableHeader.scss';

export interface TableHeaderProps {
  additionalButtons?: ReactNode[];
  batchActions?: ReactNode;
  tableLabel?: ReactNode;
  globalSearch?: ReactNode;
  addButton?: ReactNode;
  csvExportButton?: ReactNode;
  showHideColumnsButton?: ReactNode;
}

export const TableHeader = ({
  additionalButtons,
  batchActions,
  tableLabel = '',
  globalSearch,
  addButton,
  csvExportButton,
  showHideColumnsButton,
}: TableHeaderProps) => {
  if (
    !(
      tableLabel ||
      batchActions ||
      globalSearch ||
      addButton ||
      csvExportButton ||
      showHideColumnsButton
    )
  ) {
    return null;
  }

  return (
    <div className="table-header">
      {batchActions && <div>{batchActions}</div>}
      {/** Always show this div so space between works correctly */}
      <div className="table-header__label">{tableLabel}</div>
      <div className="table-header__table-actions">
        {globalSearch}
        {additionalButtons?.map((additionalButton, i) => (
          <Fragment key={i}>{additionalButton}</Fragment>
        ))}
        {addButton}
        {csvExportButton}
        {showHideColumnsButton}
      </div>
    </div>
  );
};
