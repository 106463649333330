import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { useContext } from 'react';
import { RouteContext } from 'context/RouteContext';
import { useLocation } from 'react-router-dom-v5-compat';
import { useValidateScopes } from './useValidateScopes';

/**
 * @description Gets the allowed scopes for a route - works in all apps. This method is
 * recursive to look deep into .items property of OpRawMenuItemType.
 * @param routes OpRawMenuItemType[] - array of menu items for the current app
 * @param route string - route of the current page
 * @returns an array of allowed scopes or null/undefined
 */
export const getAllowedScopes = ({
  routes,
  route,
}: {
  routes: OpRawMenuItemType[];
  route: string;
}): string[] | undefined | null => {
  let allowedScopes: string[] | undefined | null = null;

  routes.find((r) => {
    if (r.route === route) {
      allowedScopes = r.scope;
      return true;
    }

    // if there are nested items - look for route inside them
    if (r.items) {
      allowedScopes = getAllowedScopes({ routes: r.items, route });

      // if it returns null, it did not find an entry matching the route
      if (allowedScopes !== null) return true;
    }

    return false;
  });

  return allowedScopes;
};

export const useHasReadWriteAccess = () => {
  const windowLocation = useLocation();
  const { pathname } = windowLocation;
  const { routes } = useContext(RouteContext) ?? {};

  // pull the current routes
  const regex = /\/o\/(\d+)\/(.*)/;
  const matches = pathname.match(regex);
  const route = matches?.[2];

  const allowedScopes =
    routes && route ? (getAllowedScopes({ routes, route }) ?? []) : [];

  const { isValid: hasReadAccess } = useValidateScopes({
    allowedScopes,
  });

  const { isValid: hasWriteAccess } = useValidateScopes({
    allowedScopes: allowedScopes.filter((s) => s.includes(':w')),
  });

  return { hasReadAccess, hasWriteAccess };
};
