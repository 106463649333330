import { QueryKey } from '@tanstack/react-query';
import { UseOpQueryOptions } from './customHooks/useOpQuery';
import { ensurePayloadAndQuery } from './ensurePayloadAndQuery';
import Helium from './helium';
import { injectScopeHeaders } from './request';

type QueryRequestProps<T extends keyof Api.ClientSpec> =
  UseOpQueryOptions<T> & {
    currentIdentityLanguage: string;
    queryKey?: QueryKey;
    headers?: Record<string, any>;
  };

export const queryRequest = async <T extends keyof Api.ClientSpec>({
  apiEndpointName,
  parameters,
  currentIdentityLanguage,
  headers = {},
}: QueryRequestProps<T>): // @ts-expect-error as already returns promise
ReturnType<Api.Client[T]> => {
  const { parametersArray, withPayload, withQuery } = ensurePayloadAndQuery(
    apiEndpointName,
    parameters,
  );

  const api = Helium.client[apiEndpointName] satisfies Api.Client[T];

  // Throw an error if an invalid api endpoint name
  if (!api) {
    throw new Error(`Invalid Helium client endpoint: \`${apiEndpointName}\``);
  }

  /** path params can be numbers or strings (e.g. describeAlarm has alarmIdExt
   * which is a string) */
  const pathParams = parametersArray.filter(
    (param) => typeof param === 'string' || typeof param === 'number',
  );

  // We need to pull out the payload and query to properly pass to api
  const payloadAndQueryParams =
    withPayload || withQuery
      ? parametersArray.filter((param) => typeof param === 'object')
      : [];

  if (withPayload === 2 && payloadAndQueryParams[0]) {
    // multipart/form-data, use a FormData object instead of a plain object
    // Note: payload is always the first object when a payload should be specified
    const formData = new FormData();

    Object.entries(payloadAndQueryParams[0]).forEach(([key, value]) => {
      if (typeof value === 'string' || value instanceof Blob) {
        formData.append(key, value);
      }
    });

    payloadAndQueryParams[0] = formData;
  }

  // Add Platinum's language to the headers
  const acceptLanguageHeader = { 'Accept-Language': currentIdentityLanguage };
  const finalHeaders = {
    ...headers,
    ...injectScopeHeaders(acceptLanguageHeader),
  };

  // TODO Headers should be injected at client level, not at endpoint level
  // @ts-expect-error cannot infer return
  return api(...pathParams, ...payloadAndQueryParams, {
    headers: finalHeaders,
  });
};
