import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
  MutationFunction,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'routes/AppContainer/actions';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { queryRequest } from 'utils/queryRequest';

export type MutationArgs<
  T extends keyof Api.ClientSpec = keyof Api.ClientSpec,
> = Api.ClientSpec[T]['pathParams'] extends []
  ? {
      apiEndpointRequirements?: never;
      payload?: ApiClientPayload<T>;
      query?: ApiClientQuery<T>;
      [key: string]: any;
    }
  : {
      apiEndpointRequirements: Api.ClientSpec[T]['pathParams'];
      payload?: ApiClientPayload<T>;
      query?: ApiClientQuery<T>;
      [key: string]: any;
    };

export type ApiClientResponse<T extends keyof Api.ClientSpec> = Awaited<
  ReturnType<Api.Client[T]>
>['json']['data'];

type ApiClientPayload<T extends keyof Api.ClientSpec = keyof Api.ClientSpec> =
  Api.ClientSpec[T]['payloadOffset'] extends -1
    ? never
    : Parameters<Api.Client[T]>[Api.ClientSpec[T]['payloadOffset']];

type ApiClientQuery<T extends keyof Api.ClientSpec = keyof Api.ClientSpec> =
  Api.ClientSpec[T]['queryOffset'] extends -1
    ? never
    : Parameters<Api.Client[T]>[Api.ClientSpec[T]['queryOffset']];

interface UseOpMutationOptionsWithoutApiEndpointName<
  TData extends any = any,
  TVariables extends any = any,
> extends UseMutationOptions<TData, OP.ApiError, TVariables> {
  apiEndpointName?: undefined;
  onSuccessMessage?:
    | string
    | ((mutationResponse: TData, mutationArgs: TVariables) => string)
    | null;
  onErrorMessage?: string | ((error: OP.ApiError) => string) | null;
  onSuccessCallback?: (
    mutationResponse: TData,
    mutationArgs: TVariables,
  ) => void;
  shouldSuppressErrorMessage?:
    | boolean
    | ((error: OP.ApiError) => boolean | void);
  queryKeysToInvalidate?: string[][];
  headers?: Record<string, string>;
}

interface UseOpMutationOptionsWithApiEndpointName<
  T extends keyof Api.ClientSpec,
  TData extends any,
  TVariables extends any,
> extends UseMutationOptions<TData, OP.ApiError, TVariables> {
  apiEndpointName: T;
  onSuccessMessage?:
    | string
    | ((mutationResponse: TData, mutationArgs: TVariables) => string)
    | null;
  onErrorMessage?: string | ((error: OP.ApiError) => string) | null;
  onSuccessCallback?: (
    mutationResponse: TData,
    mutationArgs: TVariables,
  ) => void;
  shouldSuppressErrorMessage?:
    | boolean
    | ((error: OP.ApiError) => boolean | void);
  queryKeysToInvalidate?: (string | number)[][];
  headers?: Record<string, string>;
}

type UseOpMutationOptions<
  T extends keyof Api.ClientSpec,
  TData extends unknown = ApiClientResponse<T>,
  TVariables extends unknown = MutationArgs<T>,
> =
  | UseOpMutationOptionsWithApiEndpointName<T, TData, TVariables>
  | UseOpMutationOptionsWithoutApiEndpointName<TData, TVariables>;

export const useOpMutation = <
  T extends keyof Api.ClientSpec,
  TData extends unknown = ApiClientResponse<T>,
  TVariables extends unknown = MutationArgs<T>,
>({
  apiEndpointName,
  onSuccessMessage,
  onErrorMessage,
  onSuccessCallback,
  queryKeysToInvalidate,
  shouldSuppressErrorMessage = false,
  mutationKey,
  mutationFn,
  onError: _onError,
  onSuccess: _onSuccess,
  // TODO fix so we can add these back
  onMutate,
  onSettled,
  headers,
  ...useOpMutationOptions
}: UseOpMutationOptions<T, TData, TVariables>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // TODO - get the language based on authed page logic if not in identity
  /** we first look at the identity language and set the language to that.
   * If that is not set, we then fallback to the local storage setting.
   * If that is not set, then we fallback to the browser locale.
   * And if that is not supported then we use English (en). */
  const currentIdentityLanguage = useSelector(selectCurrentIdentityLanguage());

  const finalMutationFn =
    mutationFn ??
    ((async ({
      apiEndpointRequirements = [],
      payload: rawPayload,
      query: rawQuery,
    }: MutationArgs<T>): Promise<ApiClientResponse<T>> => {
      const parametersArray: Parameters<Api.Client[T]>[number][] = [
        ...apiEndpointRequirements,
      ];

      if (rawPayload) {
        parametersArray.push(rawPayload);
      }
      if (rawQuery) {
        parametersArray.push(rawQuery);
      }

      const result = await queryRequest({
        apiEndpointName: apiEndpointName!,
        parameters: parametersArray as Parameters<Api.Client[T]>,
        currentIdentityLanguage,
        headers,
      });

      if (result.statusCode >= 400) {
        throw result.json;
      }

      return result.json?.data;

      // TODO: Figure out how to do this method without needing the typecast
    }) as MutationFunction<TData, TVariables>);

  const customOnError = (error: OP.ApiError) => {
    const errorMessage =
      typeof onErrorMessage === 'string'
        ? onErrorMessage
        : onErrorMessage
          ? onErrorMessage(error)
          : undefined;

    if (
      (typeof shouldSuppressErrorMessage === 'boolean' &&
        !shouldSuppressErrorMessage) ||
      (typeof shouldSuppressErrorMessage === 'function' &&
        !shouldSuppressErrorMessage(error))
    ) {
      dispatch(setAlert('error', errorMessage || error.message));
    }
  };

  const customOnSuccess = (
    mutationResponse: TData,
    mutationArgs: TVariables,
  ) => {
    const successMessage =
      typeof onSuccessMessage === 'string'
        ? onSuccessMessage
        : onSuccessMessage
          ? onSuccessMessage(mutationResponse, mutationArgs)
          : undefined;

    if (successMessage) {
      dispatch(setAlert('success', successMessage));
    }

    if (onSuccessCallback) {
      onSuccessCallback(mutationResponse, mutationArgs);
    }

    if (queryKeysToInvalidate) {
      queryKeysToInvalidate.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey });
      });
    }
  };

  return useMutation<TData, OP.ApiError, TVariables>({
    mutationKey: mutationKey || apiEndpointName ? [apiEndpointName] : undefined,
    mutationFn: finalMutationFn,
    onError: (_onError || customOnError) as (error: OP.ApiError) => void,
    onSuccess: (_onSuccess || customOnSuccess) as (
      data: TData,
      variables: TVariables,
      context: unknown,
    ) => unknown,
    onMutate,
    onSettled: onSettled as (
      data: TData | undefined,
      error: OP.ApiError | null,
      variables: TVariables,
      context: unknown,
    ) => unknown,
    ...useOpMutationOptions,
  });
};
