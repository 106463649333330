import { OpDataFetchTable } from 'new-components/DLS/OpDataFetchTable/OpDataFetchTable';
import { tableStateKeys } from 'new-components/DLS/OpTableCore/constants/tableStateKeys';
import { OpTableRawColumnType } from 'new-components/DLS/OpTableCore/OpTableCore';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useFeatureFlag } from 'utils/customHooks/useFeatureFlag';
import { useOpMutation } from 'utils/customHooks/useOpMutation';
import { opTime } from 'utils/dates';

export const RolesTable = ({
  orgId,
  uiStateKey,
  defaultFilters,
}: {
  orgId: number;
  uiStateKey: Utils.ValueOf<typeof tableStateKeys>;
  defaultFilters?: { name: string[] };
}) => {
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();

  const deleteRole = useOpMutation({
    apiEndpointName: 'deleteRole',
    onSuccessMessage: t('Role successfully deleted'),
    queryKeysToInvalidate: [['describeRole'], ['listRoles']],
  });

  const columns: OpTableRawColumnType[] = [
    {
      label: t('Id'),
      dataIndex: ['id'],
      sorter: true,
      filter: { type: 'input' },
      hidden: true,
      width: 150,
    },
    {
      label: t('Role name'),
      dataIndex: ['name'],
      ellipsis: true,
      sorter: true,
      filter: { type: 'input' },
      defaultFilteredValue: defaultFilters?.name,
    },
    {
      label: t('Description'),
      dataIndex: ['description'],
      ellipsis: true,
      sorter: true,
      filter: { type: 'input' },
    },
    {
      label: t('User count'),
      dataIndex: ['userCount'],
      width: 150,
      sorter: true,
    },
  ];

  const handleAdd = useCallback(() => {
    setSearchParams({ drawer: 'create' });
  }, [setSearchParams]);

  const handleEdit = useCallback(
    ({ id: editRoleId }: Api.Response['describeRole']) => {
      setSearchParams({ roleId: String(editRoleId), drawer: 'edit' });
    },
    [setSearchParams],
  );

  const handleDelete = useCallback(
    ({ id: deleteRoleId }: Api.Response['describeRole']) => {
      deleteRole.mutate({
        apiEndpointRequirements: [orgId, deleteRoleId],
      });
    },
    [deleteRole, orgId],
  );

  // TODO - Hide mailroom in table for now. Update when no longer determined by a feature flag.
  const { value: mailroomEnabled } = useFeatureFlag<Boolean>(
    'ENABLE_MAILROOM',
    orgId,
  );

  const rolesPreFilter = !mailroomEnabled
    ? { preFilter: 'name:(!~mailroom)' }
    : {};

  return (
    <OpDataFetchTable
      uiStateKey={uiStateKey}
      height="calc(100vh - 344px)"
      opQueryProps={{
        apiEndpointName: 'listRoles',
        parameters: [orgId, rolesPreFilter],
      }}
      columns={columns}
      allowExport={{ filename: `roles_${opTime()}` }}
      allowAddition={{
        itemName: t('Role'),
        onClick: handleAdd,
      }}
      rowActions={{
        onEditClick: handleEdit,
        onDeleteClick: handleDelete,
      }}
    />
  );
};
