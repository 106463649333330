import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { useDebouncedState } from 'utils/customHooks/useDebouncedState';
import { OpDataFetchSelectProps } from './types';
import { OpSelect, SelectableValueTypes } from '../OpSelect/OpSelect';
import { useCreateOptions } from './helpers';

export const OpDataFetchSelect = <
  Value extends SelectableValueTypes,
  T extends keyof Api.ClientSpec,
  SelectData = Awaited<ReturnType<Api.Client[T]>>,
>({
  className = '',
  testId = 'op-data-fetch-select',
  gtm,
  value: valueProp,
  numerifyOptionValues = false, // TEMP - until we can correctly type Selects

  // Props for fetching data and creating options
  optionRender,
  createDisabledOption,
  createOptionLabel,
  queryOptions,
  pathToData = 'json.data',
  pathToLabel = 'name',
  pathToValue = 'id',
  fetchAllInitialValues = false,

  // Props passed through to OpSelect
  ...opSelectProps
}: OpDataFetchSelectProps<T, SelectData, Value>) => {
  const [debouncedQuery, setQuery] = useDebouncedState('');

  const { isLoading, isFetching, options, optionChildren } = useCreateOptions({
    numerifyOptionValues,
    optionRender,
    createDisabledOption,
    createOptionLabel,
    queryOptions,
    pathToData,
    pathToLabel,
    pathToValue,
    fetchAllInitialValues,
    valueProp,
    debouncedQuery,
  });

  /** Manipulating the value so that we make sure we don't have issues in non-ts
   * files. Doing this as Select will display the value if there is no option value
   * that matches (e.g. a number value passed will not match any of the coerced
   * option values [see code above]) */
  const finalValue = !valueProp
    ? valueProp
    : Array.isArray(valueProp)
      ? valueProp.map((v) =>
          numerifyOptionValues ? Number(v) || v : String(v),
        )
      : numerifyOptionValues
        ? Number(valueProp) || valueProp
        : String(valueProp);

  /**
   * If the Select mode is single (default mode), and the option for the
   * value is disabled, we should disable the Select so that the value
   * can't be changed.
   */
  const firstDisabledOption = options?.find(({ disabled }) => disabled);
  const isOptionForSingleModeValueDisabled = opSelectProps.mode
    ? false
    : Boolean(firstDisabledOption && firstDisabledOption.value === valueProp);

  const defaultProps = {
    className: `${className}`.trim(),
    testId,
    gtm,
    showSearch: true,
    filterOption: false, // Needed so options menu doesn't close on change
    optionFilterProp: 'label', // Defaulting to label as Select should always have options and antd docs recommend using label
    notFoundContent: isFetching ? <LoadingOutlined /> : undefined, // Pass undefined to trigger default behavior
    options,
    onSearch: (value: string) => setQuery(value),
  };

  const overrideProps = {
    loading: isFetching,
    disabled:
      opSelectProps.disabled ||
      (isLoading && !debouncedQuery.length) || // Can't disable if there is a query as Select loses focus and typing is interrupted
      isOptionForSingleModeValueDisabled,
    ...(firstDisabledOption && { allowClear: false }), // If an option is disabled, we don't want to allow clearing
  };

  // We duplicate the return here to allow type narrowing to work properly
  if (opSelectProps.mode) {
    return (
      <OpSelect
        // @ts-expect-error - OpSelect can actually handle numbers just fine, but we need to retype it
        value={finalValue}
        {...defaultProps}
        {...opSelectProps}
        onChange={(value, option) => {
          // Update the value if an onChange prop was passed
          opSelectProps.onChange?.(value, option);

          // Reset the query so we see all options again
          setQuery('');
        }}
        mode={opSelectProps.mode} // Keep this prop to ensure type narrowing
        {...overrideProps}
      >
        {optionChildren}
      </OpSelect>
    );
  }
  return (
    <OpSelect
      // @ts-expect-error - OpSelect can actually handle numbers just fine, but we need to retype it
      value={finalValue}
      {...defaultProps}
      {...opSelectProps}
      onChange={(value, option) => {
        // Update the value if an onChange prop was passed
        opSelectProps.onChange?.(value, option);

        // Reset the query so we see all options again
        setQuery('');
      }}
      {...overrideProps}
    >
      {optionChildren}
    </OpSelect>
  );
};
