import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { useSelectorJs } from '.';
import { getOpParams } from '../getOpParams';
import { useAwareDeploymentUrl } from './useAwareDeploymentUrl';

// Unification only means Aware - does not include DMP
export const useIsUnificationEnabled = () => {
  const enabledOrgIds =
    useSelectorJs(selectFeatureFlag('ORG_IDS_WITH_UNIFICATION_ENABLED')) || [];

  const awareDeploymentUrl = useAwareDeploymentUrl();

  const { orgId } = getOpParams();

  // Just in case someone touches `getOpParams` and orgId is no longer a number
  return Boolean(enabledOrgIds.includes(Number(orgId)) && awareDeploymentUrl);
};
