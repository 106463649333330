import AlertWrapperWithSuir from './AlertWrapperWithSuir';
import AlertWrapperWithoutSuir from './AlertWrapperWithoutSuir';

interface AlertWrapperProps {
  // Temporary but gives us the ability to hard code this in net new apps
  removeSuir?: boolean;
}

const AlertWrapper = ({ removeSuir }: AlertWrapperProps) => {
  const hasUserSelectedSuirRemovedUi =
    localStorage.getItem('hasUserSelectedSuirRemovedUi') === 'true';

  return hasUserSelectedSuirRemovedUi || removeSuir ? (
    <AlertWrapperWithoutSuir />
  ) : (
    <AlertWrapperWithSuir />
  );
};

export default AlertWrapper;
