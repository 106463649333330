import { QuestionMarkOutlinedSvg } from 'components/svgs/QuestionMarkOutlinedSvg/QuestionMarkOutlinedSvg';
import { OpDropdown } from 'new-components/DLS/OpDropdown/OpDropdown';
import { OpButton } from 'new-components/DLS/OpButton/OpButton';
import { useTranslation } from 'react-i18next';
import { changeRoute } from 'routes/AuthenticatedContainer/actions';
import { releaseNotesRoute } from 'routes/constants';
import { useDispatch } from 'react-redux';
import { FeedbackMenuItem } from './components/FeedbackMenuItem/FeedbackMenuItem';

import './HelpMenu.scss';

export const HelpMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const items = [
    {
      key: '1',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.openpath.com"
        >
          {t('FAQs')}
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.avigilon.com/category/alta-access-control"
        >
          {t('Installation guides')}
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.openpath.com/request-security-docs"
        >
          {t('Security docs')}
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://openpath.readme.io"
        >
          {t('Developer support')}
        </a>
      ),
    },
    {
      key: '5',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://control.openpath.com/license-attribution.html"
        >
          {t('Licensing information')}
        </a>
      ),
    },
    {
      key: '6',
      label: <FeedbackMenuItem />,
    },
    {
      key: '7',
      label: t('Release notes'),
      onClick: () => {
        dispatch(changeRoute(releaseNotesRoute));
      },
    },
  ];

  return (
    <OpDropdown
      className="help-menu"
      trigger={['click']}
      menu={{ items }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      overlayClassName="help-menu-overlay"
    >
      <OpButton
        className="help-menu__button"
        icon={<QuestionMarkOutlinedSvg width={18} height={18} />}
        type="link"
      />
    </OpDropdown>
  );
};
