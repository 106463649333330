import { useMemo } from 'react';
import { useOpQuery } from 'utils/customHooks/useOpQuery';

/**
 *
 * @param {number} orgId - ID of the org
 * @param {number} roleId - ID of the role if in edit mode
 * @returns {object}
 *  initialValues {object} - initial values for role form
 *  roleScopesData {array} - scopes in the role
 *  isEditable {boolean} - true if roleId exists and is for a default role
 *  isLoading {boolean} - true if data is loading
 *  hasError {boolean}  - true if error loading data
 */
export const useRoleFormData = ({
  orgId,
  roleId,
}: {
  orgId: number;
  roleId?: number;
}) => {
  /* Queries for form data */
  const {
    data: roleData,
    isLoading: isLoadingRole,
    isError: isErrorRole,
  } = useOpQuery({
    apiEndpointName: 'describeRole',
    parameters: [orgId, roleId!],
    select: (data) => data.json.data,
  });

  const {
    data: roleScopesData,
    isLoading: isLoadingRoleScopes,
    isError: isErrorRoleScopes,
  } = useOpQuery({
    apiEndpointName: 'listRoleScopeIds',
    parameters: [orgId, roleId!],
    select: (data) => data.json.data,
  });

  /* Initial values */

  const initialValues = useMemo(
    () => ({
      name: roleData?.name,
      description: roleData?.description,
      isMfaRequired: roleData?.isMfaRequired,
      isSiteSpecific: roleData?.isSiteSpecific,
      sites: roleData?.sites?.map((s) => s.id),
      roleScopes: roleScopesData,
    }),
    [
      roleData?.name,
      roleData?.description,
      roleData?.isMfaRequired,
      roleData?.isSiteSpecific,
      roleData?.sites,
      roleScopesData,
    ],
  );

  return {
    initialValues,
    roleScopesData,
    isEditable: !roleData || roleData.isEditable,
    isLoading: isLoadingRole || isLoadingRoleScopes,
    hasError: isErrorRole || isErrorRoleScopes,
  };
};
