import { AltaAccessSvg } from 'components/svgs/AltaAccessSvg';
import { AltaVideoSvg } from 'components/svgs/AltaVideoSvg';
import { AltaDmpSvg } from 'components/svgs/AltaDmpSvg';
import { AltaMailroomSvg } from 'components/svgs/AltaMailroomSvg';
import { AppSwitcherButton } from './AppSwitcherButton';

import './AppSwitcherMenu.scss';

interface AppSwitcherMenuProps {
  handleAwareLogin?(): Promise<void>;
  handleDmpLogin?(): Promise<void>;
  handleNavigateToMailroom?(): void;
  handleNavigateToAccess(): void;
}

export const AppSwitcherMenu = ({
  handleAwareLogin,
  handleDmpLogin,
  handleNavigateToMailroom,
  handleNavigateToAccess,
}: AppSwitcherMenuProps) => {
  return (
    <div className="app-switcher-menu">
      <AppSwitcherButton
        label="Access"
        onClick={handleNavigateToAccess}
        icon={<AltaAccessSvg className="app-switcher-button__icon" />}
      />
      {handleDmpLogin ? (
        <AppSwitcherButton
          label="DMP"
          onClick={handleDmpLogin}
          icon={<AltaDmpSvg className="app-switcher-button__icon" />}
        />
      ) : null}
      {handleAwareLogin ? (
        <AppSwitcherButton
          label="Video"
          onClick={handleAwareLogin}
          icon={<AltaVideoSvg className="app-switcher-button__icon" />}
        />
      ) : null}
      {handleNavigateToMailroom ? (
        <AppSwitcherButton
          label="Mailroom"
          onClick={handleNavigateToMailroom}
          icon={<AltaMailroomSvg className="app-switcher-button__icon" />}
        />
      ) : null}
    </div>
  );
};
