import { SvgWrapper } from 'components/svgs/SvgWrapper';

export const AltaIntrusionSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 36 36" {...svgProps}>
    <path
      opacity="0.6"
      d="M18.0101 35C17.8901 35 17.7701 34.98 17.6601 34.94C1.16009 28.75 2.00009 8.65 2.00009 8.45C2.02009 8.03 2.30009 7.67 2.70009 7.55C10.5101 5.11 17.4401 1.17 17.5101 1.13C17.8201 0.959995 18.2001 0.949995 18.5001 1.13C18.5701 1.17 25.5001 5.11 33.3001 7.54C33.7001 7.66 33.9801 8.03 34.0001 8.44C34.0001 8.64 34.8501 28.74 18.3501 34.93C18.2401 34.97 18.1201 34.99 18.0001 34.99L18.0101 35ZM4.00009 9.22999C4.02009 12.63 4.89009 27.74 18.0101 32.92C31.1301 27.74 32.0001 12.63 32.0201 9.22999C25.4201 7.08999 19.7001 4.07 18.0101 3.14C16.3201 4.07 10.6001 7.07999 4.00009 9.22999Z"
    />
    <path d="M28.79 10.55C23.48 8.89 18.5798 6.18 18.5398 6.15L18.5325 6.14559C18.2115 5.95178 17.8091 5.95348 17.4898 6.15V6.15C17.4898 6.15 12.7998 8.89 7.4998 10.55L7.14989 10.66C6.94155 10.7255 6.7998 10.9186 6.7998 11.137V11.51C6.7998 12.05 7.13977 24.69 17.5698 29.9V29.9C17.8537 30.0388 18.1858 30.0388 18.4698 29.9V29.9C28.8998 24.68 29.5 12.04 29.5 11.51V11.137C29.5 10.9186 29.3583 10.7255 29.1499 10.66L28.8 10.55H28.79Z" />
  </SvgWrapper>
);
