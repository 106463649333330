import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getOpParams } from 'utils/getOpParams';
import { useIsDmpEnabled } from 'utils/customHooks/useIsDmpEnabled';
import { getDmpUrl } from 'utils/getDmpUrl';
import { useUnifiedLogin } from 'utils/customHooks/useUnifiedLogin';
import opConfig from 'openpathConfig';
import { getAccessToken } from 'utils/accessToken';
import { getActiveTokenScopeListItem } from 'utils/getActiveTokenScopeListItem';
import { checkAuthIsUPCPartnerOrg } from 'utils/checkAuthIsUPCPartnerOrg';
import { getOrgNativeAppUrl } from './utils/getOrgNativeAppUrl';
import { getUpsellUrl } from './utils/getUpsellUrl';
import { handleUpsellClick } from './utils/handleUpsellClick';
import { getOrgNativeApp } from './utils/getOrgNativeApp';
import { getUpcUrl } from '../../../altaLoginApp/helpers/getUpcUrl';

const ACCESS_NATIVE_APP_ID = 1;
const MAILROOM_NATIVE_APP_ID = 2;
const INTRUSION_NATIVE_APP_ID = 3;
const VIDEO_NATIVE_APP_ID = 4;

const REGION = opConfig.ENV;

export const useNativeAppSwitcher = () => {
  const { orgId } = getOpParams();
  const dmpUrl = getDmpUrl();
  const accessToken = getAccessToken();

  const { isDmpEnabled } = useIsDmpEnabled();

  const { data: orgNativeApps = [] } = useOpQuery({
    apiEndpointName: 'listOrgNativeApps',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  const { data: nativeApps = [] } = useOpQuery({
    apiEndpointName: 'listGlobalNativeApps',
    select: (data) => data.json.data,
    // No need for cache to clear on these
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const { data: tokenData } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    enabled: Boolean(accessToken),
    select: (data) => data.json?.data,
  });

  const tokenScopeListItem = getActiveTokenScopeListItem(
    tokenData?.tokenScopeList,
    orgId,
  );

  const isUpcPartner = checkAuthIsUPCPartnerOrg(tokenScopeListItem);

  const accessUpsellUrl = getUpsellUrl(ACCESS_NATIVE_APP_ID, nativeApps);
  const mailroomUpsellUrl = getUpsellUrl(MAILROOM_NATIVE_APP_ID, nativeApps);
  const videoUpsellUrl = getUpsellUrl(VIDEO_NATIVE_APP_ID, nativeApps);
  const intrusionUpsellUrl = getUpsellUrl(INTRUSION_NATIVE_APP_ID, nativeApps);

  const accessNativeApp = getOrgNativeApp(ACCESS_NATIVE_APP_ID, orgNativeApps);
  const mailroomNativeApp = getOrgNativeApp(
    MAILROOM_NATIVE_APP_ID,
    orgNativeApps,
  );
  const videoNativeApp = getOrgNativeApp(VIDEO_NATIVE_APP_ID, orgNativeApps);
  const intrusionNativeApp = getOrgNativeApp(
    INTRUSION_NATIVE_APP_ID,
    orgNativeApps,
  );

  const accessUrl = getOrgNativeAppUrl(accessNativeApp);
  const mailroomUrl = getOrgNativeAppUrl(mailroomNativeApp);
  const videoUrl = getOrgNativeAppUrl(videoNativeApp);
  const intrusionUrl = getOrgNativeAppUrl(intrusionNativeApp);

  // dmp login
  const { handleUnifiedLogin: dmpLogin, isLoggingIn: isDmpLoggingIn } =
    useUnifiedLogin({
      url: dmpUrl,
      options: { org_id: orgId, region: opConfig.ENV },
    });

  const { handleUnifiedLogin: videoLogin, isLoggingIn: isVideoLoggingIn } =
    useUnifiedLogin({
      url: videoUrl,
      options: { org_id: orgId, region: opConfig.ENV },
    });

  const {
    handleUnifiedLogin: intrusionLogin,
    isLoggingIn: isIntrusionLoggingIn,
  } = useUnifiedLogin({
    url: intrusionUrl,
    options: { org_id: orgId, region: opConfig.ENV },
  });

  const handleAccessClick = accessNativeApp
    ? () => {
        window.open(accessUrl, '_blank');
      }
    : () => handleUpsellClick(accessUpsellUrl);

  const handleMailroomClick = mailroomNativeApp
    ? () => {
        window.open(mailroomUrl, '_blank');
      }
    : () => handleUpsellClick(mailroomUpsellUrl);

  const handleVideoClick = videoNativeApp
    ? videoLogin
    : () => handleUpsellClick(videoUpsellUrl);

  const handleIntrusionClick = intrusionNativeApp
    ? intrusionLogin
    : () => handleUpsellClick(intrusionUpsellUrl);

  return {
    access: {
      onClick: handleAccessClick,
    },
    mailroom: {
      onClick: handleMailroomClick,
    },
    video: {
      onClick: handleVideoClick,
    },
    intrusion: {
      onClick: handleIntrusionClick,
    },
    dmp: {
      onClick: dmpLogin,
      isVisible: isDmpEnabled,
    },
    partnerCenter: {
      onClick: () => {
        window.open(`${getUpcUrl(REGION)}/o/${orgId}`, '_blank');
      },
      isVisible: isUpcPartner,
    },
    isLoading: isDmpLoggingIn || isVideoLoggingIn || isIntrusionLoggingIn,
  };
};
